import { useSessionStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

import type { AuthType, DecryptData } from '@/types/authentication';

import type { RemovableRef } from '@vueuse/core';

const initDecryptData = {
  safeKey: '',
  safeKeyStatus: 'NICE_ETC_ERROR' as const,
  decryptedAt: '',
  requestId: '',
  responseId: '',
  authType: '',
  name: '',
  duplicateInfo: '',
  connectInfo: '',
  birthDate: '',
  gender: '',
  nationInfo: '',
  mobileNumber: '',
  mobileCompany: '',
  ageGroup: '',
};

interface AuthStore {
  authType: RemovableRef<AuthType | null>;
  decryptData: RemovableRef<DecryptData>;
}

const initState: AuthStore = {
  authType: useSessionStorage('auth/authType', null),
  decryptData: useSessionStorage('auth/decryptData', initDecryptData),
};
/**
 * useAuthStore
 * @description 본인인증방식
 */
const useAuthStore = defineStore('auth', {
  state: (): AuthStore => ({ ...initState }),
  actions: {
    setAuthType(value: AuthType | null) {
      this.$state.authType = value;
    },
    resetAuthType() {
      this.$state.authType = null;
    },
    setDecryptData(data: DecryptData) {
      this.$state.decryptData = data;
    },
    resetDecryptData() {
      this.$state.decryptData = { ...initDecryptData };
    },
  },
});

export default useAuthStore;
