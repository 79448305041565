<script setup lang="ts">
/**
 * GuideMessageArea
 * @description 안내 메세지 영역 컴포넌트
 */
import { uuid } from 'vue-uuid';

import type { GuideMessage } from '@/types/commons';

interface Props {
  guideMessages: GuideMessage[];
  className?: string;
}

withDefaults(defineProps<Props>(), {
  guideMessages: undefined,
  className: 'list_type3',
});

const uuidKey = uuid.v4();
</script>

<template>
  <ul :class="className">
    <template v-for="({ isShow, message }, index) of guideMessages" :key="uuidKey + index">
      <li v-if="isShow" v-html="$t(message)"></li>
    </template>
  </ul>
</template>
