/**
 * commonError
 * @description 공통 에러 처리를 위한 hook
 */

import { MODAL_KEY } from '@/constants/modal';
import useNative from '@/hooks/useNative';
import useCommonStore from '@/stores/common';
import { useModalStore } from '@/stores/modal';

import { i18n } from '@/i18n';
import router from '@/router';

import type { ErrorType } from '@/types/commonError';

/**
 * pageNotFoundError
 * @description 404 에러
 */
export async function pageNotFoundError(isPopup?: boolean) {
  const { openModal, closeModalAll } = useModalStore();
  if (isPopup) {
    // 팝업
    openModal(MODAL_KEY.ALERT, {
      message: i18n.global.t('alert.message.pageNotFoundError'),
      showClose: false,
    });
  } else {
    // 페이지 이동
    await router.replace({ name: 'ErrorPage', state: { errorType: 'pageNotFound' as ErrorType } });
    closeModalAll();
  }
}

/**
 * networkError
 * @description 네트워크 미연결 (etc code)
 */
export async function networkError(isPopup?: boolean) {
  const { openModal, closeModalAll } = useModalStore();
  if (isPopup) {
    // 팝업
    openModal(MODAL_KEY.ALERT, {
      message: i18n.global.t('alert.message.networkError'),
      showClose: true,
      buttonLabel: i18n.global.t('general.retry'),
      onClick: () => {
        window.history.go(0);
      },
    });
  } else {
    // 페이지 이동
    await router.replace({ name: 'ErrorPage', state: { errorType: 'network' as ErrorType } });
    closeModalAll();
  }
}

/**
 * wrongAccessError
 * @description 비정상 접근
 */
export async function wrongAccessError(isPopup?: boolean) {
  const { openModal, closeModalAll } = useModalStore();
  const { clientName } = useCommonStore();
  if (clientName && isPopup) {
    // 팝업
    openModal(MODAL_KEY.ALERT, {
      message: i18n.global.t('alert.message.wrongAccessError'),
      showClose: false,
    });
  } else {
    // 페이지 이동
    await router.replace({ name: 'ErrorPage', state: { errorType: 'wrongAccess' as ErrorType } });
    closeModalAll();
  }
}

/**
 * sessionTimeoutError
 * @description 세션 만료 (401)
 */
export async function sessionTimeoutError() {
  const { getClientName } = useCommonStore();
  const { close } = useNative();
  if (getClientName === 'IOCARE') {
    close({ action: 'logout' });
    return;
  }
  // 페이지 이동
  const { closeModalAll } = useModalStore();
  await router.replace({ name: 'ErrorPage', state: { errorType: 'sessionTimeout' as ErrorType } });
  closeModalAll();
}

/**
 * etcError
 * @description 기타 오류 (500~503)
 */
export async function etcError(isPopup?: boolean) {
  const { openModal, closeModalAll } = useModalStore();
  if (isPopup) {
    // 팝업
    openModal(MODAL_KEY.ALERT, {
      message: i18n.global.t('alert.message.etcError'),
      showClose: false,
    });
  } else {
    // 페이지 이동
    await router.replace({ name: 'ErrorPage', state: { errorType: 'etc' as ErrorType } });
    closeModalAll();
  }
}
