import type { AxiosRequestConfig } from 'axios';

import axios from 'axios';

import { APIS_KEY, COMMON_ERROR_MESSAGE_TYPE } from '@/constants/apis';
import { MODAL_KEY } from '@/constants/modal';
import { etcError, networkError, pageNotFoundError, sessionTimeoutError, wrongAccessError } from '@/hooks/useCommonError';
import useEnv from '@/hooks/useEnv';
import useCommonStore from '@/stores/common';
import { useModalStore } from '@/stores/modal';
import { getCommonError } from '@/utils/apis';
import { getKeyFromQueryParam } from '@/utils/urlParameter';

import { i18n } from '@/i18n';

import type { FetchData, AxiosResponseType } from '@/types/apis';

/**
 * fetchData
 * @description axios 를 사용하여 api 를 직접 호출하는 util
 * @params { url, method, requestData } : api를 호출할 url, method, request params
 * @return { Promise<AxiosResponseType<T>> }
 */
export function fetchData<T>({ url, method, requestData, headers, config, isPopup }: FetchData, baseUrl: string = useEnv().VITE_API_URL): Promise<AxiosResponseType<T>> {
  const { addLoading, subLoading } = useCommonStore();

  const axiosInstance = axios.create({
    baseURL: baseUrl,
    timeout: 60 * 1000,
    withCredentials: true,
    headers: { 'content-type': 'application/json;charset=UTF-8', ...headers },
  });

  const requestConfig: AxiosRequestConfig = {
    url,
    method,
    ...(config ?? {}),
  };

  if (requestData) {
    requestConfig.data = requestData;
  }

  axiosInstance.interceptors.request.use(
    function (response) {
      addLoading();
      return Promise.resolve(response);
    },
    function (error) {
      addLoading();
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    function (response) {
      subLoading();
      return Promise.resolve(response);
    },
    function (error) {
      subLoading();

      if (error?.code === 'ECONNABORTED') {
        networkError();
        return Promise.reject(error);
      }
      const { openModal } = useModalStore();

      const statusCode = error?.response?.status;
      const dataCode = error?.response?.data?.code ?? '';

      let apiUrl: string = error?.config?.url.split('?')[0] ?? '';
      apiUrl = apiUrl.match(APIS_KEY.USERINFO_CHANGE) ? APIS_KEY.USERINFO_CHANGE : apiUrl;

      const commonError = getCommonError(apiUrl, dataCode);
      const { type: errorType, message: errorMessage, buttonLabel, ...rest } = commonError ?? {};
      const { setSessionTimeoutRedirectUri } = useCommonStore();

      switch (statusCode) {
        case 401:
          setSessionTimeoutRedirectUri(location.pathname, getKeyFromQueryParam());
          sessionTimeoutError();
          break;
        case 403:
        case 405:
          wrongAccessError(isPopup);
          break;
        case 404:
          pageNotFoundError(isPopup);
          break;
        case 400:
          if (!dataCode) break;

          if (apiUrl === APIS_KEY.AGREEMENT_CLIENT && dataCode === 'NOT_FOUND_CLIENT_NAME') {
            wrongAccessError();
            return;
          } else if (apiUrl === APIS_KEY.AGREEMENT_CLIENT && dataCode === 'NOT_EXIST_USER') {
            etcError();
            return;
          } else if (apiUrl === APIS_KEY.USERINFO_PHONE && dataCode === 'INVALID_COUNTRY_CODE') {
            etcError();
            return;
          } else if (dataCode === 'BAD_REQUEST_PARAMETER' || dataCode === 'NOT_FOUND_CLIENT_NAME') {
            wrongAccessError(isPopup);
            return;
          } else if (apiUrl === APIS_KEY.TERMS_CATEGORY_TYPE_API && dataCode === 'NOT_EXIST_CATEGORY_TYPE') {
            etcError(isPopup);
            return;
          } else if (apiUrl === APIS_KEY.USERINFO_CHANGE && (dataCode === 'NOT_EXIST_AGREEMENT' || dataCode === 'NOT_EXIST_USER')) {
            etcError(isPopup);
            return;
          }

          if (!commonError) break;
          switch (errorType) {
            case COMMON_ERROR_MESSAGE_TYPE.ALERT:
              openModal(MODAL_KEY.ALERT, {
                message: errorMessage ? i18n.global.t(errorMessage) : '',
                buttonLabel: buttonLabel ? i18n.global.t(buttonLabel) : undefined,
                ...rest,
              });
              return;
            case COMMON_ERROR_MESSAGE_TYPE.TOAST:
              openModal(MODAL_KEY.TOAST, { message: errorMessage ? i18n.global.t(errorMessage) : '' });
              return;
          }

          break;
        case 408:
          networkError(isPopup);
          break;
        case 429:
        case 500:
        case 501:
        case 502:
        case 503:
        case 504:
          etcError(isPopup);
          break;
        default:
          console.warn(`[url] ${error?.response?.request?.responseURL} \n Fetch Error Response `, error?.response);
          networkError(isPopup);
          break;
      }
      return Promise.reject(error);
    },
  );
  return axiosInstance.request(requestConfig);
}
