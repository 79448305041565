<script setup lang="ts">
/**
 * PopupChangePassword
 * @description 패스워드 변경 모달 컴포넌트
 */

import { useForm } from 'vee-validate';
import { onMounted, ref, computed } from 'vue';

import { MODAL_KEY } from '@/constants/modal';
import MyAccountChangePassword from '@/domains/myAccountChangePassword';
import useInputField from '@/hooks/useInputField';

import ButtonAtom from '@/components/atoms/ButtonAtom/index.vue';
import InputLabel from '@/components/molecules/input/InputLabel.vue';
import InputPasswordConfirm from '@/components/molecules/input/InputPasswordConfirm.vue';
import PopupLayout from '@/components/molecules/layout/PopupLayout.vue';

import type { HtmlEvent } from '@/types/dom';

interface Props {
  isUserPasswordCheckRequired?: boolean;
}

const props = withDefaults(defineProps<Props>(), { isUserPasswordCheckRequired: true });

interface FormDefault {
  currPassword: string;
  password: string;
  passwordConfirm: string;
}

const { errors } = useForm<FormDefault>({
  validationSchema: {
    currPassword: 'required:를|currPassword',
    password: 'required:를|validPassword|strongPassword|min:10|repetitionLimit:3',
    passwordConfirm: 'requiredConfirm:를|confirmed:@password,를',
  },
  initialValues: {
    password: '',
    passwordConfirm: '',
    currPassword: '',
  },
});

const { value: currPassword, setValue: setCurrPassword, meta: currPasswordMeta } = useInputField('currPassword');
const { value: password, setValue: setPassword, meta: passwordMeta } = useInputField('password');
const { value: passwordConfirm, setValue: setPasswordConfirm, meta: passwordConfirmMeta } = useInputField('passwordConfirm');

const passwordRef = ref<InstanceType<typeof InputLabel> | null>(null);
const passwordConfirmRef = ref<InstanceType<typeof InputPasswordConfirm> | null>(null);

const myAccountChangePassword = new MyAccountChangePassword();

const buttonIsDisabled = computed<boolean>(() => {
  return props.isUserPasswordCheckRequired
    ? !(
        currPasswordMeta.valid &&
        passwordMeta.valid &&
        passwordConfirmMeta.valid &&
        !myAccountChangePassword.serverSentError.value.currPassword &&
        !myAccountChangePassword.serverSentError.value.newPassword
      )
    : !(passwordMeta.valid && passwordConfirmMeta.valid);
});

const handleChangeForm = (e: HtmlEvent<HTMLInputElement>): void => {
  switch (e.target.name) {
    case 'currPassword':
      setCurrPassword(e.target.value.trim());
      myAccountChangePassword.serverSentError.value.currPassword = '';
      break;
    case 'password':
      setPassword(e.target.value.trim());
      myAccountChangePassword.serverSentError.value.newPassword = '';
      break;
    case 'passwordConfirm':
      setPasswordConfirm(e.target.value.trim());
      myAccountChangePassword.serverSentError.value.newPasswordConfirm = '';
      break;
    default:
      throw new Error('유효하지 않는 name입니다.');
  }
};

const handleClickChangePassword = () => {
  if (!buttonIsDisabled.value) {
    myAccountChangePassword.changePassword({
      currPassword: currPassword.value,
      newPassword: password.value,
      newPasswordConfirm: passwordConfirm.value,
    });
  }
};

onMounted(() => {
  if (props.isUserPasswordCheckRequired) {
    passwordRef.value?.toggleFocus();
  } else {
    passwordConfirmRef.value?.toggleFocus();
  }
});
</script>

<template>
  <PopupLayout tabindex="0" header-right lay-inner :title="$t('text.changePassword')" :content-id="MODAL_KEY.CHANGE_PASSWORD">
    <template #section>
      <div class="layCon">
        <div class="title_wrap mt0">
          <h2 class="h2_tit">{{ $t('text.enterNewPassword') }}</h2>
          <p class="sub_tit">
            {{ $t('text.carePasswordExposure') }}
          </p>
        </div>

        <form @keydown.enter="handleClickChangePassword">
          <InputLabel
            v-if="isUserPasswordCheckRequired"
            id="currPassword"
            ref="passwordRef"
            name="currPassword"
            type="password"
            autocomplete="on"
            :label="$t('label.currentPassword')"
            :value="currPassword"
            :on-input="handleChangeForm"
            :error-message="myAccountChangePassword.serverSentError.value.currPassword || (errors.currPassword ?? '')"
            :placeholder="$t('placeholder.enterCurrentPassword')" />

          <InputPasswordConfirm
            ref="passwordConfirmRef"
            password-id="password"
            password-confirm-id="passwordConfirm"
            :label="$t('label.newPassword')"
            :values="{ password, passwordConfirm }"
            :placeholders="[$t('placeholder.enterNewPassword'), $t('placeholder.reEnterNewPassword')]"
            :guide-messages="[{ isShow: true, message: 'text.newPasswordGuideMessage' }]"
            guide-message-class-name="txt_form_guide_msg"
            :error-messages="[
              myAccountChangePassword.serverSentError.value.newPassword || (errors.password ?? ''),
              myAccountChangePassword.serverSentError.value.newPasswordConfirm || (errors.passwordConfirm ?? ''),
            ]"
            :on-input="handleChangeForm" />
        </form>

        <ButtonAtom
          wrap-mode
          wrap-class="bottom_btn"
          tabindex="0"
          :label="$t('text.changePassword')"
          color="btnBlack"
          size="sizeL"
          auto
          :disabled="buttonIsDisabled"
          :on-click="handleClickChangePassword"
          :on-keyup="handleClickChangePassword" />
      </div>
    </template>
  </PopupLayout>
</template>
