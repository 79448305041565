<script setup lang="ts">
/**
 * PopupPersonalInfoTermsCollectionPurpose
 * @description 개인정보 이용내역 팝업 - 수집/이용목적 컴포넌트
 */
import GuideMessageArea from '@/components/molecules/GuideMessageArea.vue';

import type { GuideMessage } from '@/types/commons';

const guideMessages: GuideMessage[] = [
  { isShow: true, message: 'terms.useMemberInfoPurposeItem1' },
  { isShow: true, message: 'terms.useMemberInfoPurposeItem2' },
  { isShow: true, message: 'terms.useMemberInfoPurposeItem3' },
  { isShow: true, message: 'terms.useMemberInfoPurposeItem4' },
];
</script>

<template>
  <div id="use_info_tab1" class="use_info_tab on">
    <h4 class="tit_section">{{ $t('terms.purposeOfGatherAndUsage') }}</h4>
    {{ $t('terms.useMemberInfoPurpose') }}
    <GuideMessageArea :guide-messages="guideMessages" class-name="list_type3" />
  </div>
</template>
