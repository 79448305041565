<script setup lang="ts">
/**
 * InputLabel
 * @description 라벨이 있는 인풋 컴포넌트
 */

import { ref } from 'vue';

import InputAtom from '@/components/atoms/InputAtom/index.vue';
import GuideMessageArea from '@/components/molecules/GuideMessageArea.vue';

import type { GuideMessage, InputType } from '@/types/commons';
import type { HtmlEvent } from '@/types/dom';
interface Props {
  label?: string;
  id: string;
  name?: string;
  type?: InputType;
  value: string;
  placeholder?: string;
  disabled?: boolean;
  autocomplete?: string;
  guideMessageClassName?: string;
  guideMessages?: GuideMessage[];
  errorMessage?: string;
  onInput?: (e: HtmlEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent) => void;
  onKeyup?: (e: KeyboardEvent) => void;
}

const props = defineProps<Props>();
const inputAtom = ref<InstanceType<typeof InputAtom> | null>(null);

const handleInput = (e: Event) => {
  props.onInput?.(e as HtmlEvent<HTMLInputElement>);
};

const handleBlur = (e: FocusEvent) => {
  props.onBlur?.(e);
};

const handleKeyup = (e: KeyboardEvent) => {
  props.onKeyup?.(e);
};

const toggleFocus = () => {
  inputAtom.value?.toggleFocus();
};

defineExpose({
  toggleFocus,
});
</script>

<template>
  <div class="inp_text" :class="{ disabled, error: errorMessage }">
    <div v-if="label" class="form_tit">{{ label }}</div>
    <InputAtom
      :id="id"
      ref="inputAtom"
      :name="name"
      :value="value"
      :type="type"
      :placeholder="placeholder"
      :disabled="disabled"
      :autocomplete="autocomplete"
      :on-input="handleInput"
      :on-blur="handleBlur"
      :on-keyup="handleKeyup" />
  </div>
  <GuideMessageArea v-if="guideMessages" :guide-messages="guideMessages" :class-name="guideMessageClassName" />
  <p v-if="errorMessage" class="member_error_msg">{{ errorMessage }}</p>
</template>
