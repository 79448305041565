import { defineRule } from 'vee-validate';

import {
  birthDate,
  email,
  min,
  phoneNumber,
  emailOrPhoneNumber,
  repetitionLimit,
  strongPassword,
  validName,
  validPassword,
  validateDoubleWhiteSpace,
  validateHundredLength,
  validateSpecialChar,
  validateTrim,
} from '@/utils/validate';

import { i18n } from '@/i18n';

interface FieldValidationMetaInfo {
  field: string;
  name: string;
  label?: string;
  value: unknown;
  form: Record<string, unknown>;
  rule?: {
    name: string;
    params?: Record<string, unknown> | unknown[];
  };
}

export const defineGlobalRules = () => {
  defineRule('required', (value: string, param: string[], context) => {
    return Boolean(value && value.length) || requiredMessage('fields', param, context);
  });
  defineRule('requiredConfirm', (value: string, param: string[], context) => {
    return Boolean(value && value.length) || requiredMessage('fields', param, context);
  });
  defineRule('confirmed', (value: string, param: string[], context) => {
    const target = param[0];
    return Boolean(value === target) || requiredMessage('fields', param, context);
  });
  defineRule('validateDoubleWhiteSpace', (value: string) => {
    return validateDoubleWhiteSpace(value) || i18n.global.t('validation.validName');
  });
  defineRule('validateTrim', (value: string) => {
    return validateTrim(value) || i18n.global.t('validation.validNameTrim');
  });
  defineRule('validateSpecialChar', (value: string) => {
    return validateSpecialChar(value) || i18n.global.t('validation.validNameSpecialChar');
  });
  defineRule('validateHundredLength', (value: string) => {
    return validateHundredLength(value) || i18n.global.t('validation.validNameLength');
  });
  defineRule('validName', (value: string) => {
    return validName(value) || i18n.global.t('validation.validName');
  });
  defineRule('validFindName', (value: string) => {
    return validateHundredLength(value) || i18n.global.t('validation.validName');
  });
  defineRule('min', (value: string, length: string[], context) => {
    return min(value, Number(length[0])) || validateMessage(length, context);
  });
  defineRule('currPassword', (value: string) => {
    return min(value, Number(10)) || i18n.global.t('validation.enterExactPassword');
  });
  defineRule('strongPassword', (value: string) => {
    return strongPassword(value) || i18n.global.t('validation.strongPassword');
  });
  defineRule('ioCareStrongPassword', (value: string) => {
    return strongPassword(value) || i18n.global.t('validation.ioCareStrongPassword');
  });
  defineRule('validPassword', (value: string) => {
    return validPassword(value) || `${i18n.global.t('validation.validPassword')}(! " # $ % & ' ( ) * + , - . / : ; < = > ? @ [ ＼ ] ^ _ \` { | } ~)`;
  });
  defineRule('repetitionLimit', (value: string, param: string[], context) => {
    return repetitionLimit(value, param) || validateMessage(param, context);
  });
  defineRule('email', (value: string) => {
    return email(value) || i18n.global.t('validation.email');
  });
  defineRule('phoneNumber', (value: string) => {
    return phoneNumber(value) || i18n.global.t('validation.phoneNumber');
  });
  defineRule('emailOrPhoneNumber', (value: string) => {
    return emailOrPhoneNumber(value) || i18n.global.t('validation.emailOrPhoneNumber');
  });
  defineRule('birthDate', (value: string) => {
    return birthDate(value) || i18n.global.t('validation.birthDate');
  });
};

/**
 * requiredMessage
 * @description 필드 값에 따라서 required 메세지 출력
 * @param unit 번역 키 값
 * @param param validation 파라미터 값, { ~~ } 형태
 * @param context vee-validate context
 * @returns i18n 번역 매핑된 값
 */
export function requiredMessage(unit: string, param: string[], context: FieldValidationMetaInfo) {
  const _field_ = getField(context, unit);
  const params = validationParams(param, 'param_');
  const result = { _field_, ...params };

  return i18n.global.t(`validation.${context.rule?.name}`, result);
}

/**
 * validateMessage
 * @param param validation 파라미터 값, { ~ } 형태
 * @param context vee-validate context
 * @returns validation 키값에 매핑되는 번역 값
 */
export function validateMessage(param: string[], context: FieldValidationMetaInfo) {
  const params = validationParams(param, 'param_');
  return i18n.global.t(`validation.${context.rule?.name}`, { _field_: getField(context, 'fields'), ...params });
}

/**
 * getField
 * @param context vee-validate context
 * @param unit 매핑될 기준 key 값
 * @returns i18n 번역
 */
export function getField(context: FieldValidationMetaInfo, unit: string) {
  return i18n.global.t(`${unit}.${context.field}`);
}

/**
 * validationParams
 * @description [key+index]: param 키를 갖는 오브젝트로 변경시키는 유틸
 * @param param 변경할 문지열 배열
 * @param key 변경될 key 값
 * @returns [key+index]: param 오브젝트
 */
export function validationParams(param: string[], key: string) {
  return param.reduce((prev, curr, index) => ({ ...prev, [`${key}${index}`]: curr }), {});
}
