import { useSessionStorage, type RemovableRef } from '@vueuse/core';
import { defineStore } from 'pinia';

import type { ValidateResponseData } from '@/types/otp';

interface OtpStoreState {
  otpValidationResponse: RemovableRef<ValidateResponseData>;
  value: RemovableRef<string>;
}

const initOtpValidationResponse = {
  isValid: false,
  subsequentValidationData: '',
};

const initState: OtpStoreState = {
  otpValidationResponse: useSessionStorage('otp/otpValidationResponse', initOtpValidationResponse),
  value: useSessionStorage('otp/value', ''),
};

/**
 * useOtpStore
 * @description 점유인증 스토어
 */
const useOtpStore = defineStore('otp', {
  state: (): OtpStoreState => ({ ...initState }),
  getters: {
    otpValidationData(): string {
      return this.otpValidationResponse.subsequentValidationData;
    },
  },
  actions: {
    setOtpValidationResponse(value: ValidateResponseData) {
      this.otpValidationResponse = value;
    },
    resetOtpValidationResponse() {
      this.otpValidationResponse = initOtpValidationResponse;
    },
    setOtpValue(value: string) {
      this.value = value;
    },
  },
});

export default useOtpStore;
