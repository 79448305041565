<script setup lang="ts">
/**
 * SelectAtom
 * @description 셀렉트 박스 기본 컴포넌트
 */

interface SelectOption {
  text: string;
  value: string;
}
interface Props {
  selected: SelectOption;
  options: SelectOption[];
  disabled?: boolean;
  onChangeSelect?: (e: Event) => void;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  onChangeSelect: undefined,
});

const handleChangeSelect = (e: Event): void => {
  props.onChangeSelect?.(e);
};
</script>

<template>
  <div class="inp_select">
    <select id="terms_select" :value="selected.value" class="select_list" :disabled="disabled" @change="handleChangeSelect">
      <option v-for="{ text, value } in options" :key="value" :value="value">
        {{ text }}
      </option>
    </select>
  </div>
</template>
