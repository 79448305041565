import { useSessionStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

import type { RemovableRef } from '@vueuse/core';

export interface Condition {
  [key: string]: boolean;
}

interface ConditionListStore {
  conditionList: RemovableRef<Condition>; // 약관 리스트
}

const initState: ConditionListStore = {
  conditionList: useSessionStorage('conditionList/conditionList', {}),
};

/**
 * useConditionListStore
 * @description 약관 동의 여부 리스트 스토어
 */
const useConditionListStore = defineStore('conditionList', {
  state: (): ConditionListStore => ({ ...initState }),
  actions: {
    setConditionList(value: Condition) {
      this.conditionList = { ...value };
    },
    addConditionList(key: string, value: boolean) {
      this.conditionList = { ...this.conditionList, [`${key}`]: value };
    },
    resetConditionList() {
      this.conditionList = Object.assign({});
    },
  },
});

export default useConditionListStore;
