<script setup lang="ts">
/**
 * ModalProvider
 * @description modal pinia를 받아 모달의 상태값을 전달.
 * modal은 array로 되어 있어 리스트 갯수 만큼 반복하여 뿌려줌
 */
import { useModalStore } from '@/stores/modal';

import ModalContent from '@/components/molecules/modal/ModalContent.vue';
import ModalDefaultLayout from '@/components/molecules/modal/popup/ModalDefaultLayout.vue';

const modal = useModalStore();
</script>

<template>
  <div>
    <slot></slot>
    <teleport to="#modal">
      <section v-for="{ contentId, isOpen, layoutClickDisabled, props } in modal.getModals.modalList" :key="contentId">
        <ModalDefaultLayout v-show="isOpen" :content-id="contentId" :layout-click-disabled="layoutClickDisabled" class="modal-container">
          <ModalContent :content-id="contentId" :modalProps="props" />
        </ModalDefaultLayout>
      </section>
    </teleport>
  </div>
</template>
