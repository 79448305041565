import type { Ref } from 'vue';

import { ref, watch } from 'vue';

import { APIS_KEY } from '@/constants/apis';
import { AUTHENTICATE_TYPE, EMAIL_AUTH_TYPE } from '@/constants/authentication';
import { MODAL_KEY } from '@/constants/modal';
import { OTP_TYPE } from '@/constants/otp';
import AuthenticationMyAccount from '@/domains/authenticationMyAccount';
import useEnv from '@/hooks/useEnv';
import useFetch from '@/hooks/useFetch';
import { useModalStore } from '@/stores/modal';
import useMyAccountEditStore from '@/stores/myAccountEdit';
import { logout } from '@/utils/router';

import { i18n } from '@/i18n';

import type { UseFetch } from '@/types/apis';
import type { GuideMessage } from '@/types/commons';
import type { LocaleInfo } from '@/types/locales';
import type { MyAccountEditChangeData } from '@/types/myAccount';
import type { CheckUserStatusResponse } from '@/types/signUp';
import type { ClientName } from '@/types/terms';

export class MyAccountUserInfo {
  userInfo: Ref<MyAccountEditChangeData>;

  constructor() {
    this.userInfo = ref<MyAccountEditChangeData>({
      email: '',
      name: '',
      phone: '',
      verification: false,
      personalInfoValidPeriod: null,
      agreeMarketingEmailSmsReceive: false,
      agreeUpdatedAt: '',
      countryCd: '',
      countryCallingCd: '',
      clientName: null,
    });
  }

  setUserInfo = (value: MyAccountEditChangeData) => {
    const { setUserEditInfo } = useMyAccountEditStore();
    this.userInfo.value = { ...value };
    setUserEditInfo(value);
  };
  get isDomestic(): boolean {
    const { userInfo } = useMyAccountEditStore();
    return Boolean(userInfo.countryCd === 'KR');
  }
  get isRegularMember(): boolean {
    const { userInfo } = useMyAccountEditStore();
    return Boolean(userInfo.verification);
  }
  get isExistPhone() {
    const { userInfo } = useMyAccountEditStore();
    return Boolean(userInfo.phone);
  }
  get isExistEmail() {
    const { userInfo } = useMyAccountEditStore();
    return Boolean(userInfo.email);
  }
}

export class MyAccountEdit extends MyAccountUserInfo {
  response: Ref<UseFetch<MyAccountEditChangeData> | undefined>;
  checkUserResponse: Ref<UseFetch<CheckUserStatusResponse> | undefined>;
  serverErrorMessage: Ref<string | undefined>;

  constructor() {
    super();

    this.response = ref<UseFetch<MyAccountEditChangeData> | undefined>();
    this.checkUserResponse = ref<UseFetch<CheckUserStatusResponse> | undefined>();
    this.serverErrorMessage = ref<string | undefined>();
  }

  get phoneNumberButtonLabel() {
    if (this.isRegularMember) {
      return i18n.global.t('text.changeNameAndPhoneNumber');
    } else if (this.isDomestic && this.isExistPhone) {
      return i18n.global.t('general.selfCert');
    } else if (!this.isDomestic && this.isExistPhone) {
      return i18n.global.t('label.changePhoneNumber');
    } else {
      return i18n.global.t('text.registerPhoneNumber');
    }
  }

  get phoneNumberFieldMessages(): GuideMessage[] {
    return [
      { isShow: this.isRegularMember, message: 'text.needSelfCertForChangeNameAndPhoneNumber' },
      { isShow: !this.isRegularMember && this.isDomestic && this.isExistPhone, message: 'text.needSelfCertForChangeNameAndPhoneNumber' },
      { isShow: !this.isRegularMember && this.isDomestic && !this.isExistPhone, message: 'text.needSelfCertForRegisterPhoneNumber' },
      { isShow: this.isDomestic, message: 'text.changeNameAndPhoneNumberAfterSelfCert' },
      { isShow: this.isDomestic && !this.isRegularMember && !this.isExistPhone, message: 'text.availableManagementProductAfterSelfCert' },
      { isShow: !this.isDomestic && this.isExistPhone, message: 'text.availableOccupancyCert' },
      { isShow: !this.isDomestic && !this.isExistPhone, message: 'text.notSelfCertGlobal' },
    ];
  }

  userLocaleInfo(list: LocaleInfo[], countryCode: string): LocaleInfo {
    return list.find((data) => data.countryCd === countryCode) ?? list[0];
  }

  getAccountInfo = async (clientName: ClientName, isPopup: boolean = true) => {
    const { isPending, isSuccess, data, error } = useFetch<MyAccountEditChangeData>({
      url: `${APIS_KEY.USERINFO_CHANGE}/${clientName}`,
      method: 'get',
      isPopup,
    });

    watch([isPending, isSuccess, data, error], () => {
      this.response.value = {
        isPending,
        isSuccess,
        data,
        error,
      };

      if (isSuccess.value && data.value?.data) {
        this.setUserInfo(data.value.data);
      }
    });
  };

  allLogout(clientName: ClientName) {
    const { isPending, isSuccess, data, error } = useFetch<null>(
      {
        url: `${APIS_KEY.ALL_LOGOUT}?client_name=${clientName}`,
        method: 'post',
        isPopup: true,
      },
      useEnv().VITE_API_ORIGIN,
    );

    watch([isPending, isSuccess, data, error], async () => {
      if (isSuccess.value) {
        logout();
      }
    });
  }

  checkPhoneNumberAndRegisterPhoneNumber(phoneNumber: string, selected: LocaleInfo) {
    const { isPending, isSuccess, data, error } = useFetch<CheckUserStatusResponse>({
      url: APIS_KEY.SIGNUP_CHECK_USER_PHONE,
      method: 'post',
      requestData: { phoneNumber, countryCallingCd: this.userInfo.value.countryCallingCd },
      isPopup: true,
    });

    watch([isPending, isSuccess, data, error], () => {
      this.checkUserResponse.value = {
        isPending,
        isSuccess,
        data,
        error,
      };

      if (error.value) {
        this.serverErrorMessage.value = undefined;
        throw error.value;
      }

      if (isSuccess.value && data.value?.data.status === 'EXISTING_USER_PHONE') {
        this.serverErrorMessage.value = i18n.global.t('commonErrorMessage.alreadyExistPhone');
      } else {
        this.serverErrorMessage.value = undefined;
        this.registerPhoneNumber(phoneNumber, selected);
      }
    });
  }

  registerPhoneNumber(phoneNumber: string, selected: LocaleInfo) {
    const { openModal } = useModalStore();
    if (this.isRegularMember) {
      new AuthenticationMyAccount(AUTHENTICATE_TYPE.CHANGE_PHONE).authenticateMyAccount();
    } else if (this.isDomestic) {
      new AuthenticationMyAccount(AUTHENTICATE_TYPE.PROMOTE_USER).authenticateMyAccount();
    } else if (!this.isDomestic && this.isExistPhone) {
      openModal(MODAL_KEY.OTP_AUTH, {
        otpState: { phoneNumber: '', otpType: OTP_TYPE.PHONE_NUMBER, locale: selected },
        type: { purpose: EMAIL_AUTH_TYPE.MY_ACCOUNT, method: 'MODIFY' },
        countryCd: this.userInfo.value.countryCd,
        countryCallingCd: this.userInfo.value.countryCallingCd,
        checkDuplicate: true,
      });
    } else {
      openModal(MODAL_KEY.OTP_AUTH, {
        otpState: { phoneNumber, otpType: OTP_TYPE.PHONE_NUMBER, locale: selected },
        type: { purpose: EMAIL_AUTH_TYPE.MY_ACCOUNT, method: 'REGISTER' },
        inputDisabled: true,
        countryCd: this.userInfo.value.countryCd,
        countryCallingCd: this.userInfo.value.countryCallingCd,
      });
    }
  }
}
