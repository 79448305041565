/**
 * userAgent
 * @description user-agent 값에서 추출하여 사용할수 있는 값
 */

/**
 * IE라면 true, 아니라면 false 반환.
 * @returns boolean
 */
export const isIe = (): boolean => {
  return Boolean(navigator.userAgent.match(/(MSIE|Trident)/));
};

/**
 * App이라면 true, 아니라면 false 반환.
 * @returns boolean
 */
export const isApp = (): boolean => {
  return Boolean(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/) && navigator.userAgent.includes(' app'));
};

/**
 * iocare 앱일 경우 true, 아니라면 false 반환
 * @returns boolean
 */
export const isIocare = (): boolean => {
  return Boolean(navigator.userAgent.includes('iocare'));
};

/**
 * iOS라면 true, 아니라면 false 반환.
 * @returns boolean
 */
export const isIos = (): boolean => {
  return Boolean(navigator.userAgent.match(/(iPhone|iPad|iPod)/));
};

/**
 * AOS라면 true, 아니라면 false 반환.
 * @returns boolean
 */
export const isAos = (): boolean => {
  return Boolean(navigator.userAgent.match(/Android/));
};
