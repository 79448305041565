/**
 * @description GA에서 수집하려는 사용자 행동.
 */
export const actions: {
  [key: string]: string;
} = {
  findIdSuccess: '아이디 찾기_성공',
  findPasswordSuccess: '비밀번호 찾기_성공',
  signUpDuplicatePhoneNumberAuthenticationSuccess: '회원가입_휴대폰번호 중복_본인인증_성공',
  signUpDuplicatePhoneNumberAuthenticationFailure: '회원가입_휴대폰번호 중복_본인인증_실패',
  signUpAuthenticationStart: '회원가입_본인인증_시작 ',
  signUpAuthenticationSuccess: '회원가입_본인인증_성공',
  signUpAskInfoEmailOtpSuccess: '회원가입_정보입력_이메일 인증_성공',
  signUpAskMigration: '회원가입_회원전환 안내',
  signUpSuccess: '회원가입_성공',
  myAccountSaveServiceUseConditions: '회원정보 관리_휴면전환기간,광고수신동의 저장_성공',
  myAccountEmailOtpSuccess: '회원정보 관리_이메일 인증_성공',
  myAccountChangePasswordStart: '회원정보 관리_비밀번호 변경_시작 ',
  myAccountChangePasswordSuccess: '회원정보 관리_비밀번호 변경_성공',
  myAccountDeleteAccountSuccess: '회원정보 관리_회원탈퇴_성공',
  myAccountDeleteAccountFailure: '회원정보 관리_회원탈퇴_실패',
  migrationIoCareSuccess: '회원전환_IoCare_성공 ',
  migrationCowaySuccess: '회원전환_(구)홈페이지_성공 ',
  migrationCowayAuthenticationSuccess: '회원전환_(구)홈페이지_본인인증_성공',
  migrationCowayAuthenticationFailure: '회원전환_(구)홈페이지_본인인증_실패',
  migrationIoCareAuthenticationSuccess: '회원전환_IoCare_본인인증_성공',
  migrationIoCareAuthenticationFailure: '회원전환_IoCare_본인인증_실패',
  migrationSuccess: '회원전환_성공',
  myAccountManageSocialLoginNaverClick: '회원정보 관리_간편 로그인 연동관리_네이버_클릭',
  myAccountManageSocialLoginKakaoClick: '회원정보 관리_간편 로그인 연동관리_카카오_클릭',
  myAccountManageSocialLoginAppleClick: '회원정보 관리_간편 로그인 연동관리_애플_클릭',
  myAccountManageSocialLoginGoogleClick: '회원정보 관리_간편 로그인 연동관리_구글_클릭',
  myAccountManageSocialLoginNaverDisconnect: '회원정보 관리_간편 로그인 연동관리_네이버_해제',
  myAccountManageSocialLoginKakaoDisconnect: '회원정보 관리_간편 로그인 연동관리_카카오_해제',
  myAccountManageSocialLoginAppleDisconnect: '회원정보 관리_간편 로그인 연동관리_애플_해제',
  myAccountManageSocialLoginGoogleDisconnect: '회원정보 관리_간편 로그인 연동관리_구글_해제',
  myAccountPhoneNumberOtpSuccess: '회원정보 관리 휴대폰_변경_성공',
};

/**
 * @description 본인인증 성공, 실패 경로를 PageView 이벤트 이름과 mapping한 데이터.
 */
export const userAuthenticationGaTitleMap = new Map<string, string>([
  ['/user-authenticate/sign-up/success', '회원가입_본인인증_성공'],
  ['/user-authenticate/find-id/success', '로그인_아이디 찾기_본인인증_성공'],
  ['/user-authenticate/find-password/success', '로그인_비밀번호찾기_본인인증_성공 '],
  ['/user-authenticate/change-phone-number/success', '회원정보관리_휴대폰번호 변경_본인인증_성공 '],
  ['/user-authenticate/promote-user/success', '회원정보관리_준회원_본인인증_성공'],
  ['/user-authenticate/promote-user-without-login/success', '회원가입_회원가입 완료 직후 본인인증_성공'],
  ['/user-authenticate/migrate-user/success', '회원 전환_본인인증 완료_공통'],
  ['/user-authenticate/sign-up/failure', '회원가입_본인인증_실패'],
  ['/user-authenticate/find-id/failure', '로그인_아이디 찾기_본인인증_실패'],
  ['/user-authenticate/find-password/failure', '로그인_비밀번호찾기_본인인증_실패 '],
  ['/user-authenticate/change-phone-number/failure', '회원정보관리_휴대폰번호 변경_본인인증_실패 '],
  ['/user-authenticate/promote-user/failure', '회원정보관리(준회원)_본인인증_실패'],
  ['/user-authenticate/promote-user-without-login/failure', '회원가입_회원가입 완료 직후 본인인증_실패'],
  ['/user-authenticate/migrate-user/failure', '회원전환_본인인증_실패'],
]);

export const urlPath: { [key: string]: string } = {
  'find-id': '아이디 찾기',
  'user-info-authenticate': '가입정보로 아이디찾기',
  'find-password': '비밀번호 찾기',
  'email-otp-authenticate': '이메일 인증',
  complete: '완료',
  'sign-up': '회원가입',
  'check-user': '아이디 입력',
  'verify-phone-number': '휴대폰 점유인증',
  'verify-email': '이메일 인증',
  'ask-info': '정보입력',
  'ask-agree': '약관동의',
  'my-account': '회원정보관리',
  'verify-password': '비밀번호 입력',
  edit: '정보 수정',
  delete: '회원 탈퇴',
  'manage-social-login': '소셜로그인 관리',
  migrate: '회원전환',
  iocare: '아이오케어',
  login: '로그인',
  'io-care': '아이오케어',
  coway: '구코웨이',
  policy: '약관조회',
  service: '서비스 이용약관',
  'service-cowayid': '서비스 이용약관-코웨이아이디',
  'service-cowaymall': '서비스 이용약관-코웨이닷컴',
  privacy: '개인정보처리방침',
  'privacy-cowayid': '서비스 이용약관-코웨이아이디',
  'privacy-cowaymall': '서비스 이용약관-코웨이닷컴',
  collection: '개인정보 수집 및 미용동의',
  'collection-cowayid': '서비스 이용약관-코웨이아이디',
  'collection-cowaymall': '서비스 이용약관-코웨이닷컴',
  marketing: '광고성 정보 수신동의',
  'user-authenticate': '본인인증',
  success: '성공',
  failure: '실패',
  'change-phone-number': '휴대전화번호 변경',
  'promote-user': '정회원 승격',
  'promote-user-without-login': '로그인 없이 정회원 승격',
  'migrate-user': '회원전환',
  otp: 'OTP 인증',
  email: '이메일',
  'kakao-sync': '카카오싱크 점유인증',
};

export const channelNames = {
  naver: '네이버',
  apple: '애플',
  google: '구글',
  kakao: '카카오',
  facebook: '페이스북',
  line: '라인',
};
