import { storeToRefs } from 'pinia';

import { wrongAccessError } from '@/hooks/useCommonError';
import useNative from '@/hooks/useNative';
import useCommonStore from '@/stores/common';
import { getCountryList, getServiceInfo } from '@/utils/apis';
import { isCowayMall } from '@/utils/clients';
import { getLocale } from '@/utils/locale';
import { clientNameByQuery } from '@/utils/typeGuard';
import { isApp, isIocare } from '@/utils/userAgent';

import { setI18nLocale } from '@/i18n';

/**
 * useInitCommonData
 * @description 공통 데이터(clientName, locale, countryList) 초기 설정
 * - 국가 리스트 api 호출 로직
 */
export default function useInitCommonData() {
  const commonStore = useCommonStore();
  const { setLocale, setClientName, setSupportedCountryList, setServiceInfo } = commonStore;
  const { getClientName: nameByStore, clientName } = storeToRefs(commonStore);
  const { getLocale: getNativeLocale } = useNative();

  const initClientName = (query: { [k: string]: string }, isSetter?: boolean) => {
    if (!isSetter) {
      isClientNameExist(nameByStore.value);
      return;
    }

    if (isApp() && isIocare()) {
      setClientName('IOCARE');
    } else {
      const queryValue = query['client_name'];
      isClientNameExist(queryValue);
      setClientName(clientNameByQuery(queryValue));
    }

    initServiceInfo();
  };

  const isClientNameExist = (value: string | null) => {
    if (!value) {
      wrongAccessError();
      throw new Error('clientName이 존재하지 않습니다.');
    }
  };

  const initLocaleData = () => {
    if (isCowayMall(clientName.value)) {
      setI18nLocale('ko');
      setLocale('ko');
    } else if (isApp()) {
      getNativeLocale();
    } else {
      setLocale(getLocale());
    }
  };

  async function initCountryList() {
    try {
      const response = await getCountryList();
      setSupportedCountryList(response.data.data?.countryList ?? []);
    } catch (e: Error | unknown) {
      console.error(e);
    }
  }
  async function initServiceInfo() {
    try {
      const response = await getServiceInfo();
      setServiceInfo(response.data.data ?? []);
    } catch (e: Error | unknown) {
      console.error(e);
    }
  }

  return { initLocaleData, isClientNameExist, initClientName, initCountryList, initServiceInfo };
}
