/**
 * isObjectEmpty
 * @description 비어있는 Object인지 확인
 * @param data
 * @returns {boolean} 비어있으면 true 반환
 */
export function isObjectEmpty(data: { [k: string]: string | number | boolean }) {
  return !Object.keys(data).length;
}

/**
 * isExistValue
 * @description 리스트에 value 값이 있는지 확인
 * @param list
 * @param value
 * @returns 리스트안에 값이 존재하면 true, 아니면 false
 */
export function isExistValue(list: string[], value: string): boolean {
  return list.some((data) => {
    return Boolean(value.includes(data));
  });
}
