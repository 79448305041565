<script setup lang="ts">
/**
 * ModalDefaultLayout
 * @description 모달 기본 레이아웃
 */

import { MODAL_KEY } from '@/constants/modal';
import { useModalStore } from '@/stores/modal';

import type { ModalKey } from '@/types/modal';

interface Props {
  contentId: ModalKey;
  layoutClickDisabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), { layoutClickDisabled: true });

const modal = useModalStore();

const handleClickLayout = (e: Event) => {
  if (props.layoutClickDisabled) {
    return;
  }

  const layoutName = 'layerWrap';
  const target = (e.target as HTMLInputElement).className;
  if (target.includes(layoutName)) {
    modal.closeModal(props.contentId);
  }
};
</script>

<template>
  <div v-if="contentId !== MODAL_KEY.TOAST" tabindex="0" class="layerWrap" @click.stop.prevent="handleClickLayout">
    <div v-if="contentId === MODAL_KEY.ALERT || contentId === MODAL_KEY.CONFIRM" id="lay_id_result" role="alert" tabindex="0" class="lay_inner lay_id_result">
      <slot></slot>
    </div>
    <div v-else tabindex="0" class="modal-content">
      <slot></slot>
    </div>
  </div>
  <div v-else id="toast-container">
    <div class="toast-wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 768px;
}
#toast-container {
  overflow-y: auto;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
  position: fixed;
  z-index: 201;
  .toast-wrapper {
    width: 100%;
    height: 100px;
    animation: toastUp 0.3s ease-in-out;
    @keyframes toastUp {
      0% {
        opacity: 0.3;
        transform: translateY(50%);
      }
      100% {
        opacity: 1;
        transform: translateY(0%);
      }
    }
  }
}
</style>
