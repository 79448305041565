import type { Ref } from 'vue';

import { AxiosError } from 'axios';
import { reactive, ref, watch } from 'vue';

import { APIS_KEY } from '@/constants/apis';
import { MODAL_KEY } from '@/constants/modal';
import { SHOW_GDPR_COUNTRY_CD_LIST } from '@/constants/terms';
import useEnv from '@/hooks/useEnv';
import useFetch from '@/hooks/useFetch';
import useCommonStore from '@/stores/common';
import type { Condition } from '@/stores/conditionList';
import { useModalStore } from '@/stores/modal';
import { getTermsRevision } from '@/utils/apis';
import { isCowayMall } from '@/utils/clients';
import { getLocale } from '@/utils/locale';
import { postMessage } from '@/utils/postMessage';
import { urlQuery } from '@/utils/urlParameter';

import { setI18nLocale } from '@/i18n';

import type { UseFetch } from '@/types/apis';
import type { AgreeClientData, AgreeClientParam, TermsInfoPayload } from '@/types/loginTerms';
import type { TermsCategoryTypes, TermsCategories, TermsType, ClientName } from '@/types/terms';

type TermsPopupType = 'NORMAL' | 'SEARCH_CATEGORY' | 'GDPR_PRIVACY_POLICY';

/**
 * terms
 * @description 약관 도메인 정의
 */
export default class Terms {
  selectedTermsIds: Array<string>;
  isShowGdpr: Ref<boolean>;
  termsType: Ref<TermsType>;
  termsContent: Ref<string>;
  categoryTypes: string;
  countryCd: string;
  termsCategoryType: Ref<TermsCategoryTypes[] | undefined>;
  categoryTypeResponse: Ref<UseFetch<TermsCategoryTypes[]> | undefined>;
  termsCategories: Ref<TermsCategories | undefined>;
  termsCategoriesResponse: Ref<UseFetch<TermsCategories> | undefined>;
  searchFlag: string;

  constructor() {
    this.selectedTermsIds = reactive([]);
    this.isShowGdpr = ref(false);
    this.termsType = ref('signUp');
    this.termsCategoryType = ref<TermsCategoryTypes[] | undefined>(undefined);
    this.termsContent = ref<string>('');
    this.categoryTypes = '';
    this.countryCd = '';
    this.categoryTypeResponse = ref<UseFetch<TermsCategoryTypes[]> | undefined>();
    this.searchFlag = '';
    this.termsCategories = ref<TermsCategories | undefined>();
    this.termsCategoriesResponse = ref<UseFetch<TermsCategories> | undefined>();
  }

  setCountryCd(value: string) {
    this.countryCd = value;
  }

  setSearchFlag(value: string) {
    this.searchFlag = value;
  }

  /**
   * setCategoryTypes
   * @description 마이페이지, 회원가입에서 사용되는 메서드, renderFooterTerms 호출 전에 사용
   * 마이페이지: setCategoryTypes("9")
   * 회원가입: setCategoryTypes("8")
   * @param {string} type 카테고리 타입
   */
  setCategoryTypes = (type: string): void => {
    this.categoryTypes = type;
  };

  setIsShowGdprMessage = (countryCd: string): void => {
    this.isShowGdpr.value = SHOW_GDPR_COUNTRY_CD_LIST.includes(countryCd);
  };

  setConditionList = (termsCategoryType: TermsCategoryTypes[], checked: Set<string>): Condition => {
    return termsCategoryType.reduce<Condition>(
      (prev, { itemName, categoryType }) => ({
        ...prev,
        [itemName]: checked.has(categoryType),
      }),
      {},
    );
  };

  getTermsList = (isPopup?: boolean): UseFetch<TermsCategoryTypes[]> => {
    const { getClientName, locale } = useCommonStore();

    const params = {
      clientName: getClientName,
      locale: getClientName === 'COWAY_MALL' ? 'ko' : locale,
      countryCd: this.countryCd,
      categoryTypes: this.categoryTypes,
      searchFlag: this.searchFlag,
    };

    switch (this.termsType.value) {
      case 'add':
      case 'signUp':
        return useFetch<TermsCategoryTypes[]>({
          url: urlQuery(APIS_KEY.TERMS_CATEGORY_TYPE_API, params),
          isPopup,
        });
      default:
        throw Error('termsType not found');
    }
  };

  /**
   * signUpTermsCategoryType
   * @description 회원가입 약관 리스트 불러오기
   */
  signUpTermsCategoryType = (isPopup?: boolean) => {
    const { isPending, isSuccess, data, error } = this.getTermsList(isPopup);

    watch([isPending, isSuccess, data, error], () => {
      this.categoryTypeResponse.value = {
        isPending,
        isSuccess,
        data,
        error,
      };

      if (isSuccess.value) {
        this.termsCategoryType.value = data.value?.data;
      }
    });
  };

  searchCategory = (type: TermsPopupType) => {
    const { getClientName, locale } = useCommonStore();

    const params = {
      clientName: getClientName,
      locale: getClientName === 'COWAY_MALL' ? 'ko' : locale,
      countryCd: this.countryCd,
      categoryTypes: this.categoryTypes,
      searchFlag: this.searchFlag,
    };

    const { isSuccess, isPending, data, error } = useFetch<TermsCategories>({
      url: urlQuery(APIS_KEY.TERMS_CATEGORY_SEARCH_API, params, { includeEmptyValue: true }),
    });
    watch([isSuccess, isPending, data, error], () => {
      this.termsCategoriesResponse.value = { isSuccess, isPending, data, error };

      if (isSuccess.value && data.value?.data) {
        this.termsCategories.value = data.value?.data;
        type !== 'SEARCH_CATEGORY' && this.openPopup();
      }
    });
  };

  /**
   * getTermsRevisionById
   * @description historyId 에 따라 약관 컨텐츠를 호출
   * - countryCd: footer약관, 탭 클릭 이벤트 발생 시, 사용됨.
   *   footer약관 일 경우 countryCd를 가져오고, 탭 이벤트 발생일 경우 undefined가 넘어옴
   * @param {string} historyId getTermsHistories함수에서 받은 결과 historyId로 컨텐츠를 가져온다.
   * @param {string} countryCd api 호출을 위한 국가 코드, 빈값일 경우 영문 약관 가져옴
   * @returns {Promise<void>}
   */
  getTermsRevisionById = async (historyId: string): Promise<void> => {
    const revisionData = (await getTermsRevision({ historyId, countryCd: this.countryCd })).data;
    if (!revisionData.data) {
      throw new AxiosError('No Revision Data');
    }
    this.termsContent.value = revisionData.data.content;
  };

  /**
   * openPopup
   * @description 팝업 오픈
   */
  openPopup(): void {
    const { openModal } = useModalStore();

    const props = {
      title: this.termsCategories.value?.displayTitle,
      categoryList: this.termsCategories.value?.categoryList,
      countryCd: this.countryCd,
    };

    openModal(MODAL_KEY.TERMS, props);
  }
}

export class FooterTerms extends Terms {
  title: Ref<string>;

  constructor() {
    super();
    this.title = ref<string>('');
  }

  /**
   * footerTermsInfo
   * @description 푸터약관 타이틀 및 약관 api 호출 id 결정
   * @param {string} type /policy/[a]-[b] 중 a 값. (UPPERCASE로 전달) ex) SERVICE, PRIVACY
   */
  footerTermsInfo(type: string) {
    const { getClientName } = useCommonStore();

    switch (type) {
      case 'SERVICE':
        this.title.value = 'terms.termsOfService';
        this.setCategoryTypes(getClientName === 'IOCARE' ? '3,4' : '3');

        break;
      case 'PRIVACY':
        this.title.value = 'terms.privacyPolicy';
        this.setCategoryTypes('10');

        break;
      default:
        this.title.value = 'terms.kakaoSyncPersonalInformation';
        this.setCategoryTypes('11');

        break;
    }
  }

  /**
   * isShow
   * @description 푸터 약관 노출 여부 값
   * @returns {boolean}
   */
  get isShow(): boolean {
    return this.title.value.length > 0 && !!this.termsCategories.value?.categoryList;
  }

  /**
   * openFooterTerms
   * @description 푸터 클릭시 새로운 약관 페이지 오픈
   * @param policyId 이용약관과 개인정보처리방침 구분 타입 (service, privacy)
   */
  openFooterTerms(policyId: string, clientName: string, countryCd?: string): void {
    const { VITE_API_ORIGIN } = useEnv();
    const serviceName = clientName === 'COWAY_MALL' ? 'cowayid' : clientName.toLowerCase();
    window.open(urlQuery(`${VITE_API_ORIGIN}/policy/${policyId}-${serviceName}`, countryCd ? { countryCd } : {}));
  }
}

export class LoginTerms extends Terms {
  cwid: Ref<string>;
  agreementClientResponse: Ref<UseFetch<AgreeClientData> | undefined>;

  constructor() {
    super();
    this.cwid = ref<string>('');
    this.agreementClientResponse = ref<UseFetch<AgreeClientData> | undefined>();
  }

  setCwid(value: string) {
    this.cwid.value = value;
  }

  /**
   * agreeClient
   * @description 추가약관 동의
   */
  agreeClient(requestData: AgreeClientParam) {
    const { VITE_LOGIN_ORIGIN_URL } = useEnv();

    const { isSuccess, isPending, data, error } = useFetch<AgreeClientData>({
      url: APIS_KEY.AGREEMENT_CLIENT,
      method: 'POST',
      requestData,
    });
    watch([isSuccess, isPending, data, error], () => {
      this.agreementClientResponse.value = { isSuccess, isPending, data, error };

      if (isSuccess.value) {
        postMessage('agreeComplete', VITE_LOGIN_ORIGIN_URL);
      }
    });
  }

  /**
   * loginTermsCategoryType
   * @description 추가약관 리스트 불러오기
   */
  loginTermsCategoryType(payload: TermsInfoPayload) {
    const { setClientName } = useCommonStore();
    const { cwid, clientName, locale, searchFlag, countryCd } = payload;

    setClientName(clientName);
    this.setLoginTermsLanguage(clientName, locale);
    this.setCwid(cwid);
    this.setSearchFlag(searchFlag);
    this.setCountryCd(countryCd);
    this.setIsShowGdprMessage(this.countryCd);
    this.signUpTermsCategoryType(true);
  }

  /**
   * setLoginTermsLanguage
   * @description 추가약관 언어 및 로케일 설정
   * - setLocale은 api 통신에 사용되므로 getLocale을 사용하지 않는다.
   */
  setLoginTermsLanguage(clientName: ClientName, locale: string) {
    const { setLocale } = useCommonStore();

    if (isCowayMall(clientName)) {
      setI18nLocale('ko');
      setLocale('ko');
    } else {
      setI18nLocale(getLocale(locale));
      setLocale(getLocale(locale));
    }
  }
}
