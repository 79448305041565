import type { RouteRecordRaw } from 'vue-router';

import { createWebHistory, createRouter } from 'vue-router';

import { userAuthenticationGaTitleMap } from '@/constants/gtm';
import useInitCommonData from '@/hooks/useInitCommonData';
import useLoginCheck from '@/hooks/useLoginCheck';
import useCommonStore from '@/stores/common';
import { useModalStore } from '@/stores/modal';
import { isObjectEmpty } from '@/utils/common';
import { initGtag, sendGaPageViewEvent, sendPageViewEventToGtm } from '@/utils/gtmUtils';
import { getKeyFromQueryParam } from '@/utils/urlParameter';

import type { ErrorType } from '@/types/commonError';

const SignUpAskAgree = () => import('@/views/signUp/SignUpAskAgree.vue');
const SignUpCheckUser = () => import('@/views/signUp/SignUpCheckUser.vue');
const SignUpVerifyUser = () => import('@/views/signUp/SignUpVerifyUser.vue');
const SignUpAskInfo = () => import('@/views/signUp/SignUpAskInfo.vue');
const SignUpComplete = () => import('@/views/signUp/SignUpComplete.vue');
const FindId = () => import('@/views/findUser/FindId.vue');
const FindIdAuthenticateWithUserInfo = () => import('@/views/findUser/FindIdAuthenticateWithUserInfo.vue');
const FindPassword = () => import('@/views/findUser/FindPassword.vue');
const FindPasswordVerifyUser = () => import('@/views/findUser/FindPasswordVerifyUser.vue');
const FindPasswordComplete = () => import('@/views/findUser/FindPasswordComplete.vue');
const MyAccountEdit = () => import('@/views/myAccount/MyAccountEdit.vue');
const MyAccountManageSocialLogin = () => import('@/views/myAccount/MyAccountManageSocialLogin.vue');
const MyAccountDelete = () => import('@/views/myAccount/MyAccountDelete.vue');
const UserAuthenticateSuccess = () => import('@/views/UserAuthenticateSuccess.vue');
const UserAuthenticateFailure = () => import('@/views/UserAuthenticateFailure.vue');
const OtpEmail = () => import('@/views/OtpEmail.vue');
const Policy = () => import('@/views/Policy.vue');
const LoginTerms = () => import('@/views/LoginTerms.vue');
const CommonError = () => import('@/views/CommonError.vue');

const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/sign-up/ask-agree',
    name: 'SignUpAskAgree',
    meta: { clientNameRequired: { isRequired: true, isSetter: true } },
    component: SignUpAskAgree,
  },
  {
    path: '/sign-up/check-user',
    name: 'SignUpCheckUser',
    meta: {
      gaTitle: '회원가입 아이디 입력',
      prevPage: ['/sign-up/ask-agree'],
      nextPage: ['/sign-up/verify-phone-number', '/sign-up/verify-email', '/sign-up/ask-info', '/find-id', '/find-password'],
      clientNameRequired: { isRequired: true },
    },
    component: SignUpCheckUser,
  },
  {
    path: '/sign-up/verify-phone-number',
    name: 'SignUpVerifyPhoneNumber',
    meta: { gaTitle: '회원가입_휴대폰 점유인증', prevPage: ['/sign-up/check-user'], nextPage: ['/sign-up/ask-info'], clientNameRequired: { isRequired: true } },
    component: SignUpVerifyUser,
  },
  {
    path: '/sign-up/verify-email',
    name: 'SignUpVerifyEmail',
    meta: { gaTitle: '회원가입_이메일 인증', prevPage: ['/sign-up/check-user'], nextPage: ['/sign-up/ask-info'], clientNameRequired: { isRequired: true } },
    component: SignUpVerifyUser,
  },
  {
    path: '/sign-up/ask-info',
    name: 'SignUpAskInfo',
    meta: {
      gaTitle: '회원가입 정보입력',
      prevPage: ['/sign-up/check-user', '/sign-up/verify-email', '/sign-up/verify-phone-number'],
      nextPage: ['/sign-up/complete'],
      clientNameRequired: { isRequired: true },
    },
    component: SignUpAskInfo,
  },
  {
    path: '/sign-up/complete',
    name: 'SignUpComplete',
    meta: { gaTitle: '회원가입 완료', prevPage: ['/sign-up/ask-info'], clientNameRequired: { isRequired: true } },
    component: SignUpComplete,
  },
  {
    name: 'FindId',
    path: '/find-id',
    meta: { gaTitle: '아이디 찾기 시작', clientNameRequired: { isRequired: true, isSetter: true } },
    component: FindId,
  },
  {
    name: 'FindIdAuthenticateWithUserInfo',
    path: '/find-id/user-info-authenticate',
    meta: { gaTitle: '아이디 찾기_가입정보' },
    component: FindIdAuthenticateWithUserInfo,
  },
  {
    name: 'FindPassword',
    path: '/find-password',
    meta: { gaTitle: '비밀번호 찾기 시작', clientNameRequired: { isRequired: true, isSetter: true } },
    component: FindPassword,
  },
  {
    name: 'FindPasswordVerifyUser',
    path: '/find-password/email-otp-authenticate',
    meta: { gaTitle: '비밀번호 찾기_이메일 인증', clientNameRequired: { isRequired: true, isSetter: true } },
    component: FindPasswordVerifyUser,
  },
  {
    name: 'FindPasswordComplete',
    path: '/find-password/complete',
    meta: { gaTitle: '비밀번호 찾기 완료' },
    component: FindPasswordComplete,
  },
  // 회원정보관리
  { path: '/my-account', redirect: { name: 'MyAccountEdit' } },
  { path: '/my-account/verify-password', redirect: { name: 'MyAccountEdit' } },
  {
    name: 'MyAccountEdit',
    path: '/my-account/edit',
    meta: { loginRequired: true, gaTitle: '회원정보 변경_정보 수정', clientNameRequired: { isRequired: true, isSetter: true } },
    component: MyAccountEdit,
  },
  {
    name: 'MyAccountManageSocialLogin',
    path: '/my-account/manage-social-login',
    meta: { loginRequired: true, gaTitle: '소셜로그인 관리', clientNameRequired: { isRequired: true } },
    component: MyAccountManageSocialLogin,
  },
  {
    name: 'MyAccountDelete',
    path: '/my-account/delete',
    meta: { loginRequired: true, gaTitle: '회원탈퇴 시작', clientNameRequired: { isRequired: true } },
    component: MyAccountDelete,
  },
  // 본인인증 결과 수신
  {
    name: 'UserAuthenticateSuccess',
    path: '/user-authenticate/:purpose/success',
    component: UserAuthenticateSuccess,
  },
  {
    name: 'UserAuthenticateFailure',
    path: '/user-authenticate/:purpose/failure',
    component: UserAuthenticateFailure,
  },
  {
    name: 'OtpEmail',
    path: '/otp/email',
    meta: { clientNameRequired: { isRequired: true, isSetter: true } },
    component: OtpEmail,
  },
  {
    name: 'Policy',
    path: '/policy/:type',
    meta: { gaTitle: '약관조회_시작' },
    component: Policy,
  },
  // 로그인 후 약관 동의 팝업
  {
    name: 'LoginTerms',
    path: '/login/terms',
    component: LoginTerms,
  },
  // 에러 페이지
  {
    name: 'ErrorPage',
    path: '/error',
    component: CommonError,
    props: true,
  },
  {
    name: 'EtcErrorPage',
    path: '/error/service-unavailable',
    redirect: () => {
      return { name: 'ErrorPage', state: { errorType: 'etc' as ErrorType } };
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/error',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to) => {
  initGtag();

  const path = window.location.pathname;
  if (path?.startsWith('/user-authenticate')) {
    sendGaPageViewEvent(userAuthenticationGaTitleMap.get(path));
  } else if (to.meta?.gaTitle) {
    sendGaPageViewEvent(to.meta.gaTitle);
  }

  sendPageViewEventToGtm(window.location.pathname);
});

router.beforeEach((to, from, next) => {
  const { initClientName } = useInitCommonData();
  const { setPrevPage, setLogoutUri, setLoginOnly, setLoginPageUri } = useCommonStore();
  const { meta, path, query } = to;
  const { clientNameRequired, loginRequired } = meta;
  const params = getKeyFromQueryParam();
  const paramsCheckedEmpty = !isObjectEmpty(params) ? params : (query as { [k: string]: string });

  clientNameRequired && initClientName(paramsCheckedEmpty, clientNameRequired?.isSetter);

  const { isLoginRequiredError, promptLogout, validateLoginRequiredPage } = useLoginCheck(path, query as Record<string, string>);
  if (isLoginRequiredError()) return;

  const prevPageUri = params['prev-page-uri'] ?? null;
  const logoutUri = params['logout-uri'] ?? null;
  const loginOnly = params['login_only'] ?? false;
  const loginPageUri = params['login-page-uri'] ?? null;
  if (prevPageUri) {
    setPrevPage(prevPageUri);
  }
  if (logoutUri) {
    setLogoutUri(logoutUri);
  }
  if (loginPageUri) {
    setLoginPageUri(loginPageUri);
  }
  setLoginOnly(loginOnly === 'true');

  validateLoginRequiredPage(loginRequired, next, promptLogout);
});

window.addEventListener('popstate', () => {
  const { closeModalAll } = useModalStore();
  closeModalAll();
});

export default router;
