import { AUTH_TYPE } from '@/constants/authentication';
import { actions, channelNames, urlPath } from '@/constants/gtm';
import useEnv from '@/hooks/useEnv';
import useCommonStore from '@/stores/common';

import { i18n } from '@/i18n';

import type { AuthType } from '@/types/authentication';
import type { SocialLoginProvider } from '@/types/myAccountSocialLogin';

/**
 * GtmCustomEvent
 * @description ga event를 쌓는 util
 */
export class GtmCustomEvent {
  constructor(private event: string = 'customEvent', private category?: string, private action?: string, private label?: string) {}

  getInstance(): GtmCustomEvent {
    return new GtmCustomEvent(this.event, this.category, this.action, this.label);
  }

  push(): void {
    window.dataLayer.push({
      event: this.event,
      category: this.category,
      action: this.action,
      label: this.label,
    });
  }

  setCategory(category: string): GtmCustomEvent {
    this.category = category;
    return this;
  }
  setAction(action: string): GtmCustomEvent {
    this.action = action;
    return this;
  }
  setLabel(label: string): GtmCustomEvent {
    this.label = label;
    return this;
  }
}

const signUpGtmCustomEvent = new GtmCustomEvent().setCategory('회원가입');

/**
 * sendSignUpCompleteEventToGtm
 * @description 회원가입 완료 이벤트를 GTM에 보낸다. 전화번호 혹은 이메일로 구분된다.
 * @param {AuthType} signUpAuthenticationType 휴대폰 OTP, 휴대폰 본인인증, 이메일 OTP
 * @returns void
 */
export const sendSignUpCompleteEventToGtm = (signUpAuthenticationType: AuthType): void => {
  if (!signUpAuthenticationType) {
    console.warn('signUpAuthenticationType must be provided to send SignUpComplete event to GA');
    return;
  }

  const { PHONE, PHONE_NUMBER_OTP } = AUTH_TYPE;
  const signUpAuthenticationTypeLabel = signUpAuthenticationType === PHONE || signUpAuthenticationType === PHONE_NUMBER_OTP ? '전화번호' : '이메일';

  signUpGtmCustomEvent.getInstance().setAction('회원가입 - 신규가입 완료').setLabel(signUpAuthenticationTypeLabel).push();
};

/**
 * sendSocialLoginConnectionEventToGtm
 * @description 소셜로그인 연결 혹은 해제 이벤트를 GTM에 보낸다.
 * @param {SocialLoginProvider['provider']} providerName Identity provider의 이름. ex) google, apple
 * @param {boolean} isConnecting 소셜로그인 연결 시도인지 여부
 * @returns void
 */
export const sendSocialLoginConnectionEventToGtm = (providerName: SocialLoginProvider['provider'], isConnecting: boolean): void => {
  if (!providerName) {
    return;
  }

  const action = isConnecting ? 'connect' : 'disconnect';

  new GtmCustomEvent()
    .setCategory('로그인')
    .setAction(`로그인 - 간편로그인 ${i18n.global.t(action)}`)
    .setLabel(channelNames[providerName])
    .push();
};

/**
 * sendGaEventByLoginProvider
 * @description provider에 따른 이벤트를 GA에 보낸다
 * @param {string} providerName
 * @param {boolean} isTurningOn
 * @returns void
 */
export const sendGaEventByLoginProvider = (providerName: string, isTurningOn: boolean) => {
  if (!providerName) return;
  const baseEventName = `myAccountManageSocialLogin${providerName[0].toUpperCase() + providerName.slice(1)}`;
  window.sendGaEvent(baseEventName + isTurningOn ? 'Click' : 'Disconnect');
};

type Pathname = typeof window.location.pathname;

type PathByDepth = { [key: string]: string };

/**
 * findPathName
 * @description path와 매핑된 경로명을 반환한다.
 * @param {string} path find-id, find-password 등의 경로.
 * @returns {string} path와 매핑된 경로명 ex) 아이디찾기, 비밀번호 찾기
 */
const findPathName = (path: string): string => {
  return urlPath[path] ?? '';
};

/**
 * buildPageInfoFromPathname
 * @description 주어진 pathname에 페이지 깊이를 더해서 반환한다.
 * @param {Pathname} pathname "/path/to/page" 형식의 페이지 경로 .
 * @returns {(PathByDepth|null)} 페이지 경로를 깊이로 구분한 객체를 반환한다.
 * ex) { depth1: '통합회원', depth2: '통합회원|회원가입', depth3: '통합회원|회원가입|정보입력' }
 */
export const buildPageInfoFromPathname = (pathname: Pathname): PathByDepth | null => {
  if (!pathname) {
    return null;
  }

  const paths = pathname.split('/').filter((path) => path);

  const pathByDepth: PathByDepth = {
    depth1: '통합회원',
  };

  paths.reduce((result, currentPath, idx) => {
    const path = idx === 0 ? `${pathByDepth.depth1}|${findPathName(result)}` : `${result}|${findPathName(currentPath)}`;
    pathByDepth[`depth${idx + 2}`] = path;
    return path;
  }, paths[0]);

  return pathByDepth;
};

/**
 * sendPageViewEventToGtm
 * @description 현재 페이지의 경로에 깊이값을 더한 정보를 페이지뷰 이벤트로 GTM에 보낸다.
 * @param {Pathname} pathname 현재 페이지의 경로(window.location.pathname)
 * @returns void
 */
export const sendPageViewEventToGtm = (pathname: Pathname): void => {
  const { clientName } = useCommonStore();
  const pageInfo = buildPageInfoFromPathname(pathname);
  if (!pageInfo) {
    return;
  }

  const isPageInfoTranslationIncomplete = Object.values(pageInfo).some((path) => path.startsWith('urlPath.'));
  if (isPageInfoTranslationIncomplete) {
    return;
  }

  window.dataLayer.push({
    event: 'gtm_pageview',
    pageInfo,
    channel: clientName,
  });
};

/**
 * sendGaEvent
 * @description 이벤트 정보를 GA에 전송한다.
 * @param {string} eventName GA 이벤트 이름. pageview, customEvent 등
 * @param {string} [category='engagement'] GA 이벤트 카테고리. 현재는 구분하지 않고 engagement를 사용한다.
 * @param {string} [label='method'] GA 이벤트 세부 카테고리.
 * @returns void
 */
export const sendGaEvent = (eventName: string, category = 'engagement', label = 'method'): void => {
  if (!eventName) {
    return;
  }

  try {
    window.gtag('event', actions[eventName], {
      event_category: category,
      event_label: label,
      send_to: useEnv().VITE_GA_TAG_ID,
    });
  } catch (error) {
    console.warn(`Failed to send a event to GA. error: ${JSON.stringify(error)}`);
  }
};

/**
 * sendGaPageViewEvent
 * @description 현재 페이지정보를 GA에 전송한다.
 * @param {string} [pageTitle] 현재 페이지 제목
 * @param {string} [pagePath=window.location.pathname] 현재 페이지 경로
 * @param {string} [pageLocation=window.location.href] 현재 페이지 전체 주소
 */
export const sendGaPageViewEvent = (pageTitle?: string, pagePath = window.location.pathname, pageLocation = window.location.href): void => {
  try {
    window.gtag('event', 'page_view', {
      page_title: pageTitle,
      page_location: pageLocation,
      page_path: pagePath,
      send_to: useEnv().VITE_GA_TAG_ID,
    });
  } catch (error) {
    console.warn(`Failed to send page view event to GA. error: ${JSON.stringify(error)}`);
  }
};

/**
 * initGtag
 * @description GA 초기화
 */
export const initGtag = () => {
  window.gtag = function gtag([...args]) {
    window.dataLayer.push(args);
  };

  window.gtag('js', new Date());

  window.gtag('config', useEnv().VITE_GA_TAG_ID, {
    send_page_view: false,
  });
};
