import useCommonStore from '@/stores/common';
import { getLocale } from '@/utils/locale';

import { setI18nLocale } from '@/i18n';

export const nativeEvent = {
  setLocale: (response: { locale: string }): void => {
    const locale = getLocale(response.locale);
    setI18nLocale(locale);
    useCommonStore().setLocale(locale);
  },
};
