import type { Ref } from 'vue';

import { ref, watch } from 'vue';

import { APIS_KEY, API_CODES } from '@/constants/apis';
import { MODAL_KEY } from '@/constants/modal';
import useFetch from '@/hooks/useFetch';
import { useModalStore } from '@/stores/modal';
import { getValidationError } from '@/utils/apis';

import { i18n } from '@/i18n';

import type { UseFetch } from '@/types/apis';
import type { MyAccountEditChangePasswordData, MyAccountPasswordVerifyData, PasswordNeedCheckData } from '@/types/myAccount';

interface Passwords {
  currPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export default class MyAccountChangePassword {
  needCheckResponse: Ref<UseFetch<PasswordNeedCheckData> | undefined>;
  checkValidResponse: Ref<UseFetch<MyAccountPasswordVerifyData> | undefined>;
  changePasswordResponse: Ref<UseFetch<MyAccountEditChangePasswordData> | undefined>;
  serverSentError: Ref<Passwords>;
  serverSentErrorNewPassword: Ref<string>;
  currPassword: string;
  newPassword: string;
  newPasswordConfirm: string;

  constructor() {
    this.needCheckResponse = ref<UseFetch<PasswordNeedCheckData> | undefined>();
    this.checkValidResponse = ref<UseFetch<MyAccountPasswordVerifyData> | undefined>();
    this.changePasswordResponse = ref<UseFetch<MyAccountEditChangePasswordData> | undefined>();
    this.currPassword = '';
    this.newPassword = '';
    this.newPasswordConfirm = '';
    this.serverSentErrorNewPassword = ref<string>('');
    this.serverSentError = ref<Passwords>({ currPassword: '', newPassword: '', newPasswordConfirm: '' });
  }

  setCurrPassword(value: string) {
    this.currPassword = value;
  }
  setPasswords(passwords: Passwords) {
    this.currPassword = passwords.currPassword;
    this.newPassword = passwords.newPassword;
    this.newPasswordConfirm = passwords.newPasswordConfirm;
  }

  openChangePasswordModal() {
    const { isPending, isSuccess, data, error } = useFetch<PasswordNeedCheckData>({
      url: APIS_KEY.USERINFO_PASSWORD_NEED_CHECK,
      method: 'get',
      isPopup: true,
    });
    const { openModal } = useModalStore();

    watch([isPending, isSuccess, data, error], () => {
      this.needCheckResponse.value = {
        isPending,
        isSuccess,
        data,
        error,
      };

      if (error.value) {
        openModal(MODAL_KEY.ALERT, {
          message: i18n.global.t('alert.message.checkNetwork'),
        });
        return;
      }

      if (isSuccess.value) {
        window.sendGaEvent('myAccountChangePasswordStart');
        openModal(MODAL_KEY.CHANGE_PASSWORD, { isUserPasswordCheckRequired: data.value?.data.result });
        return;
      }
    });
  }

  checkValid(password: string, type?: string) {
    this.setCurrPassword(password);

    if (this.currPassword.length === 0) {
      this.serverSentError.value = { ...this.serverSentError.value, currPassword: '' };
      return;
    }

    const { isPending, isSuccess, data, error } = useFetch<MyAccountPasswordVerifyData>({
      url: APIS_KEY.USERINFO_PASSWORD_CHECK_VALID,
      method: 'post',
      requestData: { password: this.currPassword },
      isPopup: true,
    });

    watch([isPending, isSuccess, data, error], () => {
      this.checkValidResponse.value = {
        isPending,
        isSuccess,
        data,
        error,
      };

      if (isSuccess.value) {
        switch (type) {
          case 'currPassword':
            this.serverSentError.value = { ...this.serverSentError.value, currPassword: !data.value?.data.result ? i18n.global.t('validation.passwordMismatch') : '' };
            break;
          case 'newPassword':
            this.serverSentError.value = { ...this.serverSentError.value, newPassword: data.value?.data.result ? i18n.global.t('validation.passwordSamePrevious') : '' };
            break;
        }
      } else {
        this.serverSentError.value = { currPassword: '', newPassword: '', newPasswordConfirm: '' };
      }
    });
  }

  changePassword(passwords: Passwords) {
    const { openModal, closeModalAll } = useModalStore();
    this.setPasswords(passwords);

    const { isPending, isSuccess, data, error } = useFetch<MyAccountEditChangePasswordData>({
      url: APIS_KEY.USERINFO_PASSWORD,
      method: 'patch',
      requestData: {
        previousPassword: this.currPassword,
        newPassword: this.newPassword,
        confirmPassword: this.newPasswordConfirm,
      },
      isPopup: true,
    });

    watch([isPending, isSuccess, data, error], () => {
      this.changePasswordResponse.value = {
        isPending,
        isSuccess,
        data,
        error,
      };

      if (isSuccess.value && Boolean(data.value?.data.cwid)) {
        window.sendGaEvent('myAccountChangePasswordSuccess');
        openModal(MODAL_KEY.ALERT, {
          message: i18n.global.t('alert.message.completeChangePassword'),
          onClick: closeModalAll,
          onClickClose: closeModalAll,
        });
        return;
      }
      if (error.value) {
        const responseCode = error.value?.response?.data.code ?? '';
        switch (responseCode) {
          case API_CODES.PASSWORD_SAME_PREVIOUS:
            this.serverSentError.value = { ...this.serverSentError.value, newPassword: getValidationError(error.value) };
            break;
          case API_CODES.PASSWORD_NOT_CORRECT:
            this.serverSentError.value = { ...this.serverSentError.value, currPassword: getValidationError(error.value) };
            break;
        }
      }
    });
  }

  get initServerSentError() {
    return { currPassword: '', newPassword: '', newPasswordConfirm: '' };
  }
}
