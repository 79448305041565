/**
 * 회원가입, 내정보수정에서 정할 수 있는 회원정보 저장기간.
 */
export const PRIVACY_EXPIRY_DATE = {
  ONE_YEAR: 'ONE_YEAR',
  WITHDRAW: 'WITHDRAW',
} as const;

export const SHOW_GDPR_COUNTRY_CD_LIST = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
];
