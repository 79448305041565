<script setup lang="ts">
/**
 * PopupPersonalInfoTermsCollectionStatus
 * @description 개인정보 이용내역 팝업 - 수집/이용내역 컴포넌트
 */
import { onMounted } from 'vue';

import { PersonalInfoTerms } from '@/domains/personalInfoTerms';

const personalInfoTerms = new PersonalInfoTerms();

onMounted(() => {
  personalInfoTerms.getPersonalInfoTerms();
});
</script>

<template>
  <div id="use_info_tab2" class="use_info_tab on">
    <h4 class="tit_section">{{ $t('terms.historyOfGatherAndUsage') }}</h4>
    <table
      v-if="personalInfoTerms.personalInfoTermsStatus.value.regularLevelAt"
      id="personal-info-sharing-status-table-for-regular-user"
      class="regluar_area"
      cellpadding="0"
      cellspacing="0"
      border="0">
      <colgroup>
        <col width="35%" />
        <col width="65%" />
      </colgroup>
      <tbody>
        <tr>
          <th>{{ $t('general.category') }}</th>
          <td>{{ $t('general.basic') }}({{ $t('general.required') }})</td>
        </tr>
        <tr>
          <th>{{ $t('terms.personalInfo') }}</th>
          <td>
            <ul class="list_type2">
              <li>{{ $t('terms.personalInfoItem6') }}</li>
              <li>
                [{{ $t('general.select') }}]
                {{ $t('terms.selfCertEmail') }}
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <th>{{ $t('general.agreeDate') }}</th>
          <td>{{ personalInfoTerms.personalInfoTermsStatus.value.signupAt }}</td>
        </tr>
      </tbody>
    </table>

    <table class="associate_area" cellpadding="0" cellspacing="0" border="0">
      <colgroup>
        <col width="35%" />
        <col width="65%" />
      </colgroup>
      <tbody>
        <tr>
          <th>{{ $t('general.category') }}</th>
          <td>{{ $t('general.basic') }}({{ $t('general.required') }})</td>
        </tr>
        <tr>
          <th>{{ $t('terms.personalInfo') }}</th>
          <td>
            <ul class="list_type2">
              <li>{{ $t('terms.signupCertInfo') }}</li>
            </ul>
          </td>
        </tr>
        <tr>
          <th>{{ $t('general.agreeDate') }}</th>
          <td>{{ personalInfoTerms.personalInfoTermsStatus.value.signupAt }}</td>
        </tr>
      </tbody>
    </table>

    <table class="associate_area" cellpadding="0" cellspacing="0" border="0">
      <colgroup>
        <col width="35%" />
        <col width="65%" />
      </colgroup>
      <tbody>
        <tr>
          <th>{{ $t('general.category') }}</th>
          <td>{{ $t('terms.autoGeneratedInfo') }}</td>
        </tr>
        <tr>
          <th>{{ $t('terms.personalInfo') }}</th>
          <td>
            <ul class="list_type2">
              <li>
                {{ $t('terms.personalInfoItem1') }}
              </li>
              <li>{{ $t('terms.personalInfoItem2') }}</li>
            </ul>
          </td>
        </tr>
        <tr>
          <th>{{ $t('general.agreeDate') }}</th>
          <td>{{ personalInfoTerms.personalInfoTermsStatus.value.signupAt }}</td>
        </tr>
      </tbody>
    </table>

    <table v-if="personalInfoTerms.personalInfoTermsStatus.value.regularLevelAt" class="regluar_area" cellpadding="0" cellspacing="0" border="0">
      <colgroup>
        <col width="35%" />
        <col width="65%" />
      </colgroup>
      <tbody>
        <tr>
          <th>{{ $t('general.category') }}</th>
          <td>{{ $t('terms.selfConfirm') }}</td>
        </tr>
        <tr>
          <th>{{ $t('terms.personalInfo') }}</th>
          <td>
            <ul class="list_type2">
              <li>
                {{ $t('terms.personalInfoItem3') }}
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <th>{{ $t('general.agreeDate') }}</th>
          <td>{{ personalInfoTerms.personalInfoTermsStatus.value.regularLevelAt }}</td>
        </tr>
      </tbody>
    </table>

    <table
      v-if="personalInfoTerms.personalInfoTermsStatus.value.existPurchaseHistory"
      id="personal-info-sharing-status-table-for-user-with-purchasement-history"
      cellpadding="0"
      cellspacing="0"
      border="0">
      <colgroup>
        <col width="35%" />
        <col width="65%" />
      </colgroup>
      <tbody>
        <tr>
          <th>{{ $t('general.category') }}</th>
          <td>{{ $t('general.cowayDotCom') }}</td>
        </tr>
        <tr>
          <th>{{ $t('terms.personalInfo') }}</th>
          <td>
            <ul class="list_type2">
              <li>{{ $t('terms.personalInfoItem4') }}</li>
              <li>
                [{{ $t('general.select') }}]
                {{ $t('terms.personalInfoItem5') }}
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <th>{{ $t('general.agreeDate') }}</th>
          <td>{{ personalInfoTerms.personalInfoTermsStatus.value.signupAt }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
