<script setup lang="ts">
/**
 * PopupPersonalInfoTerms
 * @description 개인정보 이용내역 팝업 컴포넌트
 */

import { MODAL_KEY } from '@/constants/modal';
import { useModalStore } from '@/stores/modal';

import ButtonAtom from '@/components/atoms/ButtonAtom/index.vue';
import PopupLayout from '@/components/molecules/layout/PopupLayout.vue';
import PopupPersonalInfoTermsCollectionPurpose from '@/components/molecules/modal/popup/PopupPersonalInfoTermsCollectionPurpose.vue';
import PopupPersonalInfoTermsCollectionStatus from '@/components/molecules/modal/popup/PopupPersonalInfoTermsCollectionStatus.vue';
import PopupPersonalInfoTermsSharingStatus from '@/components/molecules/modal/popup/PopupPersonalInfoTermsSharingStatus.vue';

const { closeModal } = useModalStore();

const handleClickClose = () => {
  closeModal(MODAL_KEY.PERSONAL_INFO_TERMS);
  document.getElementsByTagName('html')[0].style.overflow = 'auto';
};
</script>

<template>
  <PopupLayout id="lay_use_info" :content-id="MODAL_KEY.PERSONAL_INFO_TERMS" :title="$t('terms.personalInfoUsageHistory')">
    <template #tab>
      <div class="u-tabs-wrap">
        <p class="notice_txt">
          {{ $t('terms.descriptionOfPersonalInfoUsageHistory') }}
        </p>
      </div>
    </template>

    <template #section>
      <div class="layCon fixBtn">
        <PopupPersonalInfoTermsCollectionPurpose />
        <PopupPersonalInfoTermsCollectionStatus />
        <PopupPersonalInfoTermsSharingStatus />
      </div>
    </template>

    <template #footer>
      <footer class="u-bottom-wrap">
        <ButtonAtom tabindex="0" color="btnBlack" size="sizeL" auto :label="$t('general.close')" :on-click="handleClickClose" :on-keyup="handleClickClose" />
      </footer>
    </template>
  </PopupLayout>
</template>
