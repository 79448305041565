import type { AxiosError } from 'axios';

import { APIS_KEY, COMMON_ERROR_MESSAGE, COMMON_ERROR_MESSAGE_TYPE } from '@/constants/apis';
import useCommonStore from '@/stores/common';
import { fetchData } from '@/utils/fetch';
import { buildUrlQuery } from '@/utils/urlParameter';

import { i18n } from '@/i18n';

import type { ApiResponse, ErrorMessageType, FetchResponse } from '@/types/apis';
import type { CountryLocalInfo } from '@/types/locales';
import type { LoginCheckData, PasswordNeedCheckData } from '@/types/myAccount';
import type { ServiceInfo } from '@/types/serviceInfo';
import type { TermsContent, TermsHistory, TermsRevisionRequest } from '@/types/terms';

/**
 * getTermsHistories
 * @description 전달된 약관 고유 ID에 해당하는 약관의 수정 내역을 반환합니다.
 * @param categoryId 약관 카테고리 고유 ID
 * @returns {FetchResponse<TermsHistory[]>}
 */
export async function getTermsHistories(categoryId: string): FetchResponse<TermsHistory[]> {
  const url = `${APIS_KEY.TERMS_HISTORY_API}/${categoryId}`;
  return await fetchData<TermsHistory[]>({ url });
}

/**
 * getTermsRevision
 * @description 전달된 이력 고유 ID에 해당하는 약관의 수정된 본문 내용을 반환합니다.
 * @param historyId 수정내역 고유 ID
 * @param countryCd 국가코드
 * @returns {FetchResponse<TermsContent>}
 */
export async function getTermsRevision(termsRevisionRequest: TermsRevisionRequest): FetchResponse<TermsContent> {
  const parameters = buildUrlQuery({ ...termsRevisionRequest });
  const url = `${APIS_KEY.TERMS_REVISION_API}?${parameters}`;
  return await fetchData<TermsContent>({ url });
}

/**
 * getCountryList
 * @description 지원 국가 목록을 조회한다.
 * @returns {FetchResponse<CountryLocalInfo>}
 */
export async function getCountryList(): FetchResponse<CountryLocalInfo> {
  const url = APIS_KEY.COUNTRY_LIST;
  const response = await fetchData<CountryLocalInfo>({ url });
  return response;
}

export async function getServiceInfo(): FetchResponse<ServiceInfo[]> {
  const { getClientName } = useCommonStore();
  const url = `${APIS_KEY.SERVICE_INFO}/${getClientName}/-1`;
  const response = await fetchData<ServiceInfo[]>({ url });
  return response;
}

/**
 * loginCheck
 * @description 로그인 여부 확인
 * @returns {FetchResponse<LoginCheckData>}
 */
export async function loginCheck(): FetchResponse<LoginCheckData> {
  const url = APIS_KEY.LOGIN_CHECK;
  return await fetchData<LoginCheckData>({ url });
}

/**
 * getPasswordNeedCheck
 * @description 카카오싱크 가입후 비밀번호 등록 여부 조회한다.
 * 카카오싱크 가입 후, 비밀번호 등록을 하지 않으면 /my-account/verify-password 를 타지 않는다.
 * @returns {FetchResponse<PasswordNeedCheckData>}
 */
export async function getPasswordNeedCheck(): FetchResponse<PasswordNeedCheckData> {
  const url = APIS_KEY.USERINFO_PASSWORD_NEED_CHECK;
  return await fetchData<PasswordNeedCheckData>({ url });
}

/**
 * getCommonError
 * @description api 공통 오류를 처리하기 위해 uri와 code 값을 입력받아 알맞은 오류 메세지 객체를 찾는다.
 * @param apiUri 호출 api uri
 * @param responseCode response data code 값
 * @returns {ErrorMessageType | undefined} 에러 메세지 객체 또는 undefined
 */
export function getCommonError(apiUri: string, responseCode: string): ErrorMessageType | undefined {
  return COMMON_ERROR_MESSAGE?.[apiUri]?.[responseCode];
}

export function getValidationError(error: AxiosError<ApiResponse<any>> | undefined): string {
  const apiUri = error?.config?.url?.split('?')[0] ?? '';
  const responseCode = error?.response?.data.code ?? '';
  const commonError = COMMON_ERROR_MESSAGE?.[apiUri]?.[responseCode];
  if (commonError && commonError.type === COMMON_ERROR_MESSAGE_TYPE.VALIDATION) {
    const errorMessage = commonError.message;
    return errorMessage ? i18n.global.t(errorMessage) : '';
  }
  return '';
}
