import { CowayId } from '@cw-account/account-frontend-sdk';

import useEnv from '@/hooks/useEnv';

export const setupCowayId = (): CowayId => {
  const { VITE_ENV } = useEnv();
  const isDev = ['local', 'development', 'staging'].includes(VITE_ENV) && window.Coway;
  const cowayId = isDev ? new window.Coway.CowayId() : new CowayId();

  return cowayId;
};
