<script setup lang="ts">
/**
 * ModalContent
 * @description 모달 컨텐츠 렌더링 컴포넌트
 */

import { MODAL_KEY } from '@/constants/modal';

import AlertDefault from '@/components/molecules/modal/alert/AlertDefault.vue';
import ConfirmDefault from '@/components/molecules/modal/confirm/ConfirmDefault.vue';
import PopupAskAuthenticateToSignUp from '@/components/molecules/modal/popup/PopupAskAuthenticateToSignUp.vue';
import PopupAskLogin from '@/components/molecules/modal/popup/PopupAskLogin.vue';
import PopupChangePassword from '@/components/molecules/modal/popup/PopupChangePassword.vue';
import PopupOtpAuth from '@/components/molecules/modal/popup/PopupOtpAuth.vue';
import PopupPersonalInfoTerms from '@/components/molecules/modal/popup/PopupPersonalInfoTerms.vue';
import PopupTerms from '@/components/molecules/modal/popup/PopupTerms.vue';
import Toast from '@/components/molecules/modal/toast/Toast.vue';

import type { ModalKey } from '@/types/modal';

interface Props {
  contentId: ModalKey;
  modalProps: any;
}

type ContentId =
  | typeof AlertDefault
  | typeof ConfirmDefault
  | typeof PopupTerms
  | typeof PopupAskLogin
  | typeof PopupAskAuthenticateToSignUp
  | typeof PopupOtpAuth
  | typeof Toast
  | typeof PopupPersonalInfoTerms
  | typeof PopupChangePassword;

const props = defineProps<Props>();
const contentId: ContentId = getComponent(props.contentId);

function getComponent(id: string) {
  switch (id) {
    case MODAL_KEY.ALERT:
      return AlertDefault;
    case MODAL_KEY.CONFIRM:
      return ConfirmDefault;
    case MODAL_KEY.TERMS:
      return PopupTerms;
    case MODAL_KEY.TOAST:
      return Toast;
    case MODAL_KEY.ASK_LOGIN:
      return PopupAskLogin;
    case MODAL_KEY.ASK_AUTHENTICATE_TO_SIGN_UP:
      return PopupAskAuthenticateToSignUp;
    case MODAL_KEY.OTP_AUTH:
      return PopupOtpAuth;
    case MODAL_KEY.CHANGE_PASSWORD:
      return PopupChangePassword;
    case MODAL_KEY.PERSONAL_INFO_TERMS:
      return PopupPersonalInfoTerms;
    default:
      throw new Error('존재하지 않는 모달 키 입니다.');
  }
}
</script>

<template>
  <component :is="contentId" v-bind="{ ...modalProps }" />
</template>
