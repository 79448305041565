import { createPinia } from 'pinia';
import { createApp } from 'vue';

import { sendGaEvent } from '@/utils/gtmUtils';
import { nativeEvent } from '@/utils/nativeEvent';
import { defineGlobalRules } from '@/utils/validateRules';

import App from '@/App.vue';
import { i18n } from '@/i18n';
import doItMock from '@/mock';
import { setupCowayId } from '@/plugins/cowayAccount';
import router from '@/router';

window.sendGaEvent = sendGaEvent;
window.nativeEvent = nativeEvent;

const pinia = createPinia();
const app = createApp(App);
defineGlobalRules();

import.meta.env.MODE === 'mock' && doItMock();

app.use(router);
app.use(pinia);
app.use(i18n);
window.addEventListener('load', () => {
  app.provide('$cowayId', setupCowayId());
  app.mount('#app');
});
