import { useSessionStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

import { wrongAccessError } from '@/hooks/useCommonError';

import type { LocaleInfo } from '@/types/locales';
import type { ServiceInfo } from '@/types/serviceInfo';
import type { ClientName } from '@/types/terms';

import type { RemovableRef } from '@vueuse/core';
interface InitialState {
  locale?: string;
  clientName?: ClientName;
  prevPage?: string;
  loginPage?: string;
  findPasswordRedirectUri: string | null;
  supportCountryList?: LocaleInfo[];
  loginOnly: boolean;
}

interface CommonStore {
  locale: RemovableRef<string>;
  clientName: RemovableRef<ClientName | null>;
  prevPage?: RemovableRef<string | null>;
  loginPage?: RemovableRef<string | null>;
  logoutUri?: RemovableRef<string | null>;
  loginPageUri?: RemovableRef<string | null>;
  findPasswordRedirectUri: RemovableRef<string | null>;
  supportCountryList: RemovableRef<LocaleInfo[]>;
  loginOnly: RemovableRef<boolean>;
  myAccountPrevPage: RemovableRef<string | null>;
  showAllLogout: RemovableRef<boolean>;
  loading: number;
  sessionTimeoutRedirectPath: RemovableRef<string | null>;
  sessionTimeoutRedirectParam: RemovableRef<Record<string, string> | null>;
}

const initialState: CommonStore = {
  locale: useSessionStorage('locale', ''),
  clientName: useSessionStorage('clientName', null),
  prevPage: useSessionStorage('prevPage', null),
  loginPage: useSessionStorage('loginPage', null),
  logoutUri: useSessionStorage('logoutUri', null),
  loginPageUri: useSessionStorage('loginPageUri', null),
  findPasswordRedirectUri: useSessionStorage('findPasswordRedirectUri', null),
  supportCountryList: useSessionStorage('supportCountryList', []),
  loginOnly: useSessionStorage('loginOnly', false),
  myAccountPrevPage: useSessionStorage('myAccountPrevPage', null),
  showAllLogout: useSessionStorage('showAllLogout', true),
  loading: 0,
  sessionTimeoutRedirectPath: useSessionStorage('sessionTimeoutRedirectPath', null),
  sessionTimeoutRedirectParam: useSessionStorage('sessionTimeoutRedirectParam', {}),
};

/**
 * useCommonStore
 * @description 공통으로 사용하는 값들을 저장하는 스토어
 */
const useCommonStore = defineStore('common', {
  state: (): CommonStore => {
    return initialState;
  },
  getters: {
    currentLocale(): string {
      return initialState.locale.value;
    },
    getClientName(): ClientName {
      if (!this.clientName) {
        wrongAccessError();
        throw new Error('clientName이 존재하지 않습니다.');
      }
      return this.clientName;
    },
    countryList(): LocaleInfo[] {
      return initialState.supportCountryList.value;
    },
    isLoading(): boolean {
      return this.loading > 0;
    },
    getShowAllLogout(): boolean {
      return this.showAllLogout;
    },
  },
  actions: {
    setInitialState(initialState: InitialState) {
      this.$state = { ...this.$state, ...initialState };
    },
    setLocale(locale: string) {
      this.$state.locale = locale;
    },
    setClientName(value: ClientName | null) {
      this.$state.clientName = value;
    },
    setSupportedCountryList(supportCountryList: LocaleInfo[]) {
      this.$state.supportCountryList = supportCountryList;
    },
    setServiceInfo(serviceInfoList: ServiceInfo[]) {
      serviceInfoList.map((serviceInfo) => {
        const { categoryName, value } = serviceInfo;
        switch (categoryName) {
          case 'login':
            this.$state.loginPage = value;
            break;
          case 'logout':
            this.$state.logoutUri = value;
            break;
          case 'prev-my-account':
            this.$state.myAccountPrevPage = value;
            break;
          case 'use-logout-all':
            this.$state.showAllLogout = value === 'true';
            break;
        }
      });
    },
    setLoginPageUri(loginPageUri: string) {
      this.$state.loginPageUri = loginPageUri;
    },
    setFindPasswordRedirectUri(findPasswordRedirectUri: string | null) {
      this.$state.findPasswordRedirectUri = findPasswordRedirectUri;
    },
    setPrevPage(prevPage: string | null) {
      this.$state.prevPage = prevPage;
    },
    setLogoutUri(logoutUri: string | null) {
      this.$state.logoutUri = logoutUri;
    },
    addLoading() {
      this.$state.loading = this.$state.loading + 1;
    },
    subLoading() {
      this.$state.loading = this.$state.loading - 1;
    },
    resetLoading() {
      this.$state.loading = 0;
    },
    setLoginOnly(loginOnly: boolean) {
      this.$state.loginOnly = loginOnly;
    },
    setSessionTimeoutRedirectUri(path: string | null, param: Record<string, string> | null) {
      this.$state.sessionTimeoutRedirectPath = path;
      this.$state.sessionTimeoutRedirectParam = { ...param };
    },
    resetLoginPage() {
      this.$state.loginPage = null;
    },
  },
});

export default useCommonStore;
