/**
 * buildUrlQuery
 * @description object 를 받아 string 형태의 url 파라미터를 생성한다
 * @params 파라미터로 받을 객체
 * @params options 빈 스트링을 반환되는 url 파라미터에 포함 여부
 * @return {string} string으로 변환된 url 파라미터
 */
function buildUrlQuery(params: { [key: string]: string }, options: { includeEmptyValue: boolean } = { includeEmptyValue: false }): string {
  return Object.entries(params)
    .filter((obj) => (options.includeEmptyValue ? obj : obj[1] !== ''))
    .map((obj) => obj.join('='))
    .join('&');
}
/**
 * urlQuery
 * @description object 를 받아 string 형태의 url을 넘겨준다.
 * @params {string} url
 * @params 파라미터로 받을 객체
 * @params options 빈 스트링을 반환되는 url 파라미터에 포함 여부
 * @return {string} string으로 변환된 url
 */
function urlQuery(url: string, params: { [key: string]: string }, options: { includeEmptyValue: boolean } = { includeEmptyValue: false }): string {
  const query = buildUrlQuery(params, options);
  return query.includes('=') ? `${url}?${query}` : `${url}`;
}

/**
 * getKeyFromQueryParam
 * @description url로부터 query param을 parsing한다.
 * @params {string=} parsing할 url. 입력하지 않으면, 현재 url을 기준으로 parsing한다.
 * @return {Record} key:value Record 형태의 object
 */
function getKeyFromQueryParam(url?: string): {
  [k: string]: string;
} {
  try {
    const replaceUrl = url?.startsWith('/') || url?.startsWith('?') ? window.location.origin + url : url;
    const targetUrl = new URL(replaceUrl || window.location.href);

    return Object.fromEntries(targetUrl.searchParams);
  } catch (e) {
    return {};
  }
}

export { buildUrlQuery, urlQuery, getKeyFromQueryParam };
