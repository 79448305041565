<script setup lang="ts">
/**
 * InputPasswordConfirm
 * @description 비밀번호, 비밀번호 확인 컴포넌트
 */
import { ref } from 'vue';

import { blockFormSubmit } from '@/hooks/useForm';

import InputAtom from '@/components/atoms/InputAtom/index.vue';
import GuideMessageArea from '@/components/molecules/GuideMessageArea.vue';

import type { GuideMessage } from '@/types/commons';
import type { HtmlEvent } from '@/types/dom';

interface PasswordKeys {
  password: string;
  passwordConfirm: string;
}

interface Props {
  passwordId: string;
  passwordConfirmId: string;
  label: string;
  values: PasswordKeys;
  placeholders?: string[];
  guideMessageClassName?: string;
  guideMessages?: GuideMessage[];
  errorMessages?: string[];
  onInput?: (e: HtmlEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent) => void;
  onKeyup?: (e: KeyboardEvent) => void;
}

const props = defineProps<Props>();
const passwordRef = ref<InstanceType<typeof InputAtom> | null>(null);

const handleInput = (e: Event) => {
  props.onInput?.(e as HtmlEvent<HTMLInputElement>);
};

const handleBlur = (e: FocusEvent) => {
  props.onBlur?.(e);
};

const handleKeyup = (e: KeyboardEvent) => {
  props.onKeyup?.(e);
};

const toggleFocus = () => {
  passwordRef.value?.toggleFocus();
};

defineExpose({
  toggleFocus,
});

blockFormSubmit();
</script>

<template>
  <div class="inp_text certf_form" :class="errorMessages?.[0] && 'error'">
    <div v-if="label" class="form_tit">{{ label }}</div>
    <form>
      <InputAtom
        :id="passwordId"
        ref="passwordRef"
        name="password"
        type="password"
        :value="values.password"
        autocomplete="off"
        :placeholder="placeholders?.[0] ?? ''"
        :on-input="handleInput"
        :on-blur="handleBlur"
        :on-keyup="handleKeyup" />
    </form>
  </div>
  <GuideMessageArea v-if="guideMessages" :guide-messages="guideMessages" :class-name="guideMessageClassName" />
  <p v-if="errorMessages?.[0]" :key="errorMessages[0]" class="member_error_msg">{{ errorMessages[0] }}</p>

  <div class="inp_text certf_form" :class="errorMessages?.[1] && 'error'">
    <form>
      <InputAtom
        :id="passwordConfirmId"
        name="passwordConfirm"
        type="password"
        autocomplete="off"
        :value="values.passwordConfirm"
        :placeholder="placeholders?.[1] ?? ''"
        :on-input="handleInput"
        :on-blur="handleBlur"
        :on-keyup="handleKeyup" />
    </form>
  </div>
  <p v-if="errorMessages?.[1]" :key="errorMessages[1]" class="member_error_msg">{{ errorMessages[1] }}</p>
</template>
