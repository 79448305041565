import type { NavigationGuardNext } from 'vue-router';

import { watch } from 'vue';

import { APIS_KEY } from '@/constants/apis';
import { sessionTimeoutError } from '@/hooks/useCommonError';
import useFetch from '@/hooks/useFetch';
import useNative from '@/hooks/useNative';
import useCommonStore from '@/stores/common';
import { loginCheck as fetchLoginCheck } from '@/utils/apis';
import { isIocare } from '@/utils/userAgent';

import type { UseFetch } from '@/types/apis';
import type { LoginCheckData } from '@/types/myAccount';

/**
 * useLoginCheck
 * @description 로그인 여부를 체크하여 sso/login 페이지로 이동하는 hook.
 * @param {string} path sso/login 페이지에서 로그인 성공 후 이동할 페이지의 path
 * @param {Record<string, string>} [query] sso/login 페이지에서 로그인 성공 후 이동할 페이지의 path 뒤에 붙여줄 query
 */
export default function useLoginCheck(
  path: string,
  query?: Record<string, string> | null,
): {
  loginCheck: (setResponse?: (res: UseFetch<LoginCheckData>) => void) => Promise<boolean>;
  isLoginRequiredError: () => boolean;
  logout: () => void;
  promptLogout: () => void;
  iocareLogout: () => void;
  validateLoginRequiredPage: (isLoginRequiredPage: boolean | undefined, next: NavigationGuardNext, promptLogout: () => void) => Promise<void>;
} {
  const originApi = import.meta.env.VITE_API_ORIGIN;
  const urlParams = new URLSearchParams({ ...query });

  /**
   * loginCheck
   * @description api 통신으로 로그인 체크 후 로그인 여부가 false 이면 promptLogout 을 호출
   */
  const loginCheck = (setResponse?: (res: UseFetch<LoginCheckData>) => void) => {
    return new Promise<boolean>((resolve, reject) => {
      const { isPending, isSuccess, data, error } = useFetch<LoginCheckData>({ url: APIS_KEY.LOGIN_CHECK, method: 'get' });

      watch([isPending, isSuccess, data, error], () => {
        setResponse?.({ isPending, isSuccess, data, error });
        if (isSuccess.value && data.value) {
          const { login } = data.value.data;
          if (!login) {
            promptLogout();
            resolve(false);
          }
          resolve(true);
        }

        if (error.value) {
          reject(error.value);
          console.warn('[Router] Could not check if a user is logged in.', error);
          return;
        }
      });
    });
  };

  /**
   * isLoginRequiredError
   * @description 파라미터에 error=login_required 가 있을 경우 로그아웃 처리
   * @returns {boolean} 파라미터 존재 여부 return
   */
  const isLoginRequiredError = () => {
    if (urlParams.has('error') && urlParams.get('error') === 'login_required') {
      const param = { ...query };
      delete param.error;
      const { setSessionTimeoutRedirectUri } = useCommonStore();
      setSessionTimeoutRedirectUri(path, param);
      sessionTimeoutError();
      return true;
    }
    return false;
  };

  /**
   * iocareLogout
   * @description iocare의 경우 native 함수를 호출하여 로그아웃 요청
   */
  const iocareLogout = () => {
    const { close } = useNative();
    close({ action: 'logout' });
  };

  /**
   * logout
   * @description 로그아웃 처리
   * @description iocare 의 경우 앱 닫기 처리
   * @description cowaymall 의 경우 sso/login?target_uri={path}{query} 로 이동
   */
  const logout = () => {
    if (isIocare()) {
      iocareLogout();
      return;
    }
    const { getClientName } = useCommonStore();

    const loginUri = `${originApi}/sso/login?target_uri=${originApi}${path}${
      [...new Set(urlParams.keys())].length > 0 ? `?${urlParams.toString()}${urlParams.has('client_name') ? '' : `&client_name=${getClientName}`}` : `?client_name=${getClientName}`
    }`;

    location.replace(loginUri);
    return;
  };

  /**
   * promptLogout
   * @description 세션 로그인 여부를 확인하는 요청으로 로그인이 되어 있지 않으면 b/e에서 error=login_required 를 추가하여 target_uri 로 리다이렉트
   */
  const promptLogout = () => {
    const { getClientName } = useCommonStore();
    location.href = `${originApi}/sso/login?prompt=none&target_uri=${originApi}${path}${
      [...new Set(urlParams.keys())].length > 0 ? `?${urlParams.toString()}${urlParams.has('client_name') ? '' : `&client_name=${getClientName}`}` : `?client_name=${getClientName}`
    }`;
    return;
  };

  const validateLoginRequiredPage = async (isLoginRequiredPage: boolean | undefined, next: NavigationGuardNext, promptLogout: () => void) => {
    if (isLoginRequiredPage) {
      const { data } = await fetchLoginCheck();
      const { data: loginCheckData } = data;
      if (loginCheckData.login) {
        next();
      } else {
        promptLogout();
      }
      return;
    }
    next();
  };

  return {
    loginCheck,
    isLoginRequiredError,
    logout,
    promptLogout,
    iocareLogout,
    validateLoginRequiredPage,
  };
}
