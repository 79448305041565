/**
 * validateDoubleWhiteSpace
 * @description 2회 연속 공백 검증
 * @param {string} value 검증되어야 할 인자
 * @returns {boolean} 정규식에 해당 시 false
 */
export function validateDoubleWhiteSpace(value: string): boolean {
  return !/(\s)+\1/.test(value);
}

/**
 * validateTrim
 * @description 문자열 양 옆에 공백 검증
 * @param {string} value 검증되어야 할 인자
 * @returns {boolean} 정규식에 해당 시 false
 */
export function validateTrim(value: string): boolean {
  return !(/^[\s].*/.test(value) || /.*[\s]$/.test(value));
}

/**
 * validateSpecialChar
 * @description 특수문자 검증
 * @param {string} value 검증되어야 할 인자
 * @returns {boolean} 정규식에 해당 시 false
 */
export function validateSpecialChar(value: string): boolean {
  // eslint-disable-next-line no-useless-escape
  return !/[{}\[\]\/)^+<>&\\=('"“”‘’]/.test(value);
}

/**
 * validateHundredLength
 * @description 문자열 길이 1~100 검증
 * @param {string} value 검증될 인자
 * @returns {boolean} 정규식에 해당 시 false
 */
export function validateHundredLength(value: string): boolean {
  return /^.{1,100}$/.test(value);
}

/**
 * validName
 * @description 이름 검증 로직
 * @param {string} value 이름 값
 * @returns {boolean} 검증된 결과 true 반환
 */
export function validName(value: string): boolean {
  if (!validateDoubleWhiteSpace(value)) {
    return false;
  }
  if (!validateTrim(value)) {
    return false;
  }
  if (!validateSpecialChar(value)) {
    return false;
  }
  return validateHundredLength(value);
}

/**
 * min
 * @description 최솟값 검증
 * @param {string} value 문자열
 * @param {string} args 최소 길이 비교 구분자
 * @returns 검증된 결과 true 반환
 */
export function min(value: string, args: number): boolean {
  return value.length >= args;
}

/**
 * strongPassword
 * @description 2종류 이상의 문자를 사용했는지 검증
 * @param {string} value 문자열
 * @returns 검증된 결과 true 반환
 */
export function strongPassword(value: string): boolean {
  const charTypeFilters = [/[a-z]+/, /[A-Z]+/, /\d+/, /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+/];
  let passwordCharTypeCounter = 0;
  for (const charTypeFilter of charTypeFilters) {
    if (charTypeFilter.test(value)) passwordCharTypeCounter++;
    if (passwordCharTypeCounter > 1) break;
  }
  if (passwordCharTypeCounter < 2) return false;
  return true;
}

/**
 * validPassword
 * @description 영문, 숫자, 일부 특수문자를 사용했는지 검증
 * @param {string} value 문자열
 * @returns 검증된 결과 true 반환
 */
export function validPassword(value: string): boolean {
  if (!value) {
    return false;
  }
  return !/[^[A-Za-z0-9!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]+/.test(value);
}

/**
 * repetitionLimit
 * @description 연속된 문자열이 있는지 검증
 * @param {string} value 문자열
 * @param {string[]} args 검증할 연속된 문자열 횟수
 * @returns 검증된 결과 true 반환
 */
export function repetitionLimit(value: string, args: string[]): boolean {
  const pattern = new RegExp(`(.)${'\\1'.repeat(Number(args[0]) - 1)}`);
  return !pattern.test(value);
}

/**
 * email
 * @description 이메일 검증
 * @param {string} value 이메일 데이터
 * @returns 검증된 결과 true 반환
 */
export function email(value: string): boolean {
  return /^[0-9a-zA-Z.!#$%&'*+-/=?^_{|}~]+@[0-9a-zA-Z-]+(\.[0-9a-zA-Z]+)+$/.test(value);
}

/**
 * phoneNumber
 * @description 휴대폰번호 검증
 * @param {string} value 1~20자 이내의 숫자만 입력되었는지 확인
 * @returns 검증된 결과 true 반환
 */
export function phoneNumber(value: string): boolean {
  return /^[0-9]{1,20}$/.test(value);
}

/**
 * emailOrPhoneNumber
 * @description 이메일 또는 휴대폰번호 검증, 이메일 먼저 검증
 * @param {string} value 이메일 또는 휴대폰번호 문자열
 * @returns 검증된 결과 true 반환
 */
export function emailOrPhoneNumber(value: string): boolean {
  return email(value) || phoneNumber(value);
}

/**
 * birthDate
 * @description 생년월일 검증
 * @param {string} value 길이 8인 문자열로 된 생년월일
 * @returns 검증된 결과 true 반환
 */
export function birthDate(value: string): boolean {
  return /^[0-9]{8}$/.test(value) && Boolean(Date.parse(`${value.substring(0, 4)}-${value.substring(4, 6)}-${value.substring(6, 8)}`));
}

/**
 * validateNumberOnly
 * @description 숫자 검증
 * 숫자만 존재할 때, true 반환
 */
export function validateNumberOnly(value: string): boolean {
  return !/[^0-9.]/g.test(value);
}

/**
 * validateWhitespace
 * @description 공백 검증
 * 공백이 하나라도 포함된 문자가 오면 false 반환
 */
export function validateWhitespace(value: string): boolean {
  return /\s/g.test(value);
}
