type UseReturnType = ReturnType<() => ImportMetaEnv>;

/**
 * useEnv
 * @description vite 환경변수 가져오는 함수
 * @return {ImportMetaEnv} vite 환경변수
 */
export default function useEnv(): UseReturnType {
  return import.meta.env;
}
