<script setup lang="ts">
/**
 * ConfirmContent
 * @description 컨펌 컨텐츠 컴포넌트
 */
import { uuid } from 'vue-uuid';

import { MODAL_KEY } from '@/constants/modal';
import { useModalStore } from '@/stores/modal';

import ButtonAtom from '@/components/atoms/ButtonAtom/index.vue';

interface Props {
  title?: string;
  message: string;
  content?: string[];
  onClickConfirm?: () => void;
  confirmButtonLabel?: string;
  closeButtonLabel?: string;
  isCenter?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  title: undefined,
  onClickConfirm: undefined,
  confirmButtonLabel: undefined,
  closeButtonLabel: undefined,
  content: undefined,
  isCenter: false,
});

const { closeModal } = useModalStore();

const handleClickClose = () => {
  closeModal(MODAL_KEY.CONFIRM);
};

const handleClickConfirm = () => {
  props?.onClickConfirm?.();
  handleClickClose();
};

const uuidKey = uuid.v4();
</script>

<template>
  <div class="layCon">
    <div class="title_wrap mt0">
      <h3 v-if="title" class="h3_tit">{{ title }}</h3>
      <p class="sub_tit">{{ message }}</p>
    </div>

    <div v-if="content && content.length !== 0" class="id_box">
      <p v-for="(value, idx) of content" :key="uuidKey + idx" class="user_id">{{ value }}</p>
    </div>

    <div :class="isCenter ? 'layBtn' : 'layBtn right'">
      <ButtonAtom size="sizeS" color="btnWhite" :label="closeButtonLabel ?? $t('general.cancel')" :on-click="handleClickClose" :on-keyup="handleClickClose" />
      <ButtonAtom size="sizeS" color="btnBlack" :label="confirmButtonLabel ?? $t('general.confirm')" :on-keyup="handleClickConfirm" :on-click="handleClickConfirm" />
    </div>
  </div>
</template>
