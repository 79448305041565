import { ref, watch, type Ref } from 'vue';

import { APIS_KEY } from '@/constants/apis';
import { MODAL_KEY } from '@/constants/modal';
import { MyAccountEdit } from '@/domains/myAccountEdit';
import useFetch from '@/hooks/useFetch';
import useCommonStore from '@/stores/common';
import { useModalStore } from '@/stores/modal';
import useOtpStore from '@/stores/otp';
import useSignUpStore from '@/stores/signUp';

import { i18n } from '@/i18n';

import type { UseFetch } from '@/types/apis';
import type { CwidData } from '@/types/myAccount';
import type { PopupOtpAuth, PopupOtpMethod } from '@/types/otp';

export default class OtpEmailHandle {
  response: Ref<UseFetch<CwidData> | undefined>;
  method: PopupOtpMethod | null;

  constructor() {
    this.response = ref<UseFetch<CwidData> | undefined>();
    this.method = null;
  }

  setMethod(value: PopupOtpMethod | null) {
    this.method = value;
  }

  changeEmail(email: string, subValidKey: string) {
    const { isPending, isSuccess, data, error } = useFetch<CwidData>({
      url: `${APIS_KEY.USERINFO_EMAIL}`,
      method: 'patch',
      requestData: { email, subValidKey },
      isPopup: true,
    });

    watch(isSuccess, (currIsSuccess) => {
      this.response.value = {
        isPending,
        isSuccess,
        data,
        error,
      };

      if (currIsSuccess) {
        const myAccountEdit = new MyAccountEdit();
        const { getClientName } = useCommonStore();
        myAccountEdit.getAccountInfo(getClientName);
        this.presentEmailAlert();
      }
    });
  }

  handleEmailOtpPopupIsSuccess(popupOtpAuthType: PopupOtpAuth, subValidKey: string) {
    const { purpose } = popupOtpAuthType;
    const { setAuthenticatedEmail } = useSignUpStore();
    const { value: otpValue } = useOtpStore();

    switch (purpose) {
      case 'MY_ACCOUNT':
        window.sendGaEvent('myAccountEmailOtpSuccess');
        this.setMethod(popupOtpAuthType.method);
        this.changeEmail(otpValue, subValidKey);
        break;
      case 'SIGN_UP':
        window.sendGaEvent('signUpAskInfoEmailOtpSuccess');
        setAuthenticatedEmail();
        this.setMethod('REGISTER');
        this.presentEmailAlert();
        break;
      default:
        throw new Error('지원하지 않는 타입입니다.');
    }
  }

  presentEmailAlert() {
    const { openModal, closeModalAll } = useModalStore();
    closeModalAll();
    openModal(MODAL_KEY.ALERT, { message: i18n.global.t(this.emailAlertMessage) }, false);
  }

  get emailAlertMessage(): string {
    if (!this.method) return '';
    return this.method === 'REGISTER' ? 'alert.message.registrationEmail' : 'alert.message.changeEmail';
  }
}
