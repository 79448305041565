import { dataShouldBeString } from '@/utils/typeGuard';

import type { ActionType, PostMessageCommand } from '@/types/loginTerms';

/**
 * postMessage
 * @description 지정된 액션을 부모창에 postMessage 전송한다.
 */
export function postMessage(action: PostMessageCommand, url: string) {
  const command = { command: action };
  window.opener.postMessage(JSON.stringify(command), url);
}

/**
 * addMessageListener
 * @description onmessage 유틸함수
 * @param reducer 리듀서 함수를 직접 넘겨주어 핸들링
 * @param url 오리진 url이 같을때 동작
 */
export function addMessageListener<T>(reducer: (action: ActionType) => void, url: string) {
  window.onmessage = (event: MessageEvent<T>) => {
    const { origin, data } = event;
    if (origin === url && dataShouldBeString(data)) {
      reducer(JSON.parse(data));
    }
  };
}
