import { ref, watch, type Ref } from 'vue';

import { APIS_KEY } from '@/constants/apis';
import useFetch from '@/hooks/useFetch';

import type { UseFetch } from '@/types/apis';
import type { PersonalInfoUsageStatus } from '@/types/personalInfoTerms';

const initialPersonalData: PersonalInfoUsageStatus = {
  signupAt: '',
  regularLevelAt: null,
  existPurchaseHistory: false,
};

export class PersonalInfoTerms {
  personalInfoTermsResponse: Ref<UseFetch<PersonalInfoUsageStatus> | undefined>;
  personalInfoTermsStatus: Ref<PersonalInfoUsageStatus>;
  constructor() {
    this.personalInfoTermsResponse = ref<UseFetch<PersonalInfoUsageStatus> | undefined>();
    this.personalInfoTermsStatus = ref<PersonalInfoUsageStatus>(initialPersonalData);
  }

  getPersonalInfoTerms() {
    const { isPending, isSuccess, data, error } = useFetch<PersonalInfoUsageStatus>({ url: APIS_KEY.PERSONAL_INFO_TERMS, method: 'get' });
    watch([isPending, isSuccess, data, error], () => {
      this.personalInfoTermsResponse.value = {
        isPending,
        isSuccess,
        data,
        error,
      };

      if (isSuccess.value && data.value?.data) {
        this.personalInfoTermsStatus.value = data.value.data;
      }
    });
  }
}
