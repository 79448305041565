import { AUTHENTICATE_TYPE } from '@/constants/authentication';
import { MODAL_KEY } from '@/constants/modal';
import { Authentication } from '@/domains/authentication';
import { MyAccountEdit } from '@/domains/myAccountEdit';
import useCommonStore from '@/stores/common';
import { useModalStore } from '@/stores/modal';

import { i18n } from '@/i18n';

import type { AuthPurpose } from '@/types/authentication';

export default class AuthenticationMyAccount extends Authentication {
  purpose: AuthPurpose;

  constructor(purpose: AuthPurpose) {
    super();
    this.purpose = purpose;
  }

  async authenticateMyAccount() {
    const { openModal } = useModalStore();
    try {
      const res = await this.authenticateUser(this.purpose);
      const { ageGroup, safeKeyStatus } = res.data;
      if (ageGroup === 'YOUTH') {
        this.refetchAccountInfo();
        openModal(MODAL_KEY.ALERT, { message: i18n.global.t('alert.message.underNineteen') });
        return;
      } else if (ageGroup === 'CHILDREN') {
        openModal(MODAL_KEY.ALERT, { message: i18n.global.t('alert.message.underFourteen') });
        return;
      }

      // 본인인증 성공
      if (this.isSafeKeyError(safeKeyStatus)) {
        // 회원정보가 업데이트 되었으나, 세이프키 에러 처리가 필요한 경우.
        this.handleSafeKeyError(safeKeyStatus, this.purpose);
        return;
      }

      // 세이프키까지 정상 발급된 경우.
      if (this.purpose === AUTHENTICATE_TYPE.PROMOTE_USER) {
        this.handlePromoteUserInSuccess();
      } else {
        this.handleChangeUserNumberInSuccess();
      }
    } catch (e: any) {
      this.handleAuthenticationError(e);
    }
  }

  handlePromoteUserInSuccess() {
    const { openModal } = useModalStore();
    this.refetchAccountInfo();
    openModal(MODAL_KEY.ALERT, {
      message: i18n.global.t('alert.message.registerMemberInfo'),
      buttonLabel: i18n.global.t('general.confirm'),
    });
  }

  handleChangeUserNumberInSuccess() {
    const { openModal } = useModalStore();
    this.refetchAccountInfo();
    openModal(MODAL_KEY.ALERT, {
      message: i18n.global.t('alert.message.changeMemberInfo'),
      buttonLabel: i18n.global.t('general.confirm'),
    });
  }

  refetchAccountInfo() {
    const { getClientName } = useCommonStore();
    new MyAccountEdit().getAccountInfo(getClientName);
  }
}
