<script setup lang="ts">
/**
 * PopupAskAuthenticateToSignUp
 * @description 본인인증 유도 팝업 컴포넌트
 */

import { AUTHENTICATE_TYPE } from '@/constants/authentication';
import { MODAL_KEY } from '@/constants/modal';
import { AuthenticationSignUpFindId } from '@/domains/authenticationSignUpFindId';
import { useModalStore } from '@/stores/modal';

import ButtonAtom from '@/components/atoms/ButtonAtom/index.vue';
import PopupLayout from '@/components/molecules/layout/PopupLayout.vue';

const { closeModalAll } = useModalStore();

interface Props {
  emailOrPhoneNumber: string;
}

const props = defineProps<Props>();

const handleAuthenticateClick = () => {
  closeModalAll();
  new AuthenticationSignUpFindId(AUTHENTICATE_TYPE.FIND_ID).authenticateSignUpFindId(props.emailOrPhoneNumber);
};
</script>

<template>
  <PopupLayout tabindex="0" :content-id="MODAL_KEY.ASK_AUTHENTICATE_TO_SIGN_UP" header-right lay-inner>
    <template #section>
      <div class="layCon">
        <div class="title_wrap mt0">
          <h2 class="h2_tit">{{ $t('title.nextStepAfterSelfCert') }}</h2>
        </div>

        <ButtonAtom wrap-mode wrap-class="layBtn" size="sizeL" color="btnBlack" auto tabindex="0" :label="$t('general.selfCert')" :on-click="handleAuthenticateClick" />
      </div>
    </template>
  </PopupLayout>
</template>
