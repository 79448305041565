<script setup lang="ts">
/**
 * AlertContent
 * @description 얼러트 컨텐츠 컴포넌트
 */
import { uuid } from 'vue-uuid';

import { MODAL_KEY } from '@/constants/modal';
import { useModalStore } from '@/stores/modal';

import ButtonAtom from '@/components/atoms/ButtonAtom/index.vue';

interface Props {
  title?: string;
  message: string;
  content?: string[];
  buttonLabel?: string;
  onClick?: () => void;
  isCenter?: boolean;
}

const props = withDefaults(defineProps<Props>(), { title: undefined, content: undefined, buttonLabel: undefined, onClick: undefined, isCenter: false });
const { closeModal } = useModalStore();
const handleClick = () => {
  props?.onClick?.();
  closeModal(MODAL_KEY.ALERT);
};

const handleKeyup = () => {
  props?.onClick?.();
  closeModal(MODAL_KEY.ALERT);
};

const uuidKey = uuid.v4();
</script>

<template>
  <div class="layCon">
    <div class="title_wrap mt0">
      <h2 v-if="title" class="h2_tit">
        {{ title }}
      </h2>

      <p class="sub_tit" v-html="message"></p>
    </div>

    <div v-if="content && content.length !== 0" class="id_box">
      <p v-for="(value, idx) of content" :key="uuidKey + idx" class="user_id">{{ value }}</p>
    </div>

    <ButtonAtom
      size="sizeM"
      color="btnBlack"
      wrap-mode
      :wrap-class="isCenter ? 'layBtn' : 'layBtn right'"
      :on-click="handleClick"
      :on-keyup="handleKeyup"
      :label="buttonLabel ?? $t('general.confirm')" />
  </div>
</template>
