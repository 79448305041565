<script setup lang="ts">
/**
 * InputAtom
 * @description 인풋 컴포넌트
 */

import { useFocus } from '@vueuse/core';
import { ref, onMounted } from 'vue';

import { validateNumberOnly, validateWhitespace } from '@/utils/validate';

import type { InputType } from '@/types/commons';
import type { HtmlEvent } from '@/types/dom';
interface Props {
  id: string;
  name?: string;
  type?: InputType;
  value: string;
  placeholder?: string;
  disabled?: boolean;
  autocomplete?: string;
  onInput?: (e: HtmlEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent) => void;
  onKeyup?: (e: KeyboardEvent) => void;
}

const props = withDefaults(defineProps<Props>(), {
  name: undefined,
  type: 'text',
  placeholder: undefined,
  autocomplete: undefined,
  disabled: false,
  onInput: undefined,
  onBlur: undefined,
  onKeyup: undefined,
});
const focusOn = ref<boolean>(false);
const input = ref<HTMLInputElement | null>(null);
const { focused: inputFocused } = useFocus(input, { initialValue: false });

const handleInput = (e: Event) => {
  props.onInput?.(e as unknown as HtmlEvent<HTMLInputElement>);
};

const handleBlur = (e: FocusEvent) => {
  focusOn.value = false;
  props.onBlur?.(e);
};

const handleKeyup = (e: KeyboardEvent) => {
  props.onKeyup?.(e);
};

const handleTextinput = (e: Event) => {
  const { data } = e as unknown as HtmlEvent<HTMLInputElement>;
  if (props.type === 'text') return true;
  if (props.type === 'number') {
    !validateNumberOnly(data) && e.preventDefault();
    return true;
  }
  if (validateWhitespace(data)) {
    e.preventDefault();
    return false;
  }
  return true;
};

const handleFocus = () => {
  focusOn.value = true;
};

const toggleFocus = () => {
  inputFocused.value = true;
};

onMounted(() => {
  input.value?.addEventListener('textInput', (e: Event) => {
    handleTextinput(e as unknown as KeyboardEvent);
  });
});

defineExpose({
  toggleFocus,
});
</script>
<template>
  <div class="u-form-box" :class="{ disabled }">
    <input
      :id="id"
      ref="input"
      :name="name"
      :type="type"
      :value="value"
      :class="{ focus: focusOn, ellipsis: disabled }"
      :placeholder="placeholder"
      :disabled="disabled"
      :autocomplete="autocomplete"
      @input="handleInput"
      @blur="handleBlur"
      @focus="handleFocus"
      @keyup.enter="handleKeyup" />
  </div>
</template>
