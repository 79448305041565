<script setup lang="ts">
/**
 * InputLabelButton
 * @description 라벨과 버튼이 있는 인풋 컴포넌트
 */

import ButtonAtom from '@/components/atoms/ButtonAtom/index.vue';
import InputAtom from '@/components/atoms/InputAtom/index.vue';
import GuideMessageArea from '@/components/molecules/GuideMessageArea.vue';

import type { GuideMessage, InputType } from '@/types/commons';
import type { HtmlEvent } from '@/types/dom';

interface Props {
  label: string;
  id: string;
  name?: string;
  inputType?: InputType;
  buttonType?: 'button' | 'submit';
  value: string;
  placeholder?: string;
  inputDisabled?: boolean;
  buttonDisabled?: boolean;
  autocomplete?: string;
  buttonLabel: string;
  guideMessageClassName?: string;
  guideMessages?: GuideMessage[];
  errorMessage?: string;
  onInput?: (e: HtmlEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent) => void;
  onKeyup?: (e?: KeyboardEvent) => void;
  onClick?: (e?: MouseEvent) => void;
}

const props = withDefaults(defineProps<Props>(), {
  name: undefined,
  inputType: 'text',
  buttonType: 'button',
  placeholder: undefined,
  autocomplete: undefined,
  guideMessageClassName: undefined,
  guideMessages: undefined,
  errorMessage: undefined,
  onInput: undefined,
  onBlur: undefined,
  onKeyup: undefined,
  onClick: undefined,
});

const handleInput = (e: Event) => {
  props.onInput?.(e as HtmlEvent<HTMLInputElement>);
};

const handleBlur = (e: FocusEvent) => {
  props.onBlur?.(e);
};

const handleKeyup = (e?: KeyboardEvent) => {
  props.onKeyup?.(e);
};

const handleClick = (e?: MouseEvent) => {
  props.onClick?.(e);
};
</script>

<template>
  <div class="inp_text button certf_form" :class="{ disabled: inputDisabled }">
    <div v-if="label" class="form_tit">{{ label }}</div>
    <InputAtom
      :id="id"
      :name="name"
      :type="inputType"
      :value="value"
      :placeholder="placeholder"
      :disabled="inputDisabled"
      :autocomplete="autocomplete"
      :on-input="handleInput"
      :on-blur="handleBlur"
      :on-keyup="handleKeyup" />
    <ButtonAtom :type="buttonType" :label="buttonLabel" size="sizeL" color="btnWhite" :disabled="buttonDisabled" :on-click="handleClick" :on-keyup="handleKeyup" wrap-mode />
  </div>
  <GuideMessageArea v-if="guideMessages" :guide-messages="guideMessages" :class-name="guideMessageClassName" />
  <p v-if="errorMessage" class="member_error_msg">{{ errorMessage }}</p>
</template>
