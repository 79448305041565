<script setup lang="ts">
/**
 * OtpVerify
 * @description 이메일 또는 핸드폰 점유인증 컴포넌트
 */
import { storeToRefs } from 'pinia';
import { watchEffect, onMounted, computed, watch } from 'vue';

import Otp from '@/domains/otp';
import useInputField from '@/hooks/useInputField';
import useCommonStore from '@/stores/common';
import useOtpStore from '@/stores/otp';

import CountrySelectAtom from '@/components/atoms/CountrySelectAtom/index.vue';
import InputLabelButton from '@/components/molecules/input/InputLabelButton.vue';
import { i18n } from '@/i18n';

import type { InputType } from '@/types/commons';
import type { HtmlEvent } from '@/types/dom';
import type { LocaleInfo } from '@/types/locales';
import type { OtpType, PurposeType, ValidateResponse } from '@/types/otp';

interface Props {
  onClickNext: (data?: ValidateResponse | null) => void;
  onEmptyData?: () => void;
  purpose?: PurposeType;
  type: OtpType;
  inputType: InputType;
  value?: string;
  inputDisabled?: boolean;
  title: string;
  titleClass?: string;
  checkDuplicate?: boolean;
  localeInfo?: LocaleInfo;
  sendOnMounted?: boolean;
  countryCd?: string;
  countryCallingCd?: string;
  saveOtpResponse?: boolean;
}

interface UserInfoProps {
  id: OtpType;
  name: OtpType;
  type: 'number' | 'email';
  inputType: InputType;
}
interface UserInfo {
  phoneNumber: UserInfoProps;
  email: UserInfoProps;
}
const userInfoProps: UserInfo = {
  phoneNumber: {
    id: 'phoneNumber',
    name: 'phoneNumber',
    type: 'number',
    inputType: 'number',
  },
  email: {
    id: 'email',
    name: 'email',
    type: 'email',
    inputType: 'id',
  },
};

const props = withDefaults(defineProps<Props>(), {
  onClickNext: undefined,
  onEmptyData: undefined,
  purpose: undefined,
  value: '',
  inputDisabled: undefined,
  localeInfo: undefined,
  titleClass: '',
  countryCd: undefined,
  countryCallingCd: undefined,
  checkDuplicate: false,
  saveOtpResponse: true,
});
const otp = new Otp();
const { setOtpValue } = useOtpStore();
const { otpValidationResponse } = storeToRefs(useOtpStore());

const fieldName = props.type === 'email' ? 'email' : 'phoneNumber';
const rules = props.type === 'email' ? 'required:을|email' : 'required:를|phoneNumber';
const { value, setValue, errorMessage, meta, handleChangeInputField } = useInputField(fieldName, rules, '');

const { countryList } = storeToRefs(useCommonStore());

const inputButtonDisabled = computed<boolean>(
  () => otp.otpData.value?.isPending || !meta.valid || !value.value || !!otp.inputErrorMessage.value || !!otp.checkEmailResponse.value?.data.value?.data.exist,
);

const initData = (value: string, type: OtpType) => {
  setValue(value);
  otp.value.value = value;
  otp.type.value = type;
};

watchEffect(() => {
  const { type, value, inputDisabled, onEmptyData } = props;
  if (!type || (inputDisabled && !value)) {
    onEmptyData?.();
    return;
  }
  initData(value, type);
});

const handleInput = (e: HtmlEvent<HTMLInputElement>) => {
  handleChangeInputField(e);
  otp.setEmail(e.target.value);
  otp.inputErrorMessage.value = undefined;
};

const handleKeyupEnter = () => {
  if (inputButtonDisabled.value) {
    return;
  }
  handleClick();
};

const handleClick = () => {
  if (props.checkDuplicate && props.type === 'email') {
    otp.checkEmailAndSendOtp(props.purpose, props.countryCd);
  } else if (props.checkDuplicate && props.type === 'phoneNumber') {
    otp.checkPhoneNumberAndSendOtp(props.countryCd, props.countryCallingCd);
  } else {
    otp.sendOtp(props.purpose, props.countryCd, props.countryCallingCd);
  }
  otp.certNumber.value = '';
};

const handleInputCertNumber = (e: HtmlEvent<HTMLInputElement>) => {
  otp.certNumber.value = e.target.value;
  otp.serverErrorMessage.value = undefined;
};

const handleClickCertNumber = () => {
  props.purpose && otp.validateCertNumber(props.saveOtpResponse, props.purpose, props.countryCallingCd);
};

const handleBlurCertNumber = () => {
  if (otp.isSend.value) {
    otp.errorMessage.value = otp.certNumber.value ? undefined : i18n.global.t('text.enterCertNumber');
  }
};

const verificationButtonDisabled = computed(() => {
  return otp.validateData.value?.isPending || !otp.isSend.value || otp.isOtpSuccess.value || !otp.remainTime.value || otp.certNumber.value.length !== 6;
});

onMounted(() => {
  props.sendOnMounted && handleClick();
});

watch(otp.isOtpSuccess, (cur) => {
  if (cur) {
    setOtpValue(value.value);
    props.onClickNext(otpValidationResponse.value);
  }
});
</script>

<template>
  <div class="title_wrap" :class="titleClass">
    <h2 class="h2_tit">{{ title }}</h2>
  </div>

  <CountrySelectAtom v-if="localeInfo" :selected="localeInfo" :options="countryList" disabled />

  <InputLabelButton
    :id="userInfoProps[otp.type.value].id"
    :name="userInfoProps[otp.type.value].name"
    :input-type="userInfoProps[otp.type.value].inputType"
    :placeholder="userInfoProps[otp.type.value].type === 'number' ? $t('placeholder.enterPhoneNumber') : $t('placeholder.enterEmail')"
    :button-label="otp.sendCount.value > 0 ? $t('text.resend') : $t('text.send')"
    :input-disabled="inputDisabled"
    :buttonDisabled="inputButtonDisabled"
    :label="userInfoProps[otp.type.value].type === 'number' ? $t('general.phoneNumber') : $t('general.email')"
    :value="value"
    :error-message="otp.inputErrorMessage.value || errorMessage"
    :on-input="handleInput"
    :on-click="handleClick"
    :on-keyup="handleKeyupEnter" />
  <InputLabelButton
    id="verificationCode"
    name="verificationCode"
    input-type="number"
    :placeholder="$t('placeholder.certNumberRule')"
    :button-label="$t('text.cert')"
    :label="$t('label.certNumber')"
    :value="otp.certNumber.value"
    :input-disabled="otp.validateData.value?.isPending || !otp.isSend.value || otp.isOtpSuccess.value"
    :button-disabled="verificationButtonDisabled"
    :on-input="handleInputCertNumber"
    :on-click="handleClickCertNumber"
    :on-keyup="handleClickCertNumber"
    :on-blur="handleBlurCertNumber" />
  <p v-if="otp.remainTime.value !== undefined" class="certf_time" role="timer">
    {{ $t('text.remainTime') }}
    <span>{{ otp.remainTime.value }}</span>
  </p>
  <p v-if="otp.errorMessage.value || otp.serverErrorMessage.value" class="member_error_msg">
    {{ otp.errorMessage.value || otp.serverErrorMessage.value }}
  </p>
</template>
