import type { AxiosError } from 'axios';

import { isAxiosError } from 'axios';
import { ref, shallowRef, watchEffect } from 'vue';

import useEnv from '@/hooks/useEnv';
import { fetchData } from '@/utils/fetch';

import type { ApiResponse, FetchData, UseFetch } from '@/types/apis';

/**
 * useFetch
 * @description api 호출 전후로 로딩과 데이터, error 를 제어하기 위한 hook
 * @params { url, method, requestData } : api를 호출할 url, method, request params
 * @return { UseFetch<T> }
 */
export default function useFetch<T>(props: FetchData, baseUrl: string = useEnv().VITE_API_URL): UseFetch<T> {
  const { url, method, requestData, headers, config, isPopup } = props;

  const isPending = ref<boolean>(true);
  const isSuccess = ref<boolean>(false);
  const data = shallowRef<ApiResponse<T> | null>(null);
  const error = ref<AxiosError<ApiResponse<any>> | null>(null);

  watchEffect(async () => {
    isPending.value = true;
    isSuccess.value = false;
    data.value = null;
    error.value = null;

    try {
      const res = await fetchData<T>({ url, method, requestData, headers, config, isPopup }, baseUrl);
      data.value = res.data;
      isSuccess.value = true;
    } catch (err: unknown | AxiosError) {
      if (isAxiosError(err)) {
        error.value = err;
      }
    } finally {
      isPending.value = false;
    }
  });

  return {
    data,
    error,
    isPending,
    isSuccess,
  };
}
