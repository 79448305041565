<script setup lang="ts">
import { computed } from 'vue';

import { EMAIL_AUTH_TYPE } from '@/constants/authentication';
import { MODAL_KEY } from '@/constants/modal';
import { OTP_TYPE } from '@/constants/otp';
import OtpEmailHandle from '@/domains/otpEmailHandle';
import OtpPhoneHandle from '@/domains/otpPhoneHandle';

import PopupLayout from '@/components/molecules/layout/PopupLayout.vue';
import OtpVerify from '@/components/organism/otp/OtpVerify.vue';

import type { LocaleInfo } from '@/types/locales';
import type { PopupOtpAuth, PurposeType, ValidateResponse } from '@/types/otp';

/**
 * PopupOtpAuth
 * @description 휴대폰, 이메일 otp 인증 팝업
 */

interface PopupPhoneNumberWithOtpType {
  phoneNumber: string;
  otpType: 'phoneNumber';
  locale?: LocaleInfo;
  inputType: 'number';
}
interface PopupEmailWithOtpType {
  email: string;
  otpType: 'email';
  inputType: 'id';
}

interface Props {
  otpState: PopupPhoneNumberWithOtpType | PopupEmailWithOtpType;
  type: PopupOtpAuth;
  inputDisabled?: boolean;
  countryCd: string;
  countryCallingCd?: string;
  checkDuplicate?: boolean;
  saveOtpResponse?: boolean;
}

const props = withDefaults(defineProps<Props>(), { countryCallingCd: undefined, saveOtpResponse: true });

const purpose = computed<PurposeType>(() => {
  switch (props.type.purpose) {
    case EMAIL_AUTH_TYPE.SIGN_UP: {
      return 'signUp';
    }
    case EMAIL_AUTH_TYPE.MY_ACCOUNT: {
      return 'myAccount';
    }
    default: {
      return 'findPassword';
    }
  }
});

const sendOnMountedOtpVerify = computed<boolean>(() => !!props.inputDisabled);

const otpVerifyValue = computed<string>(() => {
  return props.otpState.otpType === OTP_TYPE.EMAIL ? props.otpState.email : props.otpState.phoneNumber;
});

const phoneNumberLocale = computed<LocaleInfo | undefined>(() => {
  return props.otpState.otpType === OTP_TYPE.PHONE_NUMBER ? props.otpState?.locale : undefined;
});

const handleClickNext = (otpValidationResponse?: ValidateResponse | null) => {
  switch (props.otpState.otpType) {
    case OTP_TYPE.PHONE_NUMBER:
      new OtpPhoneHandle().handlePhoneOtpPopupIsSuccess(props.type);
      break;
    case OTP_TYPE.EMAIL:
      if (!otpValidationResponse?.subsequentValidationData) {
        throw new Error('otp 인증에 실패했습니다.');
      }
      new OtpEmailHandle().handleEmailOtpPopupIsSuccess(props.type, otpValidationResponse.subsequentValidationData);
      break;

    default:
      throw new Error('지원하지 않는 타입입니다.');
  }
};
</script>

<template>
  <PopupLayout tabindex="0" lay-inner header-right :content-id="MODAL_KEY.OTP_AUTH">
    <template #section>
      <div class="layCon">
        <OtpVerify
          :purpose="purpose"
          :localeInfo="phoneNumberLocale"
          :type="otpState.otpType"
          :input-type="otpState.inputType"
          :value="otpVerifyValue"
          :title="$t('title.enterCertNumber')"
          title-class="mt0"
          :on-click-next="handleClickNext"
          :input-disabled="inputDisabled"
          :send-on-mounted="sendOnMountedOtpVerify"
          :check-duplicate="checkDuplicate"
          :country-cd="countryCd"
          :country-calling-cd="countryCallingCd"
          :save-otp-response="saveOtpResponse" />
      </div>
    </template>
  </PopupLayout>
</template>
