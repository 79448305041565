<script setup lang="ts">
/**
 * PopupAskLogin
 * @description 로그인 유도 팝업 컴포넌트
 */

import { MODAL_KEY } from '@/constants/modal';
import useCommonStore from '@/stores/common';
import { useModalStore } from '@/stores/modal';
import { goToLoginPage } from '@/utils/router';

import ButtonAtom from '@/components/atoms/ButtonAtom/index.vue';
import PopupLayout from '@/components/molecules/layout/PopupLayout.vue';
import router from '@/router';
const { closeModalAll } = useModalStore();
const { getClientName } = useCommonStore();

const handleLoginClick = async () => {
  goToLoginPage();
};

const handleFindIdClick = async () => {
  await router.push({ name: 'FindId', query: { client_name: getClientName } });
  closeModalAll();
};

const handleFindPasswordClick = async () => {
  await router.push({ name: 'FindPassword', query: { client_name: getClientName } });
  closeModalAll();
};
</script>

<template>
  <PopupLayout tabindex="0" header-right lay-inner :content-id="MODAL_KEY.ASK_LOGIN">
    <template #section>
      <div class="layCon">
        <div class="title_wrap mt0">
          <h2 class="h2_tit" v-html="$t('title.alreadySignupId')"></h2>
        </div>

        <ButtonAtom wrap-mode wrap-class="layBtn" size="sizeL" color="btnBlack" auto tabindex="0" :label="$t('general.login')" :on-click="handleLoginClick" />

        <div class="login_find">
          <a tabindex="0" @click="handleFindIdClick">{{ $t('general.findId') }}</a>
          <a tabindex="0" @click="handleFindPasswordClick">{{ $t('general.findPassword') }}</a>
        </div>
      </div>
    </template>
  </PopupLayout>
</template>
