/**
 * useInputField
 * @description 통합회원 input 필드 검증 핸들러 훅
 */

import { useField } from 'vee-validate';

import type { HtmlEvent } from '@/types/dom';

export default function useInputField(fieldName: string, rules?: string, initialValue?: string) {
  const { setValue, ...fieldsObject } = useField<string>(fieldName, rules, { initialValue });

  const handleChangeUserName = (e?: HtmlEvent<HTMLInputElement>) => {
    if (!e) return;
    setValue(e.target.value);
  };

  const handleChangeInputField = (e?: HtmlEvent<HTMLInputElement>) => {
    if (!e) return;
    setValue(e.target.value.trim());
  };

  return { ...fieldsObject, setValue, handleChangeUserName, handleChangeInputField };
}
