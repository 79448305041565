import { useSessionStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

import useAuthStore from '@/stores/authentication';
import useConditionListStore from '@/stores/conditionList';

import type { LocaleInfo } from '@/types/locales';
import type { OtpPayload, OtpType } from '@/types/otp';
import type { RegisterUser, SignUpData } from '@/types/signUp';

import type { RemovableRef } from '@vueuse/core';

interface SignUpStore {
  countryCallingCd: RemovableRef<string>; // 국가 전화번호
  countryCd: RemovableRef<string>; // 국가 코드
  data: RemovableRef<SignUpData>;
  isAuthenticatedEmail: RemovableRef<boolean>;
  type: RemovableRef<OtpType>; // SignUpCheckUser에서 입력한 회원 아이디 타입
  value: RemovableRef<string>; // SignUpCheckUser에서 입력한 회원 아이디
  isUserAuthenticated: RemovableRef<boolean>; // 본인 인증 여부
  registerUser: RemovableRef<RegisterUser>;
}

const initRegisterUserState = {
  requestId: '',
  name: '',
  email: null,
  otpValidationData: '',
  phone: '',
  password: '',
  personalInfoValidPeriod: '',
  countryCd: null,
  locale: null,
  countryCallingCd: null,
  clientName: null,
  agreeItemList: [],
};
const initSignUpAskInfoResult = {
  cwid: '',
  promotionKey: '',
  signUpDate: '',
  safeKeyStatus: '',
};

const initState: SignUpStore = {
  // SignUpAskAgree
  countryCallingCd: useSessionStorage('signUp/countryCallingCd', ''),
  countryCd: useSessionStorage('signUp/countryCd', ''),
  // SignUpAskInfo
  data: useSessionStorage('signUp/data', initSignUpAskInfoResult),
  registerUser: useSessionStorage('signUp/registerUser', initRegisterUserState),
  isAuthenticatedEmail: useSessionStorage('signUp/isAuthenticatedEmail', false),
  // SignUpCheckUser
  type: useSessionStorage('signUp/type', 'email') as RemovableRef<OtpType>,
  value: useSessionStorage('signUp/value', ''),
  isUserAuthenticated: useSessionStorage('signUp/isUserAuthenticated', false),
};

/**
 * useSignUpStore
 * @description 회원가입
 */
const useSignUpStore = defineStore('signUp', {
  state: (): SignUpStore => ({ ...initState }),
  getters: {
    promotionKey(): string {
      return this.data.promotionKey;
    },
    signUpDate(): string {
      return this.data.signUpDate;
    },
  },
  actions: {
    setSignUpData(value: SignUpData) {
      this.$state.data = { ...value };
    },
    setAuthenticatedEmail() {
      this.$state.isAuthenticatedEmail = true;
    },
    resetAuthenticatedEmail() {
      this.$state.isAuthenticatedEmail = false;
    },
    setOtpRequiredData(payload: OtpPayload) {
      switch (payload.otpType) {
        case 'phoneNumber':
          this.$state.type = payload.otpType;
          this.$state.value = payload.phone;
          break;
        case 'email':
          this.$state.type = payload.otpType;
          this.$state.value = payload.email;
          break;
        default:
          throw new Error('지원하지 않는 otp 타입입니다.');
      }
    },
    setSignUpPromotionKey(value: string) {
      this.$state.data = { ...this.$state.data, promotionKey: value };
    },
    setCountryInfo(countryInfo: LocaleInfo) {
      this.$state.countryCd = countryInfo.countryCd;
      this.$state.countryCallingCd = countryInfo.countryCallingCd;
    },
    setRegisterUser(user: RegisterUser) {
      this.$state.registerUser = { ...user };
    },
    resetCheckUserData() {
      this.$state.type = 'email';
      this.$state.value = '';
    },
    resetRegisterUser() {
      this.$state.registerUser = { ...initRegisterUserState };
    },
    resetSignUp() {
      this.$state.data = initSignUpAskInfoResult;
      this.$state.countryCd = '';
      this.$state.countryCallingCd = '';
      this.resetAuthenticatedEmail();
      this.resetCheckUserData();
      this.resetRegisterUser();
      this.resetUserAuthenticated();
      const { resetConditionList } = useConditionListStore();
      const { resetAuthType } = useAuthStore();
      resetConditionList();
      resetAuthType();
    },
    resetSignUpData() {
      this.$state.data = { ...initSignUpAskInfoResult };
    },
    setUserAuthenticated(value: boolean) {
      this.$state.isUserAuthenticated = value;
    },
    resetUserAuthenticated() {
      this.$state.isUserAuthenticated = false;
    },
  },
});

export default useSignUpStore;
