import { isExistValue } from '@/utils/common';
import { clientNameByQuery } from '@/utils/typeGuard';

const notRequiredClientNameRoutes: string[] = ['/login/terms'];
const footerPolicyRoutes: string[] = ['/policy'];

/**
 * isCowayMall
 * @description clientName이 coway mall 이고, clientName이 필수가 아닌 경로를 필터링.
 * - 푸터약관일 경우 queryString에 cowayid 포함 여부 확인.
 * @param {ClientName} clientName clientName
 */
export function isCowayMall(clientName: string | null): boolean {
  const { pathname } = window.location;

  if (isExistValue(footerPolicyRoutes, pathname) && clientNameByQuery(pathname.split('-')[1]) === 'COWAY_MALL') {
    return true;
  }
  return Boolean(clientName === 'COWAY_MALL' && !isExistValue(notRequiredClientNameRoutes, window.location.pathname));
}
