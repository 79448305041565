import type { Ref } from 'vue';

import { watch, ref } from 'vue';

import { APIS_KEY } from '@/constants/apis';
import { MODAL_KEY } from '@/constants/modal';
import { MyAccountEdit } from '@/domains/myAccountEdit';
import useFetch from '@/hooks/useFetch';
import useCommonStore from '@/stores/common';
import { useModalStore } from '@/stores/modal';
import { getValidationError } from '@/utils/apis';

import { i18n } from '@/i18n';

import type { UseFetch } from '@/types/apis';
import type { PopupOtpMethod } from '@/types/otp';

export class MyAccountPhone {
  response: Ref<UseFetch<{ cwid: string }> | undefined>;
  errorMessage: Ref<string | undefined>;

  constructor() {
    this.response = ref<UseFetch<{ cwid: string }> | undefined>();
    this.errorMessage = ref<string>();
  }

  changePhone = (method: PopupOtpMethod, phone: string, countryCd: string, countryCallingCd: string) => {
    const myAccountEdit = new MyAccountEdit();
    const { getClientName } = useCommonStore();
    const { isPending, isSuccess, data, error } = useFetch<{ cwid: string }>({
      url: `${APIS_KEY.USERINFO_PHONE}`,
      method: 'patch',
      requestData: { phone, countryCd, countryCallingCd },
      isPopup: true,
    });

    watch([isPending, isSuccess, data, error], () => {
      this.response.value = {
        isPending,
        isSuccess,
        data,
        error,
      };

      if (isSuccess.value) {
        myAccountEdit.getAccountInfo(getClientName);
        switch (method) {
          case 'REGISTER':
            this.presentPhoneRegisterAlert();
            break;
          case 'MODIFY':
            this.presentPhoneChangeAlert();
            break;
        }
        return;
      }

      if (error.value) {
        const validationError = getValidationError(error.value);
        this.errorMessage.value = validationError;
      }
    });
  };

  presentPhoneChangeAlert() {
    const { openModal, closeModal, closeModalAll } = useModalStore();

    closeModalAll();
    openModal(
      MODAL_KEY.ALERT,
      {
        message: i18n.global.t('alert.message.changeMemberInfo'),
        onClick: () => {
          closeModal(MODAL_KEY.OTP_AUTH);
        },
      },
      false,
    );
  }

  presentPhoneRegisterAlert(): void {
    const { openModal, closeModal, closeModalAll } = useModalStore();

    closeModalAll();
    openModal(
      MODAL_KEY.ALERT,
      {
        message: i18n.global.t('alert.message.registerMemberInfo'),
        onClick: () => {
          closeModal(MODAL_KEY.OTP_AUTH);
        },
      },
      false,
    );
  }
}
