import type { Ref } from 'vue';

import { isAxiosError } from 'axios';
import { onUnmounted, ref, watch } from 'vue';

import { APIS_KEY } from '@/constants/apis';
import { MODAL_KEY } from '@/constants/modal';
import { OTP_TYPE } from '@/constants/otp';
import useFetch from '@/hooks/useFetch';
import { useModalStore } from '@/stores/modal';
import useOtpStore from '@/stores/otp';
import { getValidationError } from '@/utils/apis';

import { i18n } from '@/i18n';

import type { UseFetch } from '@/types/apis';
import type { ValidationOtpEmailRequest, ValidationOtpPhoneRequest, OtpResponse, ValidateResponse, PurposeType, OtpType, PopupOtpAuth, ValidationOtpRequest, OtpApis } from '@/types/otp';
import type { CheckEmailData, CheckUserStatusResponse } from '@/types/signUp';

type ValidateApisType = {
  phoneNumber: OtpApis;
  email: OtpApis;
};

const emailOtpApis: OtpApis = {
  signUp: APIS_KEY.SEND_OTP_EMAIL,
  findPassword: APIS_KEY.SEND_OTP_EMAIL_EDIT_USERINFO,
  myAccount: APIS_KEY.SEND_OTP_EMAIL_EDIT_USERINFO,
};

const validateApis: ValidateApisType = {
  phoneNumber: {
    signUp: APIS_KEY.VALIDATE_OTP_PHONE,
    findPassword: APIS_KEY.VALIDATE_OTP_PHONE_RESET_PASSWORD,
    myAccount: APIS_KEY.VALIDATE_OTP_PHONE,
  },
  email: {
    signUp: APIS_KEY.VALIDATE_OTP_EMAIL,
    findPassword: APIS_KEY.VALIDATE_OTP_EMAIL_RESET_PASSWORD,
    myAccount: APIS_KEY.VALIDATE_OTP_EMAIL,
  },
};

const inputErrorMessage = {
  email: i18n.global.t('validation.enterExactEmail'),
  phoneNumber: i18n.global.t('validation.enterExactPhoneNumber'),
  toastMessage: (minute: number) => `${i18n.global.t('text.completeSendCertNumber')}\n ${i18n.global.t('text.enterInMinute', { minute })}`,
};

/**
 * otp
 * @description 점유인증 도메인 정의
 */
export default class Otp {
  type = ref<OtpType>('phoneNumber');
  value = ref<string>('');
  certNumber = ref<string>('');
  isSend = ref<boolean>(false);
  sendCount = ref<number>(0);
  remainTime = ref<string | undefined>(undefined);
  inputErrorMessage = ref<string | undefined>(undefined);
  errorMessage = ref<string | undefined>(undefined);
  serverErrorMessage = ref<string | undefined>(undefined);
  isOtpSuccess = ref<boolean>(false);
  sendTime: Date | undefined = undefined;
  expireTime: number = 5 * 1000 * 60;
  timeoutId: NodeJS.Timer | undefined = undefined;
  timerId: NodeJS.Timer | undefined = undefined;
  otpKey: string = '';
  validateData = ref<UseFetch<ValidateResponse> | undefined>(undefined);
  otpData = ref<UseFetch<OtpResponse> | undefined>(undefined);

  popupOtpAuthType: PopupOtpAuth | undefined;
  checkUserResponse: Ref<UseFetch<CheckUserStatusResponse> | undefined>;
  checkEmailResponse: Ref<UseFetch<CheckEmailData> | undefined>;

  constructor() {
    this.checkEmailResponse = ref<UseFetch<CheckEmailData> | undefined>();
    this.checkUserResponse = ref<UseFetch<CheckUserStatusResponse> | undefined>();

    watch(
      () => this.remainTime.value,
      (cur) => {
        if (!cur) {
          this.serverErrorMessage.value = !this.isOtpSuccess.value && !this.isSend.value ? i18n.global.t('text.expiredCertNumber') : undefined;
        }
      },
    );

    onUnmounted(() => {
      this.clearTimer();
    });
  }

  setEmail(value: string) {
    this.value.value = value;
  }

  /**
   * clearTimer
   * @description 남은 시간을 계산하기 위해 setTimeout과 setInterval로 만든 타이머들을 해제한다
   * @returns void
   */
  clearTimer(): void {
    if (this.timerId) {
      clearInterval(this.timerId);
      this.timerId = undefined;
    }
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
    this.remainTime.value = undefined;
  }

  /**
   * setTimer
   * @description otp 를 전송한 시간과 현재 시간의 차이를 계산하여 remainTime 값을 변경한다
   * @returns void
   */
  setTimer(): void {
    if (!this.sendTime) {
      this.clearTimer();
      return;
    }
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
    const passingTime = new Date().getTime() - this.sendTime.getTime();
    const timeGap = this.expireTime - passingTime;
    if (timeGap < this.expireTime && timeGap > 1000) {
      this.remainTime.value = this.formatTime(timeGap);
    } else {
      this.isSend.value = false;
      this.clearTimer();
    }
  }

  /**
   * formatTime
   * @description 잔여시간을 화면에 보여지는 형태로 포맷 변경
   * @param timeGap 잔여시간
   * @returns 00 : 01
   */
  formatTime(timeGap: number): string {
    const sec = Math.floor(timeGap / 1000);
    const seconds = sec % 60;
    return `${String(Math.floor(sec / 60)).padStart(2, '0')} : ${String(seconds).padStart(2, '0')}`;
  }

  /**
   * showToastMessage
   * @description 토스트 메세지 노출
   */
  showToastMessage(): void {
    const { openModal, closeModal } = useModalStore();

    const minute = Math.floor(Number(this.otpData.value?.data?.data?.remainTimeInSec) / 60);
    openModal(MODAL_KEY.TOAST, { message: inputErrorMessage.toastMessage(minute) });

    const timer = setTimeout(() => {
      closeModal(MODAL_KEY.TOAST);
      clearTimeout(timer);
    }, 3000);
  }

  get isEmail(): boolean {
    return !!(this.type.value === 'email');
  }

  /**
   * getOtpResponse
   * @description 타입에 따라 otp를 전송하고 그 결과를 리턴
   * @returns otp 전송 결과
   */
  getOtpResponse(purpose?: PurposeType, countryCd?: string, countryCallingCd?: string | null): UseFetch<OtpResponse> {
    switch (this.type.value) {
      case 'phoneNumber':
        return useFetch<OtpResponse>({ url: APIS_KEY.SEND_OTP_PHONE, method: 'post', requestData: { phone: this.value.value, countryCallingCd }, isPopup: true });
      case 'email':
        if (!purpose) {
          throw new Error('purpose is required');
        }
        return useFetch<OtpResponse>({ url: emailOtpApis[purpose], method: 'post', requestData: { email: this.value.value, countryCd: countryCd ?? '' }, isPopup: true });
      default:
        throw new Error('otpType is wrong');
    }
  }

  inputErrorMessageByPurpose(isExist: boolean | undefined, purpose?: PurposeType): string | undefined {
    if (purpose === 'findPassword' && !isExist) {
      return i18n.global.t('validation.notSignUpEmail');
    } else if ((purpose === 'signUp' || purpose === 'myAccount') && isExist) {
      return i18n.global.t('validation.alreadyExistsEmail');
    }
  }

  /**
   * checkEmailAndSendOtp
   * @description 이메일 주소로 중복 체크 이후 otp 전송
   */
  checkEmailAndSendOtp(purpose?: PurposeType, countryCd?: string): void {
    const { isSuccess, data, error } = useFetch<CheckEmailData>({ url: APIS_KEY.CHECK_EXIST_EMAIL, method: 'post', requestData: { email: this.value.value }, isPopup: true });
    watch([isSuccess, data, error], async ([isSuccessCur, dataCur, errorCur]) => {
      try {
        if (!isSuccessCur || errorCur) throw errorCur;
        this.inputErrorMessage.value = undefined;
        this.inputErrorMessage.value = this.inputErrorMessageByPurpose(dataCur?.data.exist, purpose);

        if (!this.inputErrorMessage.value) {
          this.sendOtp(purpose, countryCd);
        }
      } catch (e) {
        if (e instanceof Error) {
          this.inputErrorMessage.value = i18n.global.t('validation.notSignUpEmail');
          console.warn(`Failed to check if an email address exists. error: ${JSON.stringify(e)}`);
        }
      }
    });
  }

  /**
   * checkPhoneNumberAndSendOtp
   * @description 휴대폰 번호로 중복 체크 이후 otp 전송
   */
  checkPhoneNumberAndSendOtp(countryCd?: string, countryCallingCd?: string) {
    const { isPending, isSuccess, data, error } = useFetch<CheckUserStatusResponse>({
      url: APIS_KEY.SIGNUP_CHECK_USER_PHONE,
      method: 'post',
      requestData: { phoneNumber: this.value.value, countryCd, countryCallingCd },
      isPopup: true,
    });

    watch([isPending, isSuccess, data, error], () => {
      this.checkUserResponse.value = {
        isPending,
        isSuccess,
        data,
        error,
      };
      if (isSuccess.value && data.value?.data.status === 'EXISTING_USER_PHONE') {
        this.inputErrorMessage.value = i18n.global.t('commonErrorMessage.alreadyExistPhone');
      } else if (isSuccess.value) {
        this.inputErrorMessage.value = undefined;
        this.sendOtp('signUp', countryCd, countryCallingCd);
      }
    });
  }

  /**
   * sendOtp
   * @description 실제로 전송 버튼 클릭시 실행되는 함수로 otp 전송 성공/실패 시 타이머나 기타 속성 값들에 대해 처리
   */
  async sendOtp(purpose?: PurposeType, countryCd?: string, countryCallingCd?: string | null) {
    const { isPending, isSuccess, data, error } = this.getOtpResponse(purpose, countryCd, countryCallingCd);
    watch(
      [isPending, isSuccess, data, error],
      ([isPendingCur, isSuccessCur, dataCur, errorCur]) => {
        this.otpData.value = { isPending: isPendingCur, isSuccess: isSuccessCur, data: dataCur, error: errorCur };
        if (!isPendingCur && dataCur?.data.otpKey) {
          this.otpKey = dataCur.data.otpKey;
          this.isSend.value = true;
          this.sendCount.value++;
          this.errorMessage.value = undefined;
          this.serverErrorMessage.value = undefined;
          this.isOtpSuccess.value = false;
          this.sendTime = new Date();
          this.showToastMessage();
          this.clearTimer();
          this.timeoutId = setTimeout(this.setTimer.bind(this), 10);
          this.timerId = setInterval(this.setTimer.bind(this), 1000);
        }
      },
      { immediate: true },
    );
  }

  /**
   * validateCertNumber
   * @description 인증번호를 타입에 따라 검증하며 성공/실패 처리
   */
  async validateCertNumber(saveOtpResponse: boolean, purpose: PurposeType, countryCallingCd?: string): Promise<void> {
    if (!this.certNumber.value) {
      return;
    }

    const otpVerificationData: ValidationOtpRequest = {
      otpKey: this.otpKey,
      otp: this.certNumber.value,
    };
    const url = validateApis[this.type.value][purpose];
    let requestData: ValidationOtpPhoneRequest | ValidationOtpEmailRequest;

    switch (this.type.value) {
      case 'phoneNumber':
        requestData = { ...otpVerificationData, phone: this.value.value, countryCallingCd: countryCallingCd ?? '' };
        break;
      case 'email':
        requestData = { ...otpVerificationData, email: this.value.value };
        break;
    }

    const { setOtpValidationResponse } = useOtpStore();
    const { isPending, isSuccess, data, error } = useFetch<ValidateResponse>({ url, method: 'post', requestData, isPopup: true });
    watch(
      [isPending, isSuccess, data, error],
      ([isPendingCur, isSuccessCur, dataCur, errorCur]) => {
        try {
          this.validateData.value = { isPending: isPendingCur, isSuccess: isSuccessCur, data: dataCur, error: errorCur };
          if (isSuccessCur && dataCur?.data.isValid) {
            this.isOtpSuccess.value = true;
            saveOtpResponse && setOtpValidationResponse(dataCur.data);
            this.clearTimer();
          }
          if (errorCur) {
            throw errorCur;
          }
        } catch (e) {
          if (isAxiosError(e)) {
            const validationError = getValidationError(e);
            this.serverErrorMessage.value = validationError;
          }
        }
      },
      { deep: true },
    );
  }

  setEmailAuthType(value: PopupOtpAuth) {
    switch (value.purpose) {
      case 'SIGN_UP':
        this.popupOtpAuthType = {
          purpose: value.purpose,
        };
        break;
      case 'MY_ACCOUNT':
        this.popupOtpAuthType = {
          purpose: value.purpose,
          method: value.method,
        };
        break;
      default:
        throw new Error('지원하지 않는 타입입니다.');
    }
  }

  async checkEmailOpenModal(countryCd?: string, countryCallingCd?: string | null, saveOtpResponse: boolean = true): Promise<void> {
    const { isPending, isSuccess, data, error } = useFetch<CheckEmailData>({
      url: APIS_KEY.CHECK_EXIST_EMAIL,
      method: 'post',
      requestData: { email: this.value.value },
      isPopup: true,
    });

    watch([isPending, isSuccess, data, error], () => {
      this.checkEmailResponse.value = {
        isPending,
        isSuccess,
        data,
        error,
      };

      if (!this.popupOtpAuthType?.purpose) {
        throw new Error('callback purpose값이 지정되지 않았습니다.');
      }

      if (isSuccess.value && data.value?.data) {
        if (data.value?.data.exist) {
          this.inputErrorMessage.value = i18n.global.t('validation.alreadyExistsEmail');
          return;
        }
        this.inputErrorMessage.value = undefined;
        this.checkEmailCallback(data.value?.data, this.popupOtpAuthType, saveOtpResponse, countryCd, countryCallingCd);
      }
    });
  }

  checkEmailCallback(data: CheckEmailData, popupOtpAuthType: PopupOtpAuth, saveOtpResponse: boolean, countryCd?: string, countryCallingCd?: string | null): void {
    if (!data.exist) {
      const { openModal } = useModalStore();
      openModal(MODAL_KEY.OTP_AUTH, {
        otpState: { email: this.value.value, otpType: OTP_TYPE.EMAIL },
        type: popupOtpAuthType,
        inputDisabled: true,
        countryCd,
        countryCallingCd,
        saveOtpResponse,
      });
    } else {
      throw new Error('The email has been taken by someone.');
    }
  }
}
