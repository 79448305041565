/**
 * i18n
 * @description 언어 설정
 */

import { createI18n } from 'vue-i18n';

import { getLocale, isAvailableLocale } from '@/utils/locale';
import { isApp } from '@/utils/userAgent';

import en from '@/locales/en.json';
import ja from '@/locales/ja.json';
import ko from '@/locales/ko.json';
import zh from '@/locales/zh.json';

import type { SupportedLocale } from '@/types/locales';

const messages = {
  ko,
  en,
  ja,
  zh,
};

export const i18n = createI18n({
  legacy: false,
  locale: isApp() ? 'en' : getLocale(),
  fallbackLocale: 'en',
  messages,
});

export function getI18nLocale(): SupportedLocale {
  return i18n.global.locale.value;
}

export function setI18nLocale(value: SupportedLocale): void {
  if (!isAvailableLocale(value)) {
    console.warn('지원하지 않는 언어셋입니다.');
    return;
  }
  i18n.global.locale.value = value;
}
