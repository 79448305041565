import type { ClientName } from '@/types/terms';

/**
 * clientNameByQuery
 * @description route type으로 부터 넘어오는 clientName을 지정해준다.
 * @param {string} client string형태의 clientName
 * @returns {ClientName | null}
 */
export function clientNameByQuery(client: string): ClientName | null {
  const result = client.replaceAll(/cowayid|cowaymall/gi, 'COWAY_MALL') ?? client.toUpperCase();
  return isAvailableClientName(result) ? result : null;

  function isAvailableClientName(name: string): name is ClientName {
    return ['COWAY_MALL', 'IOCARE'].includes(name);
  }
}

/**
 * dataShouldBeString
 * @description string 타입으로 지정해준다.
 */
export function dataShouldBeString(data: unknown): data is string {
  if (typeof data !== 'string' || !String(data).length) return false;
  else return true;
}
