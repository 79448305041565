export const AUTHENTICATE_TYPE = {
  CHANGE_PHONE_NUMBER: 'change-phone-number',
  CHANGE_PHONE: 'change-phone',
  PROMOTE_USER: 'promote-user',
  FIND_ID: 'find-id',
  FIND_PASSWORD: 'find-password',
  PROMOTE_USER_WITHOUT_LOGIN: 'promote-user-without-login',
  MIGRATE_USER: 'migrate-user',
} as const;

export const EMAIL_AUTH_TYPE = {
  MY_ACCOUNT: 'MY_ACCOUNT',
  SIGN_UP: 'SIGN_UP',
} as const;

export const AUTH_TYPE = {
  PHONE: 'phone',
  PHONE_NUMBER_OTP: 'phoneNumberOtp',
  EMAIL_OTP: 'emailOtp',
} as const;

export const FIND_PASSWORD_AUTH_TYPE = {
  PHONE: 'phone',
  EMAIL_OTP: 'emailOtp',
} as const;
