<script setup lang="ts">
/**
 * PopupLayout
 * @description 팝업 공통 모달 레이아웃
 */

import { MODAL_KEY } from '@/constants/modal';
import { useModalStore } from '@/stores/modal';

import PopupHeader from '@/components/molecules/layout/PopupHeader.vue';

import type { ModalKey } from '@/types/modal';

interface Props {
  title?: string;
  layInner?: boolean;
  headerLeft?: boolean;
  onClickHeaderLeft?: (e?: MouseEvent) => void;
  onKeyupHeaderLeft?: (e?: KeyboardEvent) => void;
  headerRight?: boolean;
  onClickHeaderRight?: (e?: MouseEvent) => void;
  onKeyupHeaderRight?: (e?: KeyboardEvent) => void;
  contentId: ModalKey;
}
const props = withDefaults(defineProps<Props>(), {
  title: undefined,
  layInner: false,
  headerLeft: false,
  onClickHeaderLeft: undefined,
  onClickHeaderRight: undefined,
  headerRight: false,
  onKeyupHeaderLeft: undefined,
  onKeyupHeaderRight: undefined,
  contentId: MODAL_KEY.ALERT,
});

const { closeModal } = useModalStore();

const handleClickLeft = () => {
  props.onClickHeaderLeft?.();
};

const handleKeyupLeft = () => {
  props.onKeyupHeaderLeft?.();
};

const handleClickRight = () => {
  closeModal(props.contentId);
  props.onClickHeaderRight?.();
};

const handleKeyupRight = () => {
  closeModal(props.contentId);
  props.onKeyupHeaderRight?.();
};
</script>

<template>
  <div class="cw-layer-wrap">
    <div :class="layInner ? 'lay_inner full' : 'u-page-wrap'">
      <header class="u-header-wrap">
        <PopupHeader
          v-bind="{ ...props }"
          :on-click-header-right="handleClickRight"
          :on-click-header-left="handleClickLeft"
          :on-keyup-header-left="handleKeyupLeft"
          :on-keyup-header-right="handleKeyupRight" />
      </header>

      <div class="u-tabs-wrap">
        <slot name="tab"></slot>
      </div>

      <article class="u-section-wrap">
        <slot name="section"></slot>
      </article>

      <slot name="footer"></slot>
    </div>
  </div>
</template>
