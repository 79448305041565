import { safeKeyStatus as safeKeyStatusCode } from '@cw-account/account-frontend-sdk';

import { API_CODES } from '@/constants/apis';
import { AUTH_TYPE } from '@/constants/authentication';
import { MODAL_KEY } from '@/constants/modal';
import useAuthStore from '@/stores/authentication';
import { useModalStore } from '@/stores/modal';

import { i18n } from '@/i18n';
import { setupCowayId } from '@/plugins/cowayAccount';

import type { AuthPurpose } from '@/types/authentication';

import type { SafeKeyStatusErrorValues, AuthenticationResponse, SafeKeyStatusValues, CowayId } from '@cw-account/account-frontend-sdk';

export class Authentication {
  $cowayId: CowayId = setupCowayId();
  handleAuthenticationError(errorResponse: any) {
    const { openModal } = useModalStore();
    const { code } = errorResponse;
    switch (code) {
      case API_CODES.ALREADY_VERIFIED_USER:
        openModal(MODAL_KEY.ALERT, { message: i18n.global.t('validation.alreadySelfCert') });
        break;
      case API_CODES.NOT_EXIST_PROMOTION_KEY:
        openModal(MODAL_KEY.ALERT, { message: i18n.global.t('validation.invalidRequestPleaseRetry') });
        break;
      case API_CODES.INVALID_VERIFICATION_REQUEST_ID:
        openModal(MODAL_KEY.ALERT, { message: i18n.global.t('alert.message.invalidRequestPleaseRetry') });
        break;
      case API_CODES.INVALID_USER:
        openModal(MODAL_KEY.ALERT, { message: i18n.global.t('alert.message.canChangeYourOwn') });
        break;
      case API_CODES.INVALID_VERIFICATION_UNDER_FOURTEEN_YEARS_OLD:
        openModal(MODAL_KEY.ALERT, { message: i18n.global.t('alert.message.underFourteen') });
        break;
      default:
        openModal(MODAL_KEY.ALERT, { message: i18n.global.t('alert.message.failSelfCert') });
        break;
    }
  }

  authenticateUser(purpose: AuthPurpose): Promise<AuthenticationResponse> {
    const cowayId = this.$cowayId;
    return new Promise<AuthenticationResponse>(function (resolve, reject) {
      cowayId.authenticateUser(`${location.origin}/user-authenticate/${purpose}/success`, `${location.origin}/user-authenticate/${purpose}/failure`, (authenticationResponse) => {
        if (authenticationResponse?.code === API_CODES.SUCCESS) {
          const { setAuthType, setDecryptData } = useAuthStore();
          setAuthType(AUTH_TYPE.PHONE);
          setDecryptData(authenticationResponse.data);

          resolve(authenticationResponse);
        }
        reject(authenticationResponse);
      });
    });
  }

  /**
   * SafeKey의 상태를 보고 에러처리가 필요한지 여부를 반환한다.
   * @param safeKeyStatus
   * @returns boolean
   */
  isSafeKeyError(safeKeyStatus: SafeKeyStatusValues): safeKeyStatus is SafeKeyStatusErrorValues {
    return [safeKeyStatusCode.niceConnectionError, safeKeyStatusCode.niceEtcError, safeKeyStatusCode.niceDataEmptyError].includes(safeKeyStatus);
  }

  /**
   * 본인인증은 성공했지만 네트워크 에러가 발생하여 SafeKey 발급에 실패하여
   * 본인인증 재시도 버튼을 클릭하면 이 함수로 추가 본인인증을 진행한다.
   */
  retrySafeKeyRegistration(purpose: AuthPurpose = 'find-id') {
    const { openModal } = useModalStore();
    this.$cowayId?.authenticateUser(
      `${location.origin}/user-authenticate/${purpose}/success`,
      `${location.origin}/user-authenticate/${purpose}/failure`,
      (authenticationResponse: AuthenticationResponse) => {
        try {
          const { code, data } = authenticationResponse;
          if (code === API_CODES.SUCCESS) {
            // SafeKey 에러 처리
            if (this.isSafeKeyError(data.safeKeyStatus)) {
              this.handleSafeKeyError(data.safeKeyStatus, purpose);
            } else {
              openModal(MODAL_KEY.ALERT, { message: i18n.global.t('alert.message.completeSelfCert') });
            }
          } else {
            openModal(MODAL_KEY.ALERT, { message: i18n.global.t('alert.message.failSelfCert') });
          }
        } catch (error) {
          openModal(MODAL_KEY.ALERT, { message: i18n.global.t('alert.message.failSelfCert') });
          console.error('Authentication to retry safekey registration has failed. : ', error);
        }
      },
    );
  }

  /**
   * SafeKey 상태가 정상적이지 않은경우 사용자에게 적절한 안내를 한다.
   * @param safeKeyStatusError 세이프키 발급 중 에러가 발생하여 사용자에게 안내가 필요한 세이프키 상태값.
   */
  handleSafeKeyError(safeKeyStatusError: SafeKeyStatusErrorValues, purpose: AuthPurpose = 'find-id') {
    const { openModal } = useModalStore();
    if (safeKeyStatusError === safeKeyStatusCode.niceDataEmptyError) {
      openModal(MODAL_KEY.ALERT, { message: i18n.global.t('alert.message.safeKeyNotCompleteCallNice') });
    } else {
      openModal(MODAL_KEY.CONFIRM, {
        message: i18n.global.t('alert.message.safeKeyNotComplete'),
        onClickConfirm: () => this.retrySafeKeyRegistration(purpose),
        confirmButtonLabel: i18n.global.t('confirm.message.retry'),
        closeButtonLabel: i18n.global.t('confirm.message.nextTry'),
      });
    }
  }
}
