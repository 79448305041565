<script setup lang="ts">
import '@/assets/css/common.css';
import '@/assets/css/member.css';
import '@/assets/css/account.css';
import '@/assets/scss/components/checkbox.scss';
import '@/assets/scss/components/popup.scss';

import { storeToRefs } from 'pinia';
import { onBeforeMount } from 'vue';

import useInitCommonData from '@/hooks/useInitCommonData';
import useCommonStore from '@/stores/common';

import Loading from '@/components/Loading.vue';

import ModalProvider from '@/components/organism/ModalProvider.vue';

const { initLocaleData, initCountryList } = useInitCommonData();
const { setLocale } = useCommonStore();
const { locale, isLoading } = storeToRefs(useCommonStore());

onBeforeMount(() => {
  setLocale('');
  initLocaleData();
  initCountryList();
});
</script>

<template>
  <ModalProvider v-show="locale">
    <router-view />
    <template v-if="isLoading">
      <Loading />
    </template>
  </ModalProvider>
</template>
