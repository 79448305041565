import { MODAL_KEY } from '@/constants/modal';
import { Authentication } from '@/domains/authentication';
import { useModalStore } from '@/stores/modal';
import useSignUpStore from '@/stores/signUp';
import { goToLoginPage } from '@/utils/router';

import { i18n } from '@/i18n';
import router from '@/router';

import type { AuthPurpose } from '@/types/authentication';
export class AuthenticationSignUpFindId extends Authentication {
  purpose: AuthPurpose;

  constructor(purpose: AuthPurpose) {
    super();
    this.purpose = purpose;
  }

  async authenticateSignUpFindId(emailOrPhoneNumber: string) {
    const { openModal } = useModalStore();
    try {
      const res = await this.authenticateUser(this.purpose);
      const { mobileNumber, exist, ageGroup } = res.data;
      const { setUserAuthenticated } = useSignUpStore();

      window.sendGaEvent('signUpDuplicatePhoneNumberAuthenticationSuccess');
      if (exist) {
        openModal(MODAL_KEY.ALERT, {
          message: i18n.global.t('alert.message.alreadySignUp'),
          buttonLabel: i18n.global.t('general.login'),
          onClick: () => {
            goToLoginPage();
          },
        });
        return false;
      }

      if (mobileNumber !== emailOrPhoneNumber) {
        openModal(MODAL_KEY.ALERT, { message: i18n.global.t('alert.message.selfCertSamePhoneNumber') });
        return false;
      }

      if (ageGroup === 'YOUTH') {
        openModal(MODAL_KEY.ALERT, { message: i18n.global.t('alert.message.underNineteen') });
        return false;
      }

      setUserAuthenticated(true);
      router.push({ name: 'SignUpAskInfo' });

      window.sendGaEvent('signUpDuplicatePhoneNumberAuthenticationSuccess');
    } catch (e: any) {
      this.handleAuthenticationError(e);
      window.sendGaEvent('signUpDuplicatePhoneNumberAuthenticationFailure');
    }
  }
}
