<script setup lang="ts">
/**
 * PopupHeader
 * @description 약관 팝업모달에서 사용되는 헤더
 */

import ButtonAtom from '@/components/atoms/ButtonAtom/index.vue';

interface Props {
  title?: string;
  headerLeft?: boolean;
  onClickHeaderLeft?: (e?: MouseEvent) => void;
  onKeyupHeaderLeft?: (e?: KeyboardEvent) => void;
  headerRight?: boolean;
  onClickHeaderRight?: (e?: MouseEvent) => void;
  onKeyupHeaderRight?: (e?: KeyboardEvent) => void;
}

const props = defineProps<Props>();

const handleClickLeft = () => {
  props.onClickHeaderLeft?.();
};

const handleKeyupLeft = () => {
  props.onKeyupHeaderLeft?.();
};

const handleClickRight = () => {
  props.onClickHeaderRight?.();
};

const handleKeyupRight = () => {
  props.onKeyupHeaderRight?.();
};
</script>

<template>
  <nav class="u-title-wrap">
    <div class="header-center">
      <a class="title">{{ title }}</a>
    </div>
    <div v-if="headerLeft" class="header-left">
      <ButtonAtom class="btn-back" :on-click="handleClickLeft" :on-keyup="handleKeyupLeft"><i class="blind">뒤로</i></ButtonAtom>
    </div>
    <div v-if="headerRight" class="header-right">
      <ButtonAtom class="btn-close" :on-click="handleClickRight" :on-keyup="handleKeyupRight"><i class="blind">닫기</i></ButtonAtom>
    </div>
  </nav>
</template>
