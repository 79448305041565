<script setup lang="ts">
/**
 * CountrySelectAtom
 * @description 국가 locale 셀렉트 박스 컴포넌트
 */
import { ref } from 'vue';

import type { LocaleInfo } from '@/types/locales';

interface Props {
  selected: LocaleInfo;
  options: LocaleInfo[];
  disabled?: boolean;
  onClickOption?: (id: LocaleInfo) => void;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  onClickOption: undefined,
});
const isOpen = ref<boolean>(false);

const handleClick = () => {
  if (props.disabled) {
    return;
  }
  isOpen.value = !isOpen.value;
};

const handleClickOption = (info: LocaleInfo) => {
  props.onClickOption?.(info);
};
</script>

<template>
  <div class="global_select">
    <div id="select_country" class="select_cstm country" :class="{ open: isOpen, disabled }" @click.prevent="handleClick">
      <div class="title">
        <input id="select_cstm1" type="radio" value="" class="option" name="select_cstm" checked />
        <label for="select_cstm1">
          <img :src="`/front/images/country/${selected.countryCd.toLowerCase()}.png`" />
          <span>{{ $t(selected.countryCd) }} ({{ selected.countryCd }})</span>
          <span class="call_num">{{ selected.countryCallingCd }}</span>
        </label>
      </div>

      <ul class="selList">
        <li
          v-for="{ countryName, countryCd, countryCallingCd } in options"
          id="county-countryNumber"
          :key="countryCd"
          :class="{ active: selected.countryCd === countryCd }"
          @click.prevent="() => handleClickOption({ countryName, countryCd, countryCallingCd })">
          <input :id="countryCd" type="radio" :value="countryCd" class="option" name="select_cstm" />
          <label :for="countryCd">
            <img :src="`/front/images/country/${countryCd.toLowerCase()}.png`" />
            <span>{{ $t(countryCd) }} ({{ countryCd }})</span>
            <span class="call_num">{{ countryCallingCd }}</span>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>
