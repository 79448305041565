import { defineStore } from 'pinia';

import type { Modal, ModalKey } from '@/types/modal';

interface ModalList {
  modalList: Modal[];
}

/**
 * useModalStore
 * @description 모달 제어 스토어
 * - modalList: 모달 리스트, 생성될 때 마다 모달이 쌓이는 구조
 */
export const useModalStore = defineStore('modal', {
  state: (): ModalList => ({ modalList: [] }),
  getters: {
    getModals: (state) => ({ ...state }),
    numberOfModalList: (state) => state.modalList.length,
  },
  actions: {
    openModal(contentId: ModalKey, props?: any, layoutClickDisabled: boolean = true) {
      this.modalList.push({
        isOpen: true,
        layoutClickDisabled,
        contentId,
        props,
      });
    },
    closeModal(id: ModalKey) {
      const result = this.modalList.filter(({ contentId }) => contentId !== id);
      this.modalList = result;
    },
    closeModalAll() {
      this.modalList = [];
    },
  },
});
