import type { SupportedLocale } from '@/types/locales';

/**
 * locale
 * @description 언어 설정과 관련된 함수 모음
 */

/**
 * isAvailableLocale
 * @description locale이 ko, ja, en, zh 일 경우 true 반환.
 */
export const isAvailableLocale = (locale: string): locale is SupportedLocale => {
  return ['ko', 'en', 'ja', 'zh'].indexOf(locale) !== -1;
};

/**
 * getLocale
 * @description 언어 정보를 반환하는 유틸 함수로 파라미터에서 추출하거나, navigator language에서 추출
 * @return 언어
 */
export function getLocale(localeParam?: string): SupportedLocale {
  const language = localeParam ?? navigator.language;
  const isZhLocale = language === 'zh' || language.includes('zh-TW') || language.includes('zh-Hant');
  const parsedLocale = isZhLocale ? 'zh' : language.substring(0, 2);
  return isAvailableLocale(parsedLocale) ? parsedLocale : 'en';
}

/**
 * isDomesticLocale
 * @description 파라미터로 받은, 혹은 현재 locale이 국내로 셋팅되어 있는지 여부
 * @param {string} locale 언어 값
 * @return ko / ko-KR의 locale의 경우 true. 그렇지않으면 false
 */
export function isDomesticLocale(locale: string): boolean {
  const koreaLocale = ['ko', 'ko-KR'];
  return koreaLocale.some((ko) => ko === locale);
}

/**
 * isUSLocale
 * @description 파라미터로 받은, 혹은 현재 locale이 영어권으로 셋팅되어 있는지 여부
 * @param {string} locale 언어 값
 * @return en / en-US locale의 경우 true. 그렇지않으면 false
 */
export function isUSLocale(locale: string): boolean {
  const target = ['en', 'en-US'];
  return target.some((value) => value === locale);
}
