import { defineStore } from 'pinia';

import type { MyAccountEditChangeData } from '@/types/myAccount';

interface MyAccountEditStore {
  userInfo: MyAccountEditChangeData;
}

const initUserInfoState = {
  email: '',
  name: '',
  phone: '',
  verification: false,
  personalInfoValidPeriod: null,
  agreeMarketingEmailSmsReceive: false,
  agreeUpdatedAt: '',
  countryCd: '',
  countryCallingCd: '',
  clientName: null,
};
const initState = {
  userInfo: initUserInfoState,
};

/**
 * useMyAccountEditStore
 * @description 회원정보관리 > 회원정보 수정 스토어
 */
const useMyAccountEditStore = defineStore('myAccountEdit', {
  state: (): MyAccountEditStore => {
    return { ...initState };
  },
  actions: {
    setUserEditInfo(value: MyAccountEditChangeData) {
      this.$state = { ...this.$state, userInfo: { ...value } };
    },
    resetUserEditInfo() {
      this.$state.userInfo = { ...initUserInfoState };
    },
  },
});

export default useMyAccountEditStore;
