import { MyAccountPhone } from '@/domains/myAccountPhone';
import useCommonStore from '@/stores/common';
import useMyAccountEditStore from '@/stores/myAccountEdit';
import useOtpStore from '@/stores/otp';

import type { PopupOtpAuth } from '@/types/otp';

export default class OtpPhoneHandle {
  countryCallingCd: string;
  countryCd: string;

  constructor() {
    this.countryCallingCd = this.callingCode;
    this.countryCd = this.countryCode;
  }

  get countryCode(): string {
    const { userInfo } = useMyAccountEditStore();
    return userInfo.countryCd;
  }

  get callingCode(): string {
    const { countryList } = useCommonStore();
    const { userInfo } = useMyAccountEditStore();
    return countryList.find((info) => !!(info.countryCd === userInfo.countryCd))?.countryCallingCd ?? '';
  }

  handlePhoneOtpPopupIsSuccess(popupOtpAuthType: PopupOtpAuth) {
    const { purpose } = popupOtpAuthType;
    const { value: otpValue } = useOtpStore();

    if (purpose === 'MY_ACCOUNT') {
      window.sendGaEvent('myAccountPhoneNumberOtpSuccess');
      new MyAccountPhone().changePhone(popupOtpAuthType.method, otpValue, this.countryCd, this.callingCode);
    } else {
      throw new Error('지원하지 않는 방식입니다.');
    }
  }
}
