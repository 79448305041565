<script setup lang="ts">
/**
 * PopupTerms
 * @description 약관 모달 컴포넌트
 */

import { ref, watchEffect, watch } from 'vue';

import { MODAL_KEY } from '@/constants/modal';
import Terms from '@/domains/terms';
import { useModalStore } from '@/stores/modal';

import ButtonAtom from '@/components/atoms/ButtonAtom/index.vue';
import SelectAtom from '@/components/atoms/SelectAtom/index.vue';
import PopupLayout from '@/components/molecules/layout/PopupLayout.vue';

import type { HtmlEvent } from '@/types/dom';
import type { SearchTermsCategory, TermsHistory } from '@/types/terms';

interface SelectOption {
  text: string; // instance.publishedAt
  value: string; //instance.historyId
}
interface Props {
  title: string;
  categoryList: SearchTermsCategory[];
  countryCd: string;
  onClickClose?: () => void;
}

const props = defineProps<Props>();

const { closeModal } = useModalStore();

const terms = new Terms();
const content = ref<string>(props.categoryList[0].content);
const categoryIndex = ref<number>(0);
const termsOptions = ref<SelectOption[]>(getTermsOption(props.categoryList[0].historyList));

watchEffect(() => {
  terms.setCountryCd(props.countryCd ?? 'KR');
});

watch(terms.termsContent, (curr) => {
  if (terms.termsContent.value) {
    content.value = curr;
  }
});

const handleClickClose = () => {
  if (props.onClickClose) {
    props.onClickClose();
    return;
  }

  closeModal(MODAL_KEY.TERMS);
  document.getElementsByTagName('html')[0].style.overflow = 'auto';
};

const handleClickCategory = (argIndex: number) => {
  if (!props.categoryList) {
    return;
  }
  categoryIndex.value = argIndex;
  content.value = props.categoryList[argIndex].content;
  termsOptions.value = getTermsOption(props.categoryList[argIndex].historyList);
};

const handleKeyupCategory = (argIndex: number, e: KeyboardEvent) => {
  e.preventDefault();
  handleClickCategory(argIndex);
};

const handleChangeHistorySelect = (e: Event) => {
  const historyId = (e as HtmlEvent<HTMLInputElement>).target.value;
  terms.getTermsRevisionById(historyId);
};

function getTermsOption(list: TermsHistory[] | null): SelectOption[] {
  return list ? list.reduce<SelectOption[]>((prev, curr) => [...prev, { text: curr.publishedAt, value: curr.historyId }], []) : [];
}
</script>

<template>
  <PopupLayout tabindex="0" :title="$t(title)" :content-id="MODAL_KEY.TERMS">
    <template v-if="categoryList.length > 1" #tab>
      <ul class="flexbox">
        <li v-for="(category, id) in categoryList" :key="category.termsId" class="cell" :class="id === categoryIndex && 'active'" @click="handleClickCategory(id)">
          <a tabindex="0" @keyup.enter="handleKeyupCategory(id, $event)">
            <span> {{ category.title }} </span>
          </a>
        </li>
      </ul>
    </template>

    <template #section>
      <div class="u-terms-wrap">
        <div class="terms_Tab">
          <span v-html="content"></span>
        </div>
      </div>
    </template>

    <template #footer>
      <footer class="u-bottom-wrap">
        <SelectAtom v-if="termsOptions.length > 0" tabindex="0" :selected="termsOptions[0]" :options="termsOptions" :on-change-select="handleChangeHistorySelect" />
        <ButtonAtom tabindex="0" color="btnBlack" size="sizeL" auto :label="$t('general.close')" :on-click="handleClickClose" :on-keyup="handleClickClose" />
      </footer>
    </template>
  </PopupLayout>
</template>
