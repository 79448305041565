import useSignUpStore from '@/stores/signUp';
import { goToLoginPage } from '@/utils/router';

import type { CommonErrorMessageType } from '@/types/apis';

export const APIS_KEY = {
  TERMS_CATEGORY_TYPE_API: '/v2/terms/category-type',
  TERMS_CATEGORY_SEARCH_API: '/v2/terms/category-search',
  TERMS_HISTORY_API: '/v1/terms/history',
  TERMS_REVISION_API: '/v1/terms/revision',
  SEND_OTP_EMAIL: '/v2/otp/email/send',
  SEND_OTP_EMAIL_EDIT_USERINFO: '/v2/otp/email/userinfo/send',
  SEND_OTP_PHONE: '/v2/otp/phone/send',
  VALIDATE_OTP_PHONE: '/v2/otp/phone/validate',
  VALIDATE_OTP_PHONE_RESET_PASSWORD: '/v2/otp/phone/info/validate',
  VALIDATE_OTP_EMAIL: '/v1/otp/email/validate',
  VALIDATE_OTP_EMAIL_RESET_PASSWORD: '/v1/otp/email/info/validate',
  CHECK_PASSWORD_OTP_EMAIL: '/v1/recovery/password/check-valid/by-email',
  CHECK_PASSWORD_PHONE: '/v1/recovery/password/check-valid/by-verification',
  SIGNUP_CHECK_USER_EMAIL: '/v1/register/check-exist/by-email',
  SIGNUP_CHECK_USER_PHONE: '/v2/register/check-exist/by-phone',
  REGISTER_BY_EMAIL: '/v2/register/by-email',
  REGISTER_BY_PHONE: '/v2/register/by-phone',
  REGISTER_BY_VERIFICATION: '/v2/register/by-verification',
  CHECK_EXIST_EMAIL: '/v1/search/check-exist/email',
  COUNTRY_LIST: '/v1/country/list',
  SERVICE_INFO: '/v1/service-info',
  USERINFO_PASSWORD_CHECK_VALID: '/v1/userinfo/password/check-valid',
  USERINFO_PASSWORD_NEED_CHECK: '/v1/userinfo/password/need-check',
  USERINFO_PASSWORD: '/v1/userinfo/password',
  USERINFO_CHANGE: '/v1/userinfo/change',
  USERINFO_EMAIL: '/v1/userinfo/email',
  USERINFO_PHONE: '/v1/userinfo/phone',
  USERINFO_POLICY: '/v2/userinfo/policy',
  LOGIN_CHECK: '/v1/login/check',
  FIND_ID: '/v1/recovery/email/find',
  RESET_PASSWORD_WITH_PHONE: '/v1/recovery/password/reset/verification',
  RESET_PASSWORD_WITH_EMAIL_OTP: '/v1/recovery/password/reset/email',
  DELETE_ACCOUNT: '/v1/withdraw',
  PERSONAL_INFO_TERMS: '/v1/userinfo/personal-info-usage-history',
  SOCIAL_LOGIN_INFO: '/v1/sns/info',
  GZIP_TEST_API: '/v2/terms/gzip-test',
  VERIFICATIONS_INFO_AUTH_DECRYPT_WITH_SAFEKEY: '/v2/verifications/info/auth/decrypt-with-safekey',
  VERIFICATIONS_INFO_PROMOTION_WITHOUT_LOGIN_DECRYPT_WITH_SAFEKEY: '/v2/verifications/info/promotion-without-login/decrypt-with-safekey',
  VERIFICATIONS_INFO_DECRYPT_WITH_SAFEKEY: '/v1/verifications/info/decrypt-with-safekey',
  AGREEMENT_CLIENT: '/v1/terms/agreement/client',
  ALL_LOGOUT: '/auth/realms/cw-account/logout-api/client-sessions',
} as const;

/**
 * account-api의 API 응답 코드.
 */
export const API_CODES = {
  SUCCESS: 'SUCCESS',
  BAD_REQUEST_PARAMETER: 'BAD_REQUEST_PARAMETER',
  ERROR: 'ERROR',
  EMAIL_OTP_MISSING: 'EMAIL_OTP_MISSING',
  EMAIL_OTP_ALREADY_VERIFIED: 'EMAIL_OTP_ALREADY_VERIFIED',
  EMAIL_OTP_EXPIRED: 'EMAIL_OTP_EXPIRED',
  EMAIL_OTP_INCORRECT: 'EMAIL_OTP_INCORRECT',
  PASSWORD_NOT_CORRECT: 'PASSWORD_NOT_CORRECT',
  NEW_PASSWORDS_DO_NOT_MATCH: 'NEW_PASSWORDS_DO_NOT_MATCH',
  PASSWORD_SAME_PREVIOUS: 'PASSWORD_SAME_PREVIOUS',
  PRODUCT_IS_PURCHASING: 'PRODUCT_IS_PURCHASING',
  NOT_EXIST_USER: 'NOT_EXIST_USER',
  ALREADY_VERIFIED_USER: 'ALREADY_VERIFIED_USER', // 동일한 CI 값을 가진 기존의 정회원 혹은 휴면회원이 있을 경우
  INVALID_VERIFICATION_REQUEST_ID: 'INVALID_VERIFICATION_REQUEST_ID', // request id가 조회되지 않는 경우
  NOT_EXIST_PROMOTION_KEY: 'NOT_EXIST_PROMOTION_KEY', // 존재하지 않는 promotion key로 요청할경우
  ALREADY_EXIST_USER: 'ALREADY_EXIST_USER',
  INVALID_VERIFICATION_UNDER_FOURTEEN_YEARS_OLD: 'INVALID_VERIFICATION_UNDER_FOURTEEN_YEARS_OLD',
  INVALID_USER: 'INVALID_USER',
  INVALID_COUNTRY_CODE: 'INVALID_COUNTRY_CODE',
  ALREADY_EXIST_PHONE: 'ALREADY_EXIST_PHONE',
  NOT_FOUND_CLIENT_NAME: 'NOT_FOUND_CLIENT_NAME',
  INVALID_USER_STATUS: 'INVALID_USER_STATUS',
  PHONE_OTP_MISSING: 'PHONE_OTP_MISSING',
  PHONE_OTP_ALREADY_VERIFIED: 'PHONE_OTP_ALREADY_VERIFIED',
  PHONE_OTP_EXPIRED: 'PHONE_OTP_EXPIRED',
  PHONE_OTP_INCORRECT: 'PHONE_OTP_INCORRECT',
  NOT_EXIST_CATEGORY_TYPE: 'NOT_EXIST_CATEGORY_TYPE',
  NOT_EXIST_AGREEMENT: 'NOT_EXIST_AGREEMENT',
  INVALID_VERIFICATION_USAGE: 'INVALID_VERIFICATION_USAGE',
  PROMOTION_KEY_EXPIRED: 'PROMOTION_KEY_EXPIRED',
  ALREADY_REGISTERED_USER: 'ALREADY_REGISTERED_USER',
} as const;

export const COMMON_ERROR_MESSAGE_TYPE = {
  VALIDATION: 'VALIDATION',
  ALERT: 'ALERT',
  TOAST: 'TOAST',
} as const;

const handleClickLoginButton = () => {
  const { resetSignUp } = useSignUpStore();
  resetSignUp();
  goToLoginPage();
};

const loginAlertProps = {
  type: COMMON_ERROR_MESSAGE_TYPE.ALERT,
  message: 'title.alreadySignupId',
  buttonLabel: 'general.login',
  onClick: handleClickLoginButton,
  onClickClose: handleClickLoginButton,
  isCenter: true,
};

export const COMMON_ERROR_MESSAGE: CommonErrorMessageType = {
  [APIS_KEY.VALIDATE_OTP_EMAIL]: {
    [API_CODES.EMAIL_OTP_MISSING]: { type: COMMON_ERROR_MESSAGE_TYPE.VALIDATION, message: 'commonErrorMessage.emailOtpMissing' },
    [API_CODES.EMAIL_OTP_ALREADY_VERIFIED]: { type: COMMON_ERROR_MESSAGE_TYPE.VALIDATION, message: 'commonErrorMessage.emailOtpAlreadyVerified' },
    [API_CODES.EMAIL_OTP_EXPIRED]: { type: COMMON_ERROR_MESSAGE_TYPE.VALIDATION, message: 'commonErrorMessage.emailOtpExpired' },
    [API_CODES.EMAIL_OTP_INCORRECT]: { type: COMMON_ERROR_MESSAGE_TYPE.VALIDATION, message: 'commonErrorMessage.emailOtpInCorrect' },
  },
  [APIS_KEY.REGISTER_BY_EMAIL]: {
    [API_CODES.NOT_FOUND_CLIENT_NAME]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notFoundClientName' },
    [API_CODES.ALREADY_REGISTERED_USER]: loginAlertProps,
  },
  [APIS_KEY.REGISTER_BY_PHONE]: {
    [API_CODES.ALREADY_REGISTERED_USER]: loginAlertProps,
  },
  [APIS_KEY.RESET_PASSWORD_WITH_EMAIL_OTP]: {
    [API_CODES.EMAIL_OTP_MISSING]: { type: COMMON_ERROR_MESSAGE_TYPE.VALIDATION, message: 'commonErrorMessage.emailOtpMissing' },
    [API_CODES.EMAIL_OTP_INCORRECT]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.emailOtpInCorrect' },
    [API_CODES.INVALID_USER_STATUS]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.invalidUserStatus' },
    [API_CODES.NEW_PASSWORDS_DO_NOT_MATCH]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.newPasswordDoNotMatch' },
    [API_CODES.PASSWORD_SAME_PREVIOUS]: { type: COMMON_ERROR_MESSAGE_TYPE.VALIDATION, message: 'commonErrorMessage.passwordSamePrevious' },
    [API_CODES.NOT_EXIST_USER]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notExistUser' },
  },
  [APIS_KEY.USERINFO_EMAIL]: {
    [API_CODES.NOT_EXIST_USER]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notExistUser' },
  },
  [APIS_KEY.CHECK_PASSWORD_OTP_EMAIL]: {
    [API_CODES.EMAIL_OTP_MISSING]: { type: COMMON_ERROR_MESSAGE_TYPE.VALIDATION, message: 'commonErrorMessage.emailOtpMissing' },
    [API_CODES.EMAIL_OTP_INCORRECT]: { type: COMMON_ERROR_MESSAGE_TYPE.VALIDATION, message: 'commonErrorMessage.emailOtpInCorrect' },
    [API_CODES.NOT_EXIST_USER]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notExistUser' },
  },
  [APIS_KEY.VALIDATE_OTP_EMAIL_RESET_PASSWORD]: {
    [API_CODES.EMAIL_OTP_ALREADY_VERIFIED]: { type: COMMON_ERROR_MESSAGE_TYPE.VALIDATION, message: 'commonErrorMessage.emailOtpAlreadyVerified' },
    [API_CODES.EMAIL_OTP_EXPIRED]: { type: COMMON_ERROR_MESSAGE_TYPE.VALIDATION, message: 'commonErrorMessage.emailOtpExpired' },
    [API_CODES.EMAIL_OTP_INCORRECT]: { type: COMMON_ERROR_MESSAGE_TYPE.VALIDATION, message: 'commonErrorMessage.emailOtpInCorrect' },
    [API_CODES.NOT_EXIST_USER]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notExistUser' },
  },
  [APIS_KEY.VALIDATE_OTP_PHONE]: {
    [API_CODES.PHONE_OTP_MISSING]: { type: COMMON_ERROR_MESSAGE_TYPE.VALIDATION, message: 'commonErrorMessage.phoneOtpMissing' },
    [API_CODES.PHONE_OTP_ALREADY_VERIFIED]: { type: COMMON_ERROR_MESSAGE_TYPE.VALIDATION, message: 'commonErrorMessage.phoneOtpAlreadyVerified' },
    [API_CODES.PHONE_OTP_EXPIRED]: { type: COMMON_ERROR_MESSAGE_TYPE.VALIDATION, message: 'commonErrorMessage.phoneOtpExpired' },
    [API_CODES.PHONE_OTP_INCORRECT]: { type: COMMON_ERROR_MESSAGE_TYPE.VALIDATION, message: 'commonErrorMessage.phoneOtpInCorrect' },
  },
  [APIS_KEY.REGISTER_BY_VERIFICATION]: {
    [API_CODES.ALREADY_EXIST_USER]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.alreadyExistUser' },
    [API_CODES.INVALID_VERIFICATION_REQUEST_ID]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.invalidVerificationRequestId' },
    [API_CODES.NOT_EXIST_USER]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notExistUser' },
    [API_CODES.ALREADY_REGISTERED_USER]: loginAlertProps,
  },
  [APIS_KEY.VERIFICATIONS_INFO_AUTH_DECRYPT_WITH_SAFEKEY]: {
    [API_CODES.ALREADY_VERIFIED_USER]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.alreadyVerifiedUser' },
    [API_CODES.INVALID_VERIFICATION_REQUEST_ID]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.invalidVerificationRequestId' },
    [API_CODES.INVALID_VERIFICATION_UNDER_FOURTEEN_YEARS_OLD]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.invalidVerificationUnderFourteenYearsOld' },
    [API_CODES.NOT_EXIST_USER]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notExistUser' },
  },
  [APIS_KEY.VERIFICATIONS_INFO_PROMOTION_WITHOUT_LOGIN_DECRYPT_WITH_SAFEKEY]: {
    [API_CODES.ALREADY_VERIFIED_USER]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.alreadyVerifiedUser' },
    [API_CODES.INVALID_VERIFICATION_REQUEST_ID]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.invalidVerificationRequestId' },
    [API_CODES.INVALID_VERIFICATION_USAGE]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.invalidVerificationUsage' },
    [API_CODES.PROMOTION_KEY_EXPIRED]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.promotionKeyExpired' },
    [API_CODES.INVALID_VERIFICATION_UNDER_FOURTEEN_YEARS_OLD]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.invalidVerificationUnderFourteenYearsOld' },
    [API_CODES.NOT_EXIST_PROMOTION_KEY]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notExistPromotionKey' },
    [API_CODES.NOT_EXIST_USER]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notExistUser' },
  },
  [APIS_KEY.RESET_PASSWORD_WITH_PHONE]: {
    [API_CODES.INVALID_VERIFICATION_REQUEST_ID]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.invalidVerificationRequestId' },
    [API_CODES.INVALID_USER_STATUS]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.invalidUserStatus' },
    [API_CODES.NEW_PASSWORDS_DO_NOT_MATCH]: { type: COMMON_ERROR_MESSAGE_TYPE.VALIDATION, message: 'commonErrorMessage.newPasswordDoNotMatch' },
    [API_CODES.PASSWORD_SAME_PREVIOUS]: { type: COMMON_ERROR_MESSAGE_TYPE.VALIDATION, message: 'commonErrorMessage.passwordSamePrevious' },
    [API_CODES.NOT_EXIST_USER]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notExistUser' },
  },
  [APIS_KEY.CHECK_PASSWORD_PHONE]: {
    [API_CODES.INVALID_VERIFICATION_REQUEST_ID]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.invalidVerificationRequestId' },
    [API_CODES.INVALID_VERIFICATION_USAGE]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.invalidVerificationUsage' },
    [API_CODES.NOT_EXIST_USER]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notExistUser' },
  },
  [APIS_KEY.VERIFICATIONS_INFO_DECRYPT_WITH_SAFEKEY]: {
    [API_CODES.INVALID_VERIFICATION_REQUEST_ID]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.invalidVerificationRequestId' },
    [API_CODES.INVALID_VERIFICATION_UNDER_FOURTEEN_YEARS_OLD]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.invalidVerificationUnderFourteenYearsOld' },
  },
  [APIS_KEY.TERMS_CATEGORY_TYPE_API]: {
    [API_CODES.NOT_EXIST_CATEGORY_TYPE]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notExistCategoryType' },
  },
  [APIS_KEY.USERINFO_CHANGE]: {
    [API_CODES.NOT_EXIST_AGREEMENT]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notExistAgreement' },
    [API_CODES.NOT_EXIST_USER]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notExistUser' },
  },
  [APIS_KEY.USERINFO_POLICY]: {
    [API_CODES.NOT_EXIST_AGREEMENT]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notExistAgreement' },
    [API_CODES.NOT_EXIST_USER]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notExistUser' },
  },
  [APIS_KEY.USERINFO_PASSWORD]: {
    [API_CODES.PASSWORD_NOT_CORRECT]: { type: COMMON_ERROR_MESSAGE_TYPE.VALIDATION, message: 'commonErrorMessage.passwordNotCorrect' },
    [API_CODES.PASSWORD_SAME_PREVIOUS]: { type: COMMON_ERROR_MESSAGE_TYPE.VALIDATION, message: 'commonErrorMessage.passwordSamePrevious' },
    [API_CODES.NOT_EXIST_USER]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notExistUser' },
  },
  [APIS_KEY.USERINFO_PHONE]: {
    [API_CODES.ALREADY_EXIST_PHONE]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.alreadyExistPhone' },
    [API_CODES.NOT_EXIST_USER]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notExistUser' },
  },
  [APIS_KEY.DELETE_ACCOUNT]: {
    [API_CODES.PRODUCT_IS_PURCHASING]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.productIsPurchasing' },
    [API_CODES.NOT_EXIST_USER]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notExistUser' },
  },
  [APIS_KEY.TERMS_CATEGORY_SEARCH_API]: {
    [API_CODES.NOT_FOUND_CLIENT_NAME]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notFoundClientName' },
  },
  [APIS_KEY.USERINFO_PASSWORD_CHECK_VALID]: {
    [API_CODES.NOT_EXIST_USER]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notExistUser' },
  },
  [APIS_KEY.PERSONAL_INFO_TERMS]: {
    [API_CODES.NOT_EXIST_USER]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notExistUser' },
  },
  [APIS_KEY.AGREEMENT_CLIENT]: {
    [API_CODES.NOT_FOUND_CLIENT_NAME]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notFoundClientName' },
    [API_CODES.NOT_EXIST_USER]: { type: COMMON_ERROR_MESSAGE_TYPE.ALERT, message: 'commonErrorMessage.notExistUser' },
  },
} as const;
