import { pageNotFoundError } from '@/hooks/useCommonError';
import useNative from '@/hooks/useNative';
import useCommonStore from '@/stores/common';
import { getKeyFromQueryParam } from '@/utils/urlParameter';

import type { UseNative } from '@/types/native';
import type { ServiceCategoryName } from '@/types/serviceInfo';

const native = useNative();

/**
 * goToLoginPage
 * @description 로그인 페이지로 이동한다. 기본값은 코웨이닷컴이다
 */
export function goToLoginPage(): void {
  goToPage('login');
}

/**
 * goToMyAccountPrevPage
 * @description 회원정보 수정을 호출한 클라이언트 사이트(Coway, IoCare)로 이동한다. 기본값은 코웨이닷컴이다.
 * 회원정보수정 페이지로 진입시 redirectUri를 전달해준다면 해당 경로로 이동한다.
 * 웹뷰로 띄웠을 경우 네이티브에서 닫기 함수를 호출하도록한다.
 */
export function goToMyAccountPrevPage(): void {
  goToPage('prev-my-account');
}

/**
 * logout
 * @description 애플리케이션 진입시 URL Search Param으로 넘겨준 로그아웃 URI로 이동한다.
 * 저장된 값이 없다면 코웨이닷컴 로그아웃 페이지로 이동한다.
 */
export function logout() {
  goToPage('logout');
}

/**
 * goToPage
 * @description 각 서비스별 페이지 이동 로직으로 param이 Native 함수인지 확인 후 실행, 아닐 경우 url 로 판단하여 페이지 이동
 * @param param native 함수 또는 페이지 url
 */
export function goToPage(type: ServiceCategoryName): void {
  const defaultUri = redirectUri(type);
  if (!defaultUri) {
    pageNotFoundError();
    return;
  }

  const functionName = defaultUri.replace(/(\(([^>]+|)\))/g, '') as keyof UseNative;
  const functionArg = defaultUri.match(/(\{([^>]+)\})/g)?.[0];
  if (native[functionName]) {
    execFunc(functionName, functionArg);
    return;
  }

  location.href = defaultUri;
}

export function redirectUri(type: ServiceCategoryName) {
  const { loginPage, prevPage, myAccountPrevPage, logoutUri, clientName } = useCommonStore();

  switch (type) {
    case 'login':
      if (clientName === 'COWAY_MALL') {
        return changeLoginPageUrl(loginPage, 'remember_url');
      } else {
        return loginPage;
      }
    case 'logout':
      return logoutUri;
    case 'prev-my-account':
      return prevPage || myAccountPrevPage;
  }
}

/**
 * execFunc
 * @description 네이티브 함수를 실행한다
 * @param functionName 함수명
 * @param functionArg 함수 파라미터
 */
function execFunc(functionName: keyof UseNative, functionArg: string | undefined) {
  native[functionName](functionArg ? JSON.parse(functionArg) : undefined);
}

/**
 * changeLoginPageUrl
 * @description 이동할 url의 정보에서 param을 login-page-uri > redirect_uri에서 추출하여 전달
 * @param targetHref replace 할 url
 * @returns href
 */
function changeLoginPageUrl(targetHref: string | null | undefined, targetParam: string) {
  try {
    // 변경할 주소가 없는 경우 원본 그대로 리턴
    if (!targetHref) return targetHref;

    const targetUrl = new URL(targetHref);
    const { loginPageUri: getLoginPageUri } = useCommonStore();
    const redirectUri = getKeyFromQueryParam(getLoginPageUri as string)['redirect_uri'];
    const setRememberUrl = getKeyFromQueryParam(redirectUri)[targetParam];

    // 이동하려는 url에 remember_url이 없는 경우
    if (!targetUrl.searchParams.get(targetParam)) {
      throw new Error(`Not found ${targetParam}`);
    }

    // 세션에 저장된 login-page-uri 가 없는 경우
    if (!getLoginPageUri) {
      throw new Error('Not found login-page-uri');
    }

    // login-page-uri에서 remember_url 추출을 실패한 경우
    if (!setRememberUrl) {
      throw new Error(`Not found ${targetParam} from login-page-uri`);
    }

    // targetUrl에 targetParam파라메터를 업데이트
    targetUrl.searchParams.set(targetParam, setRememberUrl);

    return targetUrl.href;
  } catch (e) {
    return targetHref;
  }
}
