export const MODAL_KEY = {
  ALERT: 'ALERT',
  CONFIRM: 'CONFIRM',
  TERMS: 'TERMS',
  TOAST: 'TOAST',
  ASK_LOGIN: 'ASK_LOGIN',
  ASK_AUTHENTICATE_TO_SIGN_UP: 'ASK_AUTHENTICATE_TO_SIGN_UP',
  OTP_AUTH: 'OTP_AUTH',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  PERSONAL_INFO_TERMS: 'PERSONAL_INFO_TERMS',
} as const;
