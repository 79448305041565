<script setup lang="ts">
/**
 * ModalTop
 * @description 모달 공통 상단 컴포넌트
 */
import { useModalStore } from '@/stores/modal';

import type { ModalKey } from '@/types/modal';

interface Props {
  contentId: ModalKey;
  title?: string;
  showClose?: boolean;
  onClick?: () => void;
}
const props = withDefaults(defineProps<Props>(), {
  title: undefined,
  showClose: true,
  onClick: undefined,
});

const { closeModal } = useModalStore();

const handleCloseModal = () => {
  props.onClick?.();
  closeModal(props.contentId);
};
</script>

<template>
  <div class="layTop">
    <h3 v-if="title" class="title">{{ title }}</h3>
    <a v-if="showClose" class="close_pop" tabindex="0" @click.prevent="handleCloseModal" @keyup.enter="handleCloseModal"></a>
  </div>
</template>
