<script setup lang="ts">
import { computed } from 'vue';

/**
 * ButtonAtom
 * @description 버튼 컴포넌트
 */

interface Props {
  wrapClass?: 'bdT' | 'borderTop' | 'bottom_btn' | 'layBtn' | 'layBtn right' | 'terms_fix_btn' | 'full' | 'inblock' | 'txtC' | 'txtR';
  wrapMode?: boolean;
  size?: 'sizeL' | 'sizeM' | 'sizeS' | 'sizeFull';
  color?: 'btnWhite' | 'btnBlack' | 'btnBlue';
  on?: boolean;
  btnTxt?: boolean;
  type?: 'button' | 'submit';
  disabled?: boolean;
  auto?: boolean;
  label?: string;
  noneStyle?: boolean;
  isPending?: boolean;
  onClick?: (e?: MouseEvent) => void;
  onKeyup?: (e?: KeyboardEvent) => void;
}

const props = withDefaults(defineProps<Props>(), {
  wrapClass: undefined,
  size: undefined,
  color: undefined,
  type: 'button',
  btnTxt: false,
  label: undefined,
  onClick: undefined,
  onKeyup: undefined,
});

const handleClick = (e?: MouseEvent) => {
  props.onClick?.(e);
};

const handleKeyup = (e?: KeyboardEvent) => {
  props.onKeyup?.(e);
};

const buttonDisabled = computed<boolean>(() => {
  return props.disabled || props.isPending;
});
</script>

<template>
  <div v-if="wrapMode" tabindex="0" class="btn_wrap" :class="wrapClass" @keyup.enter="handleKeyup">
    <button :type="type" :class="[noneStyle ? '' : 'btnBasic', size, color, { disabled }, { auto }, { on }, { btnTxt }]" :disabled="buttonDisabled" @click="handleClick">
      <span v-if="label">{{ label }}</span>
    </button>
  </div>
  <button
    v-else
    tabindex="0"
    :type="type"
    :class="[noneStyle ? '' : 'btnBasic', size, color, { disabled }, { auto }, { on }, { btnTxt }]"
    :disabled="buttonDisabled"
    @click="handleClick"
    @keyup.enter="handleKeyup">
    <span v-if="label">{{ label }}</span>
  </button>
</template>
