/**
 * useForm
 * @description 탭 제어 훅
 * @return void
 * blockFormSubmit : form submit이벤트 막기
 */

import { onMounted } from 'vue';

export const blockFormSubmit = () => {
  onMounted(() => {
    const $form = document.querySelectorAll('form');
    $form.forEach((form) => {
      form.addEventListener('submit', (event) => {
        event.preventDefault();
      });
    });
  });
};
