import { isAos, isIos } from '@/utils/userAgent';

import doItNativeMock from '@/mock/native';

import type { LoginSnsParameter, Message, UseNative } from '@/types/native';

export default function useNative(): UseNative {
  import.meta.env.MODE === 'mock' && doItNativeMock();

  const postMessage = (message: Message | LoginSnsParameter) => {
    if (isAos()) {
      window?.CowayId?.nativeCall(JSON.stringify(message));
    }
    if (isIos()) {
      window?.webkit?.messageHandlers?.CowayId?.postMessage(JSON.stringify(message));
    }
  };

  const getLocale = () => {
    postMessage({ command: 'getLocale', callback: 'nativeEvent.setLocale' });
  };

  const close = (data?: { action: string }) => {
    postMessage({ command: 'close', data });
  };

  const loginSns = (param: LoginSnsParameter) => {
    postMessage({ ...param });
  };

  const showTitleBar = () => {
    postMessage({ command: 'showTitleBar' });
  };

  return {
    getLocale,
    close,
    loginSns,
    showTitleBar,
  };
}
